import { useMemo, type PropsWithChildren } from 'react';
import { HomeCurrencyContext } from '../contexts';
import { useWLHomeCurrency } from './WLCustomerUserConfigContextProvider';

export function WLHomeCurrencyProvider({ children }: PropsWithChildren) {
  const { homeCurrency } = useWLHomeCurrency();

  const value = useMemo(
    () => ({
      homeCurrency,
    }),
    [homeCurrency]
  );

  return <HomeCurrencyContext.Provider value={value}>{children}</HomeCurrencyContext.Provider>;
}
