import type { PropsWithChildren } from 'react';
import { EndpointsContext } from '../contexts/EndpointsContext';
import { urlFromLocalOrAbsolute } from '../utils';

export const EndpointsContextProvider = function EndpointsContextProvider(props: PropsWithChildren) {
  const apiEndpoint = urlFromLocalOrAbsolute(import.meta.env.VITE_API_ENDPOINT);
  const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const wsEndpoint = urlFromLocalOrAbsolute(import.meta.env.VITE_WS_ENDPOINT, wsProtocol);

  const value = {
    apiEndpoint,
    wsEndpoint,
  };
  return <EndpointsContext.Provider value={value}>{props.children}</EndpointsContext.Provider>;
};
