import {
  type NumericField,
  OrderFormSides,
  OrdTypeEnum,
  POST,
  request,
  type RequiredProperties,
  selectLimitPrice,
  useWLOrderFormSelector,
  useWLOrgConfigContext,
} from '@talos/kyoko';
import { useEffect, useState } from 'react';

export interface OrderSummaryResponse {
  Amount: string;
  AmountCurrency: string;
  OrderQty: string;
  Currency: string;
  EstimatedTotal?: string; // returned if EquivalentCurrency is provided
  EquivalentCurrency?: string; // returned if EquivalentCurrency is provided
  // below properties are returned but currently unused
  BalanceExceeding: boolean;
  Fee: string;
  FeeCurrency: string;
  MarketAccount: string;
  OrdType: string;
  Side: string;
  Symbol: string;
}

export interface OrderSummaryProps {
  MarketAccount?: string;
  Symbol?: string;
  Currency?: string;
  OrderQty?: string;
  OrdType?: OrdTypeEnum;
  Side?: OrderFormSides;
  Price?: string;
  EquivalentCurrency?: string;
}

export const useOrderSummaryResponse = (summaryProps: OrderSummaryProps) => {
  const [orderSummaryResponse, setOrderSummaryResponse] = useState<OrderSummaryResponse>();
  const { config } = useWLOrgConfigContext();
  const maybeLimitPrice = useWLOrderFormSelector(selectLimitPrice);

  useEffect(() => {
    if (
      !shouldRequestOrderSummary({
        summaryProps,
        useBackendOrderSummary: config.useBackendOrderSummary,
        limitPriceField: maybeLimitPrice,
      })
    ) {
      setOrderSummaryResponse(undefined);
      return;
    }

    request<{ data: OrderSummaryResponse[] }>(POST, '/v1/order-pay-preview', summaryProps)
      .then(response => setOrderSummaryResponse(response.data.at(0)))
      .catch(() => setOrderSummaryResponse(undefined));
  }, [config.useBackendOrderSummary, maybeLimitPrice, summaryProps]);

  return orderSummaryResponse;
};

export const REQUIRED_SUMMARY_FIELDS = ['MarketAccount', 'Symbol', 'Currency', 'OrderQty', 'OrdType', 'Side'] as const;

const hasRequiredProps = (
  props: OrderSummaryProps
): props is RequiredProperties<OrderSummaryProps, (typeof REQUIRED_SUMMARY_FIELDS)[number]> => {
  return !REQUIRED_SUMMARY_FIELDS.some(field => !props[field]);
};

export const shouldRequestOrderSummary = ({
  summaryProps,
  useBackendOrderSummary,
  limitPriceField,
}: {
  summaryProps: OrderSummaryProps;
  useBackendOrderSummary: boolean | undefined;
  limitPriceField: NumericField | undefined;
}) => {
  if (!useBackendOrderSummary) {
    // If the config doesn't allow backend order summary, don't make the request
    return false;
  }
  if (!hasRequiredProps(summaryProps)) {
    // If any required fields are missing, don't make the request
    return false;
  }
  // Price is required when OrdType is Limit
  if (summaryProps.OrdType === OrdTypeEnum.Limit && !summaryProps.Price) {
    return false;
  }
  // If Side is Twoway don't make the request.
  if (summaryProps.Side === OrderFormSides.Twoway) {
    return false;
  }
  // If OrderQty is 0 or Price is 0, don't make the request
  if (summaryProps.OrderQty === '0' || summaryProps.Price === '0') {
    return false;
  }

  // if the price has an error, don't make the request
  if (limitPriceField?.hasError) {
    return false;
  }

  return true;
};
