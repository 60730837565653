import { useEffect, useMemo } from 'react';
import { useBalancesContext } from '../../../../contexts';
import {
  useCountdownAction,
  useCurrencyConversionRateValue,
  useDynamicCallback,
  useObservableValue,
} from '../../../../hooks';
import { useWLTradingLimits } from '../../../../providers/WLTradingLimitsProvider';

import { useWLOrderFormDispatch, useWLOrderFormSelector, type RootState } from '../../WLOrderFormStore';
import { setDependencies, triggerFormValidation } from './reducer';
import { selectSecurity, selectTradingLimitsArguments } from './selector';

/**
 * This hook is responsible for calling the useWLTradingLimits hook and propagating the results to the OrderSlice state
 */
export function useOrderDependencies() {
  const dispatch = useWLOrderFormDispatch();

  const tradingLimitsProps = useWLOrderFormSelector(selectTradingLimitsArguments);

  const { listBalances } = useBalancesContext();
  const balances = useObservableValue(() => listBalances, [listBalances]);

  useEffect(() => {
    dispatch(setDependencies({ balances }));
  }, [balances, dispatch]);

  // Call the useWLTradingLimits hook here, and propagate the results of the hook for validation
  const tradingLimitsValidationResult = useWLTradingLimits(tradingLimitsProps);

  const maybeStartTime = useWLOrderFormSelector((state: RootState) => state.order.form.parameters?.StartTime);
  const maybeEndTime = useWLOrderFormSelector((state: RootState) => state.order.form.parameters?.EndTime);

  const startTimeValue = useMemo(() => {
    return maybeStartTime?.value;
  }, [maybeStartTime]);

  const endTimeValue = useMemo(() => {
    return maybeEndTime?.value;
  }, [maybeEndTime]);

  const onCountDownFinished = useDynamicCallback(() => {
    dispatch(triggerFormValidation({}));
  });

  useCountdownAction({ countdownTarget: startTimeValue, action: onCountDownFinished });
  useCountdownAction({ countdownTarget: endTimeValue, action: onCountDownFinished });

  const security = useWLOrderFormSelector(selectSecurity);

  const { BaseCurrency, QuoteCurrency } = security ?? {};
  const ccConversionRate = useCurrencyConversionRateValue(BaseCurrency, QuoteCurrency);

  useEffect(() => {
    dispatch(setDependencies({ ccConversionRate }));
  }, [ccConversionRate, dispatch]);

  useEffect(() => {
    dispatch(setDependencies({ tradingLimitsValidation: tradingLimitsValidationResult }));
  }, [tradingLimitsValidationResult, dispatch]);
}
