import type { AgGridEvent } from 'ag-grid-community';
import type { GridApi } from 'ag-grid-enterprise';
import { isEqual } from 'lodash-es';
import { useEffect } from 'react';
import { iif, of } from 'rxjs';
import type { MarketPrice } from '../../../contexts';
import { useDynamicCallback } from '../../../hooks/useDynamicCallback';
import { useMarketPrices } from '../../../hooks/useMarketPrice';
import { useNamedState } from '../../../hooks/useNamedState';
import { useObservableValue } from '../../../hooks/useObservable';
import { useWatchlistVisibility } from '../../../providers';
import { EMPTY_ARRAY } from '../../../utils';
import type { WatchListColumnTypes, WatchListRowData, WatchListRowNode } from '../types';
import { useRenderedRows } from './useRenderedRows';

const REQUIRED_COLUMNS = ['bidButton', 'offerButton', 'bid', 'offer', 'spread'];

/**
 * A hook to subscribe to current bid/offer prices only for the rows that are currently rendered on a grid.
 * Grid row data will be updated when the price data is updated, and then an async update will
 * be triggered on those rows that were updated.
 *
 * @param gridApi Api object for the grid this hook belongs to
 */
export function useDynamicMarketPriceSubscription(
  gridApi: GridApi | null,
  enabledColumns: { type: WatchListColumnTypes }[]
) {
  const [renderedRows, setRenderedRows] = useNamedState<string[]>([], 'renderedRows');
  const loadDataForRenderedRows = useDynamicCallback((_event: AgGridEvent<unknown>, renderedRowIds: string[]) => {
    setRenderedRows(enabledColumns.some(c => REQUIRED_COLUMNS.includes(c.type)) ? renderedRowIds : EMPTY_ARRAY);
  });

  const isWatchlistVisible = useWatchlistVisibility();
  const marketPricesSubscription = useMarketPrices(renderedRows);
  // Subscribe to market prices only when the watchlist is visible, due to the nature of useObservableValue, the
  // subscription will be automatically unsubscribed when the watchlist is hidden.
  const latestMarketPrices = useObservableValue(
    () => iif(() => isWatchlistVisible, marketPricesSubscription, of(new Map<string, MarketPrice | undefined>())),
    [marketPricesSubscription, isWatchlistVisible],
    new Map()
  );

  useEffect(() => {
    const updates: WatchListRowData[] = [];
    for (const [symbol, marketPrice] of latestMarketPrices) {
      const gridRow = gridApi?.getRowNode(symbol) as WatchListRowNode;
      if (!gridRow) {
        continue;
      }
      const prevPrices = {
        bid: gridRow.data.bid,
        offer: gridRow.data.offer,
        spread: gridRow.data.spread,
      };

      // Update only if bid/offer/spread has changed
      if (marketPrice != null && !isEqual(marketPrice, prevPrices)) {
        gridRow.data.bid = marketPrice.bid;
        gridRow.data.offer = marketPrice.offer;
        gridRow.data.spread = marketPrice.spread;
        updates.push(gridRow.data);
      } else {
        gridRow.data.bid = null;
        gridRow.data.offer = null;
        gridRow.data.spread = null;
      }
    }
    if (updates.length) {
      gridApi?.applyTransactionAsync({ update: updates });
    }
  }, [gridApi, latestMarketPrices]);

  useRenderedRows(gridApi, loadDataForRenderedRows);
}
