import type { ICellRendererParams } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { memo } from 'react';
import { InlineFormattedNumber } from '../FormattedNumber';
import { Icon, IconName } from '../Icons';
import { Placeholder, PriceCell, placeholder } from './styles';
import type { WatchListRowData } from './types';

export interface RefRateCellRendererParams extends ICellRendererParams {
  data: WatchListRowData;
  value: WatchListRowData['refRate'];
}
export const RefRateCellRenderer = memo(function RefRateCellRenderer({ value, data }: RefRateCellRendererParams) {
  const security = data.security;
  const { currentPrice, prevPrice, isLoaded } = value ?? {};
  const hasPrices = !isNil(value?.currentPrice);
  const trend = !isNil(prevPrice) && !isNil(currentPrice) ? prevPrice.cmp(currentPrice) : 0;

  return (
    <PriceCell>
      {isLoaded && hasPrices ? (
        <>
          {trend !== 0 && (
            <>
              <Icon
                icon={trend > 0 ? IconName.TrendUp : IconName.TrendDown}
                color={trend > 0 ? 'green.lighten' : 'red.lighten'}
                size="fontSizeSmall"
              />{' '}
            </>
          )}
          <InlineFormattedNumber
            highlightColor="colors.gray.100"
            number={currentPrice}
            align="left"
            increment={security?.DefaultPriceIncrement}
            truncate={true}
          />
        </>
      ) : isLoaded ? (
        placeholder
      ) : (
        <Placeholder />
      )}
    </PriceCell>
  );
});
