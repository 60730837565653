import { AnimatePresence, motion } from 'framer-motion';
import type { ToastAppInboxProps, ToastProps, ToastPropsBase } from 'hooks/Toast/useToasts';
import { forwardRef, useEffect, useState } from 'react';
import type { BoxProps } from '../Core/Box';
import { Flex } from '../Core/Flex';
import { AppInboxToast, ToastSimple } from '../Notification/AppInboxToast';
import { ToastInternal } from './ToastInternal';

export type ToastsProps<T extends ToastPropsBase> = {
  toasts: T[];
  direction?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  remove: (id: string) => void;
  /** Maximum number of toasts before it gets limited, by default, unlimited toasts are shown */
  stackingLimit?: number;
  /** Container to display toasts, driving width behavior. 'local' (default) means in a local popup window, global indicates full screen locations */
  container?: 'global' | 'local';
  /** Width of the toast lists in question (content will wrap if needed)
   * - width is (currently) critical for framer-layout's popLayout behavior  to not cause the layout to slide out of view
   */
  width?: string;

  /** Flex justify content in the toast list */
  justifyContent?: BoxProps['justifyContent'];
  /** Flex align items in the toast list */
  alignItems?: BoxProps['alignItems'];
};

function isInternalToast(toast: ToastProps | ToastAppInboxProps): toast is ToastProps {
  return 'text' in toast;
}

/** Area (typically, dialog-level) Toasts container for the useToasts hook */
export function Toasts<T extends ToastProps | ToastAppInboxProps>({
  width,
  toasts,
  remove,
  alignItems,
  justifyContent,
  stackingLimit,
  direction = 'column',
  container = 'local',
}: ToastsProps<T>) {
  const [preventStacking, setPreventStacking] = useState(false);
  const totalToasts = toasts.length;
  useEffect(() => {
    if (totalToasts === 0) {
      setPreventStacking(false);
    }
  }, [totalToasts]);

  const isStackGroupShown = stackingLimit && toasts.length > stackingLimit - 1 && !preventStacking;

  if (isStackGroupShown) {
    toasts = [...toasts].slice(-stackingLimit + 1);
  }
  return (
    <AnimatePresence initial={false}>
      <motion.div
        layout="position"
        style={{ width: '100%', display: 'flex', gap: '4px', alignItems, justifyContent, flexDirection: direction }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2, layout: { delay: 0, duration: 0.1 } }}
      >
        <AnimatePresence mode="popLayout">
          {isStackGroupShown && (
            <OverflowToast
              overflowCount={totalToasts - toasts.length}
              onClick={event => {
                event?.preventDefault();
                setPreventStacking(true);
              }}
              width={width}
            />
          )}
          {toasts.map((toast, index) => {
            return (
              <motion.div
                layout="position"
                key={toast.id}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.2, layout: { delay: 0, duration: 0.1 } }}
              >
                {isInternalToast(toast) ? (
                  <ToastInternal
                    boxProps={{
                      // if the container is global, we need to set the width to 100% to allow the content to size correctly
                      // otherwise, we set the width to the width of the container
                      w: container === 'global' ? width : undefined,
                      minWidth: container === 'global' ? width : undefined,
                    }}
                    {...toast}
                    onRemove={() => (toast.id ? remove?.(toast.id) : undefined)}
                  />
                ) : (
                  <AppInboxToast
                    {...toast}
                    wrapperWidth={width}
                    containerVariant="toast"
                    onRemove={() => {
                      return toast.id ? remove?.(toast.id) : undefined;
                    }}
                  />
                )}
              </motion.div>
            );
          })}
        </AnimatePresence>
      </motion.div>
    </AnimatePresence>
  );
}

/** Overflow component for Toast stacking - requires forward Ref for framer-motion compatibility */
const OverflowToast = forwardRef<
  HTMLDivElement,
  { overflowCount: number; onClick: BoxProps['onClick']; width: BoxProps['w'] }
>(({ overflowCount, onClick, width }, ref) => {
  return (
    <motion.div
      ref={ref}
      layout="position"
      key="stacking-indicator"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width }}
      onClick={onClick}
    >
      <ToastSimple data-testid="toast-overflow" justifyContent="center">
        <Flex fontSize="fontSizeMd" style={{ fontSize: '0.75rem' }}>{`+${overflowCount} more`}</Flex>
      </ToastSimple>
    </motion.div>
  );
});
