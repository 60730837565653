import Big from 'big.js';
import { useMemo } from 'react';
import { useMaxOrderQuantity } from '../components/Forms/FormBalances/hooks';
import { useBalancesByMarketAccountCurrency } from '../contexts';
import { useMaxBalance } from '../hooks/useMaxBalance';
import { OrderFormSides, type Security } from '../types';
import { useCurrencyConversionRateValue } from './useCurrencyConversionRate';

export function useWLEffectiveMaxBalance({
  account,
  security,
  side,
  currency,
  price,
}: {
  account: string | undefined;
  security: Security | undefined;
  side: OrderFormSides | undefined;
  currency: string | undefined;
  price: string | undefined;
}) {
  const { BaseCurrency, QuoteCurrency } = security || {};
  const balancesByMarketAccountCurrency = useBalancesByMarketAccountCurrency();
  const balances = account ? balancesByMarketAccountCurrency?.get(account) : undefined;

  const maxBalanceBase = useMaxBalance(balances, BaseCurrency, BaseCurrency);
  const maxBalanceQuote = useMaxBalance(balances, QuoteCurrency, QuoteCurrency);

  const baseMaxOrderAmountProps = useMemo(
    () => ({
      MarketAccount: account,
      Symbol: security?.Symbol,
      Currency: security?.BaseCurrency,
      Side: side,
      Price: price,
    }),
    [account, security?.Symbol, security?.BaseCurrency, side, price]
  );
  const quoteMaxOrderAmountProps = useMemo(
    () => ({
      MarketAccount: account,
      Symbol: security?.Symbol,
      Currency: security?.QuoteCurrency,
      Side: side,
      Price: price,
    }),
    [account, security?.Symbol, security?.QuoteCurrency, side, price]
  );
  const { maxOrderAmountResponse: maxAmountBaseResponse } = useMaxOrderQuantity(baseMaxOrderAmountProps);
  const { maxOrderAmountResponse: maxAmountQuoteResponse } = useMaxOrderQuantity(quoteMaxOrderAmountProps);

  const baseToQuoteConversionRate = useCurrencyConversionRateValue(BaseCurrency, QuoteCurrency)?.Rate;
  const quoteToBaseConversionRate = useMemo(
    () => (baseToQuoteConversionRate ? Big(1).div(baseToQuoteConversionRate) : undefined),
    [baseToQuoteConversionRate]
  );

  const effectiveMaxBase = useMemo(() => {
    // Return the base balance if conversion rate is unavailable
    if (!quoteToBaseConversionRate) {
      return maxBalanceBase;
    }

    if (side === OrderFormSides.Buy) {
      // Buying base: available funds come from the quote side
      if (maxAmountQuoteResponse) {
        if (maxAmountQuoteResponse.Currency === QuoteCurrency) {
          return Big(maxAmountQuoteResponse.MaxOrderAmount).times(quoteToBaseConversionRate).toFixed();
        }
        // Assume the response is already in base currency
        return maxAmountQuoteResponse.MaxOrderAmount;
      }
      // Fallback to converting the quote balance to base
      if (maxBalanceQuote) {
        return Big(maxBalanceQuote).times(quoteToBaseConversionRate).toFixed();
      }
    } else if (side === OrderFormSides.Sell) {
      // Selling base: available funds come from the base side
      if (maxAmountBaseResponse) {
        // If the max amount response is already in base currency, use it directly
        if (maxAmountBaseResponse.Currency === BaseCurrency) {
          return maxAmountBaseResponse.MaxOrderAmount;
        }
        // Assume the response is in quote currency and convert it to base
        return Big(maxAmountBaseResponse.MaxOrderAmount).times(quoteToBaseConversionRate).toFixed();
      }
      return maxBalanceBase;
    }
    return maxBalanceBase;
  }, [
    side,
    quoteToBaseConversionRate,
    maxBalanceBase,
    maxBalanceQuote,
    maxAmountQuoteResponse,
    maxAmountBaseResponse,
    BaseCurrency,
    QuoteCurrency,
  ]);

  const effectiveMaxQuote = useMemo(() => {
    // Return the quote balance if conversion rate is unavailable
    if (!baseToQuoteConversionRate) {
      return maxBalanceQuote;
    }

    if (side === OrderFormSides.Buy) {
      // Buying quote: available funds come directly from the quote side
      if (maxAmountQuoteResponse) {
        if (maxAmountQuoteResponse.Currency === QuoteCurrency) {
          return maxAmountQuoteResponse.MaxOrderAmount;
        }
        // Assume the response is in base and convert it to quote
        return Big(maxAmountQuoteResponse.MaxOrderAmount).times(baseToQuoteConversionRate).toFixed();
      }
      return maxBalanceQuote;
    } else if (side === OrderFormSides.Sell) {
      // Selling quote: available funds come from the base side converted to quote
      if (maxAmountBaseResponse) {
        if (maxAmountBaseResponse.Currency === BaseCurrency) {
          return Big(maxAmountBaseResponse.MaxOrderAmount).times(baseToQuoteConversionRate).toFixed();
        }
        // Assume the response is already in quote currency
        return maxAmountBaseResponse.MaxOrderAmount;
      }
      if (maxBalanceBase) {
        return Big(maxBalanceBase).times(baseToQuoteConversionRate).toFixed();
      }
    }
    return maxBalanceQuote;
  }, [
    side,
    baseToQuoteConversionRate,
    maxBalanceBase,
    maxBalanceQuote,
    maxAmountQuoteResponse,
    maxAmountBaseResponse,
    BaseCurrency,
    QuoteCurrency,
  ]);

  return {
    effectiveMaxBase,
    effectiveMaxQuote,
    effectiveMaxBalance: currency === BaseCurrency ? effectiveMaxBase : effectiveMaxQuote,
    estimate:
      currency === BaseCurrency
        ? maxAmountBaseResponse?.Currency !== BaseCurrency
        : maxAmountQuoteResponse?.Currency !== QuoteCurrency,
  };
}
