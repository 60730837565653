import { ThemeTypes, usePreferredColorScheme, useThemeFromType, useWLThemeType } from '@talos/kyoko';
import type { DefaultTheme } from 'styled-components';

export const usePreferredTheme = (themeName: string): DefaultTheme => {
  const { themeType } = useWLThemeType();
  const preferredType = usePreferredColorScheme();

  const preferredThemeType = themeType === ThemeTypes.auto ? preferredType : themeType;

  return useThemeFromType(themeName, preferredThemeType);
};
