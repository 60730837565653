import {
  archiveOrderID,
  Box,
  ButtonGroup,
  type CustomerOrder,
  Divider,
  Flex,
  FormattedMessage,
  MixpanelEvent,
  modifyOrder,
  resubmitOrderForm,
  selectArchivedOrderIDSet,
  ToggleButton,
  useGetNewEntityWithTranslatedText,
  useMixpanel,
  useObservableValue,
  useStaticSubscription,
  useWLOrderFormDispatch,
  useWLOrderFormSelector,
  useWLOrderServiceContext,
  useWLOrdersProvider,
  wsScanToMap,
} from '@talos/kyoko';
import { sortBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { map } from 'rxjs';
import { ActiveOrdersRequest } from '../../tokens/order';
import { ActiveOrdersList } from './ActiveOrdersList';
import { RecentOrdersList } from './RecentOrdersList';

const orderCardsMessages = defineMessages({
  recent: {
    defaultMessage: 'Recent',
    id: 'OrderCards.recent',
  },
  active: {
    defaultMessage: 'Active',
    id: 'OrderCards.active',
  },
});

export const OrderCards = () => {
  const mixpanel = useMixpanel();
  const navigate = useNavigate();
  const { recentOrdersObs } = useWLOrdersProvider();
  const archivedOrderIDs = useWLOrderFormSelector(selectArchivedOrderIDSet);
  const recentOrders = useObservableValue(() => recentOrdersObs.pipe(map(orders => orders)), [recentOrdersObs]);

  const { data: activeOrdersSub } = useStaticSubscription<CustomerOrder>(ActiveOrdersRequest);
  const getNewCustomerOrderWithTranslatedText = useGetNewEntityWithTranslatedText<CustomerOrder>();

  const activeOrders = useObservableValue(
    () =>
      activeOrdersSub.pipe(
        wsScanToMap({
          getUniqueKey: o => o.OrderID,
          getInsertable: getNewCustomerOrderWithTranslatedText,
          newMapEachUpdate: false,
        }),
        map(map => [...map.values()]),
        map(orders => sortBy(orders, '-SubmitTime'))
      ),
    [activeOrdersSub, getNewCustomerOrderWithTranslatedText]
  );

  const unarchivedActiveOrders = useMemo(() => {
    return activeOrders?.filter(order => !archivedOrderIDs.has(order.OrderID));
  }, [activeOrders, archivedOrderIDs]);

  const dispatch = useWLOrderFormDispatch();
  const { orderService } = useWLOrderServiceContext();

  const handleArchiveOrder = useCallback(
    (orderID: string) => {
      dispatch(archiveOrderID(orderID));
      mixpanel.track(MixpanelEvent.ArchiveOrder);
    },
    [dispatch, mixpanel]
  );

  const handleResubmitOrder = useCallback(
    (order: CustomerOrder) => {
      dispatch(resubmitOrderForm(order));
      navigate('/trade');
      mixpanel.track(MixpanelEvent.OpenResubmitOrder);
    },
    [dispatch, mixpanel, navigate]
  );

  const handleModifyOrder = useCallback(
    (order: CustomerOrder) => {
      dispatch(modifyOrder(order));
      navigate('/trade');
      mixpanel.track(MixpanelEvent.ModifyOrder);
    },
    [dispatch, mixpanel, navigate]
  );

  const handleCancelOrder = useCallback(
    (orderID: string) => {
      orderService.cancelOrder(orderID);
      mixpanel.track(MixpanelEvent.CancelOrder);
    },
    [orderService, mixpanel]
  );

  const [_selectedTab, setSelectedTab] = useLocalStorage('mobile-order-cards-tab', 'active');
  const selectedTab = _selectedTab || 'active';

  return (
    <Flex h="100%" flexDirection="column" w="100%" data-testid="order-cards-container">
      <ButtonGroup p="spacingSmall" gap="spacingSmall">
        <ToggleButton
          selected={selectedTab === 'active'}
          data-testid="active-toggle-button"
          onClick={() => setSelectedTab('active')}
        >
          <FormattedMessage {...orderCardsMessages.active} />
        </ToggleButton>
        <ToggleButton
          selected={selectedTab === 'recent'}
          data-testid="recent-toggle-button"
          onClick={() => setSelectedTab('recent')}
        >
          <FormattedMessage {...orderCardsMessages.recent} />
        </ToggleButton>
      </ButtonGroup>
      <Divider orientation="horizontal" color="gray.000" />
      <Box background="backgroundBody" w="100%" h="100%">
        {selectedTab === 'active' && (
          <ActiveOrdersList
            handleCancelOrder={handleCancelOrder}
            handleModifyOrder={handleModifyOrder}
            handleResubmitOrder={handleResubmitOrder}
            handleArchiveOrder={handleArchiveOrder}
            activeOrders={unarchivedActiveOrders}
          />
        )}
        {selectedTab === 'recent' && (
          <RecentOrdersList
            handleCancelOrder={handleCancelOrder}
            handleModifyOrder={handleModifyOrder}
            handleResubmitOrder={handleResubmitOrder}
            recentOrders={recentOrders}
          />
        )}
      </Box>
    </Flex>
  );
};
