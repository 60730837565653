import styled from 'styled-components';

export const ButtonActions = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacingDefault}px ${theme.spacingMedium}px`};
  margin: ${({ theme }) => `0 -${theme.spacingMedium}px -${theme.spacingDefault}px`};
  border-top: ${({ theme }) => `solid 1px ${theme.colors.gray['040']}`};

  button {
    flex: 1;
  }

  button:first-child {
    margin-right: 10px;
  }
`;
