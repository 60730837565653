import type { CustomerUser } from '@talos/kyoko';

export const createCustomerCredential = async (user: CustomerUser, rp: string): Promise<boolean> => {
  try {
    const publicKey: PublicKeyCredentialCreationOptions = {
      challenge: generateRandomBuffer(),
      rp: {
        name: rp,
        id: window.location.hostname,
      },
      user: {
        id: Uint8Array.from(user.Email, c => c.charCodeAt(0)),
        name: user.Email,
        displayName: user.DisplayName,
      },
      pubKeyCredParams: [
        { type: 'public-key', alg: -7 }, // ES256 (ECDSA)
        { type: 'public-key', alg: -257 }, // RS256 (RSA)
      ],
      authenticatorSelection: {
        authenticatorAttachment: 'platform',
        userVerification: 'required',
      },
      timeout: 60000,
      attestation: 'none',
    };

    return navigator.credentials
      .create({
        publicKey,
      })
      .then(credential => {
        if (!credential) {
          return false;
        }
        const cred = credential as PublicKeyCredential;

        const idBase64 = bufferToBase64(cred.rawId);
        const allowCredentials: PublicKeyCredentialDescriptor[] = [
          {
            id: cred.rawId,
            type: 'public-key',
            transports: ['internal'],
          },
        ];
        localStorage.setItem('allowCredentials', JSON.stringify(allowCredentials));
        localStorage.setItem('credentialIdBase64', idBase64);

        return true;
      });
  } catch (err) {
    return false;
  }
};

export const authenticate = async (): Promise<boolean> => {
  try {
    const idBase64 = localStorage.getItem('credentialIdBase64');

    if (!idBase64) {
      // No credentials found. Please create one first.
      console.error('No credentials found. Please create one first.');
      return false;
    }

    const allowCredentials: PublicKeyCredentialDescriptor[] = [
      {
        id: base64ToBuffer(idBase64), // Properly convert back to Uint8Array
        type: 'public-key',
        transports: ['internal'],
      },
    ];

    const challenge = generateRandomBuffer();

    const publicKey: PublicKeyCredentialRequestOptions = {
      challenge,
      allowCredentials,
      userVerification: 'preferred',
      timeout: 60000,
    };

    await navigator.credentials.get({ publicKey });
    return true;
  } catch (err) {
    return false;
  }
};

// Utility: generate a random Uint8Array
const generateRandomBuffer = (length = 32): Uint8Array => {
  return window.crypto.getRandomValues(new Uint8Array(length));
};

// Utility: convert buffer to Base64 (for display/storage)
const bufferToBase64 = (buffer: ArrayBuffer): string => {
  return btoa(String.fromCharCode(...new Uint8Array(buffer)));
};

const base64ToBuffer = (base64: string): Uint8Array =>
  new Uint8Array(
    atob(base64)
      .split('')
      .map(char => char.charCodeAt(0))
  );
