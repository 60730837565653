import { isEqual } from 'lodash-es';
import { memo, useCallback, useMemo } from 'react';
import { FormattedPlural, defineMessages } from 'react-intl';
import {
  useDynamicCallback,
  useGetCustomerMarketAccountLabel,
  useIntl,
  useWLTradableCustomerMarketAccounts,
} from '../../hooks';
import { useWLCustomerMarketAccountContext } from '../../providers/WLCustomerMarketAccountsProvider';
import type { CustomerMarketAccount } from '../../types';
import { EMPTY_ARRAY } from '../../utils';
import { Box, HStack } from '../Core';
import { SearchSelect } from '../Form';
import type { AutocompleteGroup, FuseSearchResult } from '../Form/Autocomplete';
import { IndicatorBadge } from '../IndicatorBadge';
import { FormattedMessage } from '../Intl';
import { Text } from '../Text';
import { useCustomerMarketAccountResults } from './hooks';
import type { CustomerMarketAccountSelectorProps } from './types';

const messages = defineMessages({
  selectAccount: {
    defaultMessage: 'Select Account',
    id: 'CustomerMarketAccountSelector.selectAccount',
  },
  maxAvailable: {
    defaultMessage: 'Max Available',
    id: 'CustomerMarketAccountSelector.maxAvailable',
  },
  account: {
    defaultMessage: 'Account',
    id: 'CustomerMarketAccountSelector.account',
  },
  accounts: {
    defaultMessage: 'Accounts',
    id: 'CustomerMarketAccountSelector.accounts',
  },
});

const ADDITIONAL_SEARCH_KEYS = ['SourceAccountID'] satisfies (keyof CustomerMarketAccount)[];
const THRESHOLD = 0.35;
const MARKET_ACCOUNT_RESULT_HEIGHT = 55;
const LIST_HEIGHT = MARKET_ACCOUNT_RESULT_HEIGHT * 5;

export const CustomerMarketAccountSelector = memo(
  function CustomerMarketAccountSelector({
    accountID,
    availableCustomerMarketAccounts,
    disabled,
    error,
    touched,
    onChangeMarketAccount,
    security,
  }: CustomerMarketAccountSelectorProps) {
    const { customerMarketAccountsList } = useWLCustomerMarketAccountContext();
    const CustomerMarketAccountResult = useCustomerMarketAccountResults<CustomerMarketAccount>(security);
    const { formatMessage } = useIntl();

    const tradableAccounts = useWLTradableCustomerMarketAccounts();
    const getCustomerMarketAccountLabel = useGetCustomerMarketAccountLabel();

    // Passing in availableCustomerMarketAccounts, now optional, overrides the default accounts.
    const searchableItems = (
      availableCustomerMarketAccounts ? availableCustomerMarketAccounts : customerMarketAccountsList ?? EMPTY_ARRAY
    ).filter(account => !tradableAccounts || tradableAccounts.has(account.SourceAccountID));

    const customerMarketAccount = useMemo(
      () => searchableItems?.find?.(s => s.SourceAccountID === accountID),
      [searchableItems, accountID]
    );

    const handleChange = useCallback(
      (account?: CustomerMarketAccount) => {
        if (account) {
          onChangeMarketAccount(account);
        }
      },
      [onChangeMarketAccount]
    );

    // This is used by the SearchSelect to apply some sorting modification on the search result
    const searchResultsSorter = useCallback(
      (a: FuseSearchResult<CustomerMarketAccount>, b: FuseSearchResult<CustomerMarketAccount>) => {
        const aIndex = searchableItems.findIndex(item => item.SourceAccountID === a.item.label);
        const bIndex = searchableItems.findIndex(item => item.SourceAccountID === b.item.label);
        if (aIndex > -1 || bIndex > -1) {
          return bIndex - aIndex;
        }

        return a.refIndex < b.refIndex ? -1 : a.refIndex > b.refIndex ? 1 : 0;
      },
      [searchableItems]
    );

    const RenderGroupHeader = useDynamicCallback(
      ({ group }: { group: AutocompleteGroup<FuseSearchResult<CustomerMarketAccount>> }) => {
        const numResults = group.items.length;
        return (
          <HStack fontSize="fontSizeXs" justifyContent="space-between" w="100%" p="spacingDefault">
            <Box>
              <HStack gap="spacingSmall">
                <Text transform="uppercase">
                  <FormattedPlural
                    value={numResults}
                    one={<FormattedMessage {...messages.account} />}
                    other={<FormattedMessage {...messages.accounts} />}
                  />
                </Text>
                <IndicatorBadge show={numResults > 0} children={numResults} />
              </HStack>
            </Box>
            <Text transform="uppercase">
              <FormattedMessage {...messages.maxAvailable} />
            </Text>
          </HStack>
        );
      }
    );

    // We only have one group
    const getGroup = useDynamicCallback((acc: CustomerMarketAccount) => 'Account');

    return (
      <SearchSelect
        data-testid="customer-market-account-selector"
        options={searchableItems}
        selection={customerMarketAccount}
        getLabel={({ SourceAccountID }) => getCustomerMarketAccountLabel(SourceAccountID)}
        onChange={handleChange}
        invalid={!!error}
        touched={touched}
        disabled={disabled}
        additionalSearchKeys={ADDITIONAL_SEARCH_KEYS}
        disableFuzzyMatching
        matchThreshold={THRESHOLD}
        customFuseSearchResultsSorter={searchResultsSorter}
        fuseDistance={100}
        inputValueChangeOnItemSelection="clear"
        renderResult={CustomerMarketAccountResult}
        RenderGroupHeader={RenderGroupHeader}
        itemSize={MARKET_ACCOUNT_RESULT_HEIGHT}
        maxHeight={LIST_HEIGHT}
        groupMaxHeight={LIST_HEIGHT}
        placeholder={formatMessage(messages.selectAccount)}
        getGroup={getGroup}
      />
    );
  },
  (p, n) => isEqual(p, n)
);
