import type React from 'react';
import { createContext, memo, useContext } from 'react';
import { asyncScheduler, type Observable } from 'rxjs';
import { map, mergeMap, shareReplay, throttleTime } from 'rxjs/operators';

import { sub } from 'date-fns';
import { useGetNewEntityWithTranslatedText, useObservable, useObservableValue, useStaticSubscription } from '../hooks';
import { wsScanToMap } from '../pipes';
import { ORDER } from '../tokens';
import type { CustomerOrder } from '../types';
import { dateComparator, formattedDateForSubscription } from '../utils';

type OrderContextProps = {
  recentOrdersByOrdID: Map<string, CustomerOrder>;
  recentOrdersObs: Observable<CustomerOrder[]>;
  /**
   * Observable that emits singular order updates as they come in.
   * Meaning [order,order,order] becomes [order] [order] [order]
   * This is meant to be used for updates that happen during the user's session.
   * */
  sessionOrdersObs: Observable<CustomerOrder>;
};

const ORDERS_THROTTLE_MS = 1000;

export const WLOrdersContext = createContext<OrderContextProps | undefined>(undefined);

export const useWLOrdersProvider = () => {
  const context = useContext(WLOrdersContext);
  if (context === undefined) {
    throw new Error('Missing WLOrdersContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export const WLOrdersProvider = memo((props: React.PropsWithChildren<unknown>) => {
  const updateOrderWithTranslation = useGetNewEntityWithTranslatedText<CustomerOrder>();
  const { data: rawRecentOrdersObs } = useStaticSubscription<CustomerOrder>({
    name: ORDER,
    tag: 'WLOrdersProvider',
    sort_by: '-SubmitTime',
    Throttle: '1s',
    StartDate: formattedDateForSubscription(sub(new Date(), { months: 3 })),
  });

  // Observable that emits singular order updates as they come in.
  // Meaning [order,order,order] becomes [order] [order] [order]
  // This is meant to be used for updates that happen during the user's session.
  const sessionOrdersObs = useObservable(() => {
    return rawRecentOrdersObs.pipe(
      mergeMap(json => json.data),
      map(updateOrderWithTranslation)
    );
  }, [rawRecentOrdersObs, updateOrderWithTranslation]);

  const ordersByOrdIDObs = useObservable(
    () =>
      rawRecentOrdersObs.pipe(
        wsScanToMap({
          getUniqueKey: d => d.OrderID,
          newMapEachUpdate: true,
          getInsertable: updateOrderWithTranslation,
        }),
        throttleTime(ORDERS_THROTTLE_MS, asyncScheduler, {
          leading: true,
          trailing: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [rawRecentOrdersObs, updateOrderWithTranslation]
  );

  const recentOrdersObs = useObservable(
    () =>
      ordersByOrdIDObs.pipe(
        map(ordersMap =>
          [...ordersMap.values()].sort((a, b) => {
            return -dateComparator(a.SubmitTime, b.SubmitTime);
          })
        )
      ),
    [ordersByOrdIDObs]
  );

  const recentOrdersByOrdID = useObservableValue(() => ordersByOrdIDObs, [ordersByOrdIDObs], new Map());

  return (
    <WLOrdersContext.Provider
      value={{
        recentOrdersByOrdID,
        recentOrdersObs,
        sessionOrdersObs,
      }}
    >
      {props.children}
    </WLOrdersContext.Provider>
  );
});
