import Big from 'big.js';
import chroma from 'chroma-js';
import { all as mergeAll } from 'deepmerge';
import type {
  Axis,
  Chart,
  default as Highcharts,
  Options,
  Point,
  Series,
  SeriesOptionsType,
  TooltipOptions,
  XAxisPlotLinesOptions,
} from 'highcharts/highcharts';
import { isArray, isEmpty, isNil } from 'lodash-es';
import { darken, lighten } from 'polished';
import type { DefaultTheme } from 'styled-components';
import { ThemeTypes } from '../../styles';
import type { Currency } from '../../types';
import { currencySymbols, format } from '../../utils';
import type { ChartDataPoint, NormalizedChartDataPoint, SupportedSeriesOptions } from './types';

/**
 * Provided an array of different sets of options, uses mergeAll from deepmerge to
 * perform a deep merge across all the provieded options.
 * @returns one set of merged options
 */
export function deepMergeOptions(...options: Options[]): Options {
  return mergeAll(options, { arrayMerge: (_, source) => source });
}

const STEPS = 12;
const DOMAIN = [0, 0.18, 0.4, 0.7, 1];
const ADJUST_LIGHTNESS = 0.05;
const MAX_LIGHTNESS = 0.35;
const ADJUST_DARKNESS = 0.05;
const MAX_DARKNESS = 0.4;
export const getColorScale = (theme: DefaultTheme, numberOfColors: number) => {
  const lightScale = chroma
    .scale([
      theme.colors.green.lighten,
      theme.colors.yellow.lighten,
      theme.colors.red.lighten,
      theme.colors.blue.lighten,
    ])
    .domain(DOMAIN);
  const baseScale = chroma
    .scale([
      theme.colors.green.default,
      theme.colors.yellow.default,
      theme.colors.red.default,
      theme.colors.blue.default,
    ])
    .domain(DOMAIN);

  if (numberOfColors <= STEPS) {
    return lightScale.colors(numberOfColors);
  } else if (numberOfColors <= STEPS * 2) {
    return lightScale.colors(Math.ceil(numberOfColors / 2)).concat(baseScale.colors(Math.floor(numberOfColors / 2)));
  } else if (numberOfColors <= STEPS * 3) {
    const alternativeScale = chroma
      .scale([
        darken(0.15, theme.colors.green.default),
        darken(0.15, theme.colors.yellow.default),
        darken(0.15, theme.colors.red.default),
        darken(0.15, theme.colors.blue.default),
      ])
      .domain(DOMAIN);

    return lightScale
      .colors(Math.ceil(numberOfColors / 3))
      .concat(baseScale.colors(Math.floor(numberOfColors / 3)))
      .concat(alternativeScale.colors(Math.floor(numberOfColors / 3)));
  } else {
    const scalesNeeded = Math.ceil(numberOfColors / STEPS);
    const additionalScalesNeeded = scalesNeeded - 2;

    let scale = lightScale
      .colors(Math.ceil(numberOfColors / scalesNeeded))
      .concat(baseScale.colors(Math.floor(numberOfColors / scalesNeeded)));

    for (let i = 1; i <= additionalScalesNeeded; i++) {
      if (i % 2) {
        const darknessFactor = ADJUST_DARKNESS + ((MAX_DARKNESS / additionalScalesNeeded) * i) / 2;
        const darkerScale = chroma
          .scale([
            darken(darknessFactor, theme.colors.green.default),
            darken(darknessFactor, theme.colors.yellow.default),
            darken(darknessFactor, theme.colors.red.default),
            darken(darknessFactor, theme.colors.blue.default),
          ])
          .domain(DOMAIN);
        scale = scale.concat(darkerScale.colors(Math.floor(numberOfColors / scalesNeeded)));
      } else {
        const lightnessFactor = ADJUST_LIGHTNESS + ((MAX_LIGHTNESS / additionalScalesNeeded) * i) / 2;
        const lighterScale = chroma
          .scale([
            lighten(lightnessFactor, theme.colors.green.lighten),
            lighten(lightnessFactor, theme.colors.yellow.lighten),
            lighten(lightnessFactor, theme.colors.red.lighten),
            lighten(lightnessFactor, theme.colors.blue.lighten),
          ])
          .domain(DOMAIN);
        scale = scale.concat(lighterScale.colors(Math.floor(numberOfColors / scalesNeeded)));
      }
    }
    return scale;
  }
};

export function currencyAxisLabelFormatter(homeCurrency: Currency | undefined) {
  return function currencyAxisLabelFormatter(this: Highcharts.AxisLabelsFormatterContextObject) {
    const text = this.axis.defaultLabelFormatter.call(this);
    return getCurrencyValue(homeCurrency, text);
  };
}

export function getCurrencyValue(homeCurrency: Currency | undefined, text: string) {
  const symbol = homeCurrency?.Symbol ? currencySymbols[homeCurrency.Symbol as keyof typeof currencySymbols] ?? '' : '';
  return `${symbol}${text}${symbol ? '' : ` ${homeCurrency?.Symbol ?? ''}`}`;
}

export function percentAxisLabelFormatter(this: Highcharts.AxisLabelsFormatterContextObject) {
  const context = { ...this, value: Big(this.value).times(100).toNumber() };
  const text = this.axis.defaultLabelFormatter.call(context);
  return `${text}%`;
}

export function currencyPointFormatter(currency: Currency | undefined) {
  return function (this: Point & { value?: number }) {
    const text = format(this.y ?? this.value, { round: true, spec: currency?.DefaultIncrement });
    return `${this.name ?? this.series.name}: ${getCurrencyValue(currency, text)}`;
  };
}

export function getColorVariations(
  color: string | undefined,
  index: number,
  numberOfItems: number,
  type: ThemeTypes,
  scale = 0.5
) {
  if (!color) {
    return undefined;
  }
  const min = 0.05;
  const amount = min + ((index + 1) / (numberOfItems + 1)) * (scale - 0.1);
  return type === ThemeTypes.dark ? darken(amount, color) : lighten(amount, color);
}

export function getPercentageTooltip(): TooltipOptions {
  return {
    pointFormat: '{point.formattedValue}%',
    formatter: function (tooltip) {
      if (this.series.name === 'Downtime') {
        return false;
      }
      // If not null, use the default formatter
      return tooltip.defaultFormatter.call(this, tooltip);
    },
  };
}

/**
 * To be used in combination with xAxis.events.afterSetExtremes.
 * Given the axis (highcharts callback "this") and the event object itself, grabs the x values of the first and last item within the
 * new selection. If anything goes wrong, undefined is returned.
 * @param axis the xAxis. Grabbed from the "this" context of the highcharts callback function.
 * @param event the event object itself, grabbed from the highcharts callback function.
 * @returns the x value of the first and last item, or undefined if something goes wrong.
 */
export function getXAxisMinMaxAfterSetExtremes(axis: Highcharts.Axis, event: Highcharts.AxisSetExtremesEventObject) {
  const { min, max } = event;
  if (min == null || max == null) {
    return undefined;
  }

  if (axis == null) {
    // Sanity check
    return undefined;
  }

  // Grab our serie from the xAxis
  const serie = axis.series?.[0];
  if (serie == null) {
    return undefined;
  }

  const shownData = serie.data.filter(d => d.x >= min && d.x <= max);

  // Should reasonably never happen but just safeguarding
  if (shownData.length === 0) {
    return undefined;
  }

  return {
    first: shownData[0].x,
    last: shownData[shownData.length - 1].x,
  };
}

/**
 * Grabs the navigator xAxis safely from the chart RefObject
 * @param chart the HighchartsReact.RefObject
 * @returns either an Axis or undefined if it could not grab the xAxis of the navigator
 */
export function getNavigatorXAxis(chart: Chart): Axis | undefined {
  // @ts-expect-error - missing typing on HighStock
  return chart?.['navigator']?.['xAxis'];
}

/**
 * Updates the plotline with this ID. Always removes if there is a plot line present on the xAxis with this ID,
 * and if newPlotLine is provided, will add that into the chart. Also performs the same action for the navigator xAxis, if present
 * @param chart
 * @param id id of the plotline to update
 * @param newPlotLine if you want to add a new plotline, provide this here
 */
export function updateXAxisPlotline(chart: Chart, id: string, newPlotLine?: XAxisPlotLinesOptions) {
  // Highcharts stock doesn't have typings -- so grab the navigator xAxis and cast it safely
  const navigatorXAxis = getNavigatorXAxis(chart);

  // Always remove the old plotline when there's a change
  chart.xAxis[0].removePlotLine(id);
  if (navigatorXAxis) {
    navigatorXAxis.removePlotLine(id);
  }

  // If there's a new snapshot date (not null), add a plotline
  if (newPlotLine) {
    chart.xAxis[0].addPlotLine(newPlotLine);
    if (navigatorXAxis) {
      navigatorXAxis.addPlotLine(newPlotLine);
    }
  }
}

/**
 * Sometimes the id of a series differs from the name of that series.
 * Use this function to get the id if available, with a fallback to the name
 * if id is undefined.
 * @param series Series to get the id for
 * @returns The id of the series, or the name if id is undefined.
 */
export function getSeriesId(
  series?: Partial<{ options: Pick<Series['options'], 'id'> } & Pick<Series, 'name' | 'id' | 'custom'>>
) {
  return series?.options?.id ?? series?.custom?.id ?? series?.id ?? series?.name;
}

export function getChartDataPointObject(
  d: ChartDataPoint | undefined,
  series?: Series | SeriesOptionsType | undefined,
  chart?: Chart | undefined
): NormalizedChartDataPoint {
  let dataPoint: NormalizedChartDataPoint = { x: 0, y: undefined, z: undefined };

  if (Array.isArray(d)) {
    dataPoint = { x: d[0], y: d[1] ?? undefined, z: d[2] ?? undefined };
  } else if (d && typeof d === 'object') {
    dataPoint = {
      ...d,
      x: 'x' in d ? d.x ?? 0 : 'name' in d ? d.name ?? '' : 0,
      y: 'y' in d ? d.y ?? undefined : 'value' in d ? d.value ?? undefined : undefined,
      z: 'z' in d ? d.z ?? undefined : undefined,
    };

    if ('options' in d && 'name' in d.options && typeof d.options.name === 'string' && !('x' in d.options)) {
      // Account for the fact that if we only defined `name` and `y`, Highcharts might have generated an `x` instead
      dataPoint.x = d.options.name;
    }

    dataPoint.name = getXAxisLabel(dataPoint, series, chart);
    // If the data point doesn't have an x value, but does have a name, use the name as the x value
    dataPoint.x ??= dataPoint.name as (typeof dataPoint)['x'];
    // If the data point doesn't have a y value, but does have a value, use the value as the y value
    dataPoint.y ??= dataPoint.value ?? undefined;
    if (!dataPoint.options) {
      dataPoint.options = d as unknown as NormalizedChartDataPoint['options'];
    }
  } else {
    if (typeof d === 'number') {
      dataPoint.y = d;
    }
  }
  if (!dataPoint.value) {
    dataPoint.value = dataPoint.y;
  }
  return dataPoint;
}

/**
 * Generates an array of normalized data points for a series.
 * This function is used to normalize the data points for a series, so that they can be used
 * in a consistent manner across different types of series.
 *
 * @param thisSeries Series to generate the normalized data points for
 * @param chart Chart that this series belongs to
 * @returns Array of normalized data points for this series
 */
export function getDataPointsForSeries(
  thisSeries: Series | SeriesOptionsType,
  chart: Chart | undefined
): NormalizedChartDataPoint[] {
  // `series.data` as an array, if that value is not empty
  const seriesData =
    'data' in thisSeries && !isEmpty(thisSeries.data)
      ? isArray(thisSeries.data)
        ? thisSeries.data
        : [thisSeries.data]
      : undefined;

  // `userOptions.data` as an array, if that value is not empty
  const userOptionsData =
    'userOptions' in thisSeries && 'data' in thisSeries.userOptions && isArray(thisSeries.userOptions.data)
      ? thisSeries.userOptions.data
      : undefined;

  // `series.data` if present, otherwise the data the user defined for the series
  // `series.data` will only be populated if Highcharts has already processed the data,
  // which only happens after the chart has been rendered.
  const rawData = seriesData ?? userOptionsData ?? [];

  const dataPoints =
    rawData?.map((d): NormalizedChartDataPoint => {
      // @ts-expect-error - incomplete typing on Highcharts
      const dataPointObject = getChartDataPointObject(d, thisSeries, chart);
      return { ...dataPointObject, series: thisSeries as SupportedSeriesOptions | Series };
    }) ?? [];
  return dataPoints;
}

function getXAxisLabel(
  dataPoint: NormalizedChartDataPoint,
  series: Series | SeriesOptionsType | undefined,
  chart: Chart | undefined
): string | undefined {
  // Try to find the xAxis for this series
  const seriesXAxis = isNil(series)
    ? undefined
    : typeof series.xAxis === 'number'
    ? chart?.xAxis?.[series.xAxis]
    : typeof series.xAxis === 'string'
    ? chart?.xAxis?.find(a => a.options.id === series.xAxis)
    : series.xAxis;

  // If we couldn't find an xAxis, try some fallback values
  if (!seriesXAxis) {
    if (dataPoint.name) {
      return dataPoint.name.toString();
    }
    // Better to just not generate a name in this case, we have no idea what it should look like
    return undefined;
  }
  const labelFormatter = (seriesXAxis?.userOptions?.labels?.formatter ??
    seriesXAxis?.defaultLabelFormatter) as Highcharts.AxisLabelsFormatterCallbackFunction;
  const labelFormatterContext: Highcharts.AxisLabelsFormatterContextObject = {
    axis: seriesXAxis,
    chart: chart as Chart,
    value: dataPoint.x,
    isFirst: false,
    isLast: false,
    pos: 0,
    tick: {} as Highcharts.Tick,
  };
  return labelFormatter?.call(labelFormatterContext, labelFormatterContext) ?? '';
}
