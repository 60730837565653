import { parseDate, type WhitelabelParametersFormState } from '@talos/kyoko';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * Calculate the start time of an order, updating if the start time is specified as a Duration.
 *
 * @param params WhitelabelParametersFormState for a new or existing order
 * @returns Order start time (existing orders) or expected start time (new orders)
 */
export function useOrderStartTime(params: WhitelabelParametersFormState) {
  const { StartTime } = params;
  const formStartTime = useMemo(() => (StartTime === undefined ? undefined : new Date(StartTime)), [StartTime]);
  const [orderStartTime, setOrderStartTime] = useState<Date>(parseDate(null));

  const recalcStartTime = useCallback(
    function recalcStartTime() {
      if (!isNil(formStartTime)) {
        return setOrderStartTime(parseDate(formStartTime));
      } else {
        setOrderStartTime(parseDate());
      }
    },
    [formStartTime]
  );

  useEffect(() => {
    recalcStartTime();
    const timer = setInterval(recalcStartTime, 1000);
    return () => clearInterval(timer);
  }, [recalcStartTime]);

  return orderStartTime;
}
