import {
  ADDRESS,
  GET,
  NEW_DEPOSIT_REQUEST,
  NEW_WITHDRAW_REQUEST,
  POST,
  logger,
  request,
  useEndpointsContext,
  useObservable,
  useObservableValue,
  useSocketClient,
  useStaticSubscription,
  wsScanToMap,
  type CamelCased,
  type CustomerBalance,
  type ICustomerAddress,
  type SubscriptionResponse,
} from '@talos/kyoko';
import { createContext, useCallback, useContext, type PropsWithChildren } from 'react';
import { shareReplay } from 'rxjs/operators';
import { v4 as uuid4 } from 'uuid';

export interface CustomerWithdrawFormState {
  currency?: string;
  address?: string;
  amount?: string;
  marketAccount?: string;
  memo?: string;
  destinationTag?: string;
  externalComment?: string;
}

export interface CustomerAddressWhitelabel extends Omit<ICustomerAddress, 'CustomerAddressID'> {
  AddressID: string;
}

type CustomerDepositWithdraw = {
  customerBalances: Map<string, CustomerBalance> | undefined;
  depositCustomerBalance: (form: CustomerDepositFormState) => void;
  withdrawCustomerBalance: (form: CustomerWithdrawFormState) => void;
  customerAddressesByAddressID: Map<string, CustomerAddressWhitelabel> | undefined;
  decryptCustomerAddress: (
    address: CustomerAddressWhitelabel['AddressID']
  ) => Promise<SubscriptionResponse<CustomerAddressWhitelabel>>;
  createCustomerAddress: (
    addressDetails: Pick<
      CustomerAddressWhitelabel,
      'Currency' | 'AddressType' | 'AddressRoutingType' | 'Mode' | 'Name' | 'RoutingInfo'
    >
  ) => Promise<SubscriptionResponse<CustomerAddressWhitelabel>>;
};

export const CustomerDepositWithdrawContext = createContext<CustomerDepositWithdraw | undefined>(undefined);

export function useCustomerDepositWithdrawContext() {
  const context = useContext(CustomerDepositWithdrawContext);
  if (context === undefined) {
    throw new Error(
      'Missing CustomerDepositWithdrawContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

export interface DepositRequest {
  ClReqID: string;
  Currency: string;
  Quantity: string;
  MarketAccount: string;
  TxHashes: {
    TxHash: string;
  }[];
  ExternalComments?: string;
}

export type CustomerDepositFormState = CamelCased<
  Omit<DepositRequest, 'ClReqID' | 'TxHashes'> & {
    TxHash: DepositRequest['TxHashes'][number]['TxHash'];
  }
>;

interface WithdrawRequest {
  Quantity: string;
  Currency: string;
  AddressID?: string;
  ClReqID: string;
  ExternalComments?: string;
  Comments?: string;
  MarketAccount: string;
}

const BALANCE = 'Balance';

function getCustomerBalanceKey(cb: CustomerBalance) {
  return `${cb.MarketAccount}-${cb.Currency}`;
}

function getCustomerAddressKey(ca: CustomerAddressWhitelabel) {
  return `${ca.AddressID}`;
}

export const CustomerDepositWithdrawProvider = ({ children }: PropsWithChildren) => {
  const client = useSocketClient();

  const { data: balanceSubscription } = useStaticSubscription<CustomerBalance>({
    name: BALANCE,
    tag: 'CustomerDepositWithdrawProvider',
  });
  const { data: addressesSubscription } = useStaticSubscription<CustomerAddressWhitelabel>({
    name: ADDRESS,
    tag: 'CustomerDepositWithdrawProvider',
  });

  const balanceObservable = useObservable<Map<string, CustomerBalance>>(
    () =>
      balanceSubscription.pipe(
        wsScanToMap({ getUniqueKey: getCustomerBalanceKey, newMapEachUpdate: true }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [balanceSubscription]
  );
  const addressesObservable = useObservable<Map<string, CustomerAddressWhitelabel>>(
    () =>
      addressesSubscription.pipe(
        wsScanToMap({
          getUniqueKey: getCustomerAddressKey,
          newMapEachUpdate: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [addressesSubscription]
  );

  const customerBalances = useObservableValue(() => balanceObservable, [balanceObservable]);
  const customerAddressesByAddressID = useObservableValue(() => addressesObservable, [addressesObservable]);

  const depositCustomerBalance = useCallback(
    (form: CustomerDepositFormState) => {
      if (!(form.quantity && form.currency && form.marketAccount)) {
        return;
      }

      const data: DepositRequest = {
        ClReqID: uuid4(),
        Currency: form.currency,
        MarketAccount: form.marketAccount,
        Quantity: form.quantity,
        TxHashes: [
          {
            TxHash: form.txHash,
          },
        ],
        ExternalComments: form.externalComments,
      };

      client.registerPublication({
        type: NEW_DEPOSIT_REQUEST,
        data: [data],
      });
    },
    [client]
  );

  const withdrawCustomerBalance = useCallback(
    (form: CustomerWithdrawFormState) => {
      if (!(form.amount && form.currency && form.marketAccount)) {
        logger.error(new Error('Missing parameters in withdrawCustomerBalance'), {
          contexts: {
            Parameters: {
              Quantity: form.amount,
              Currency: form.currency,
              MarketAccount: form.marketAccount,
              AddressID: form.address,
            },
          },
        });
        return;
      }

      const data: WithdrawRequest = {
        Quantity: form.amount,
        Currency: form.currency,
        MarketAccount: form.marketAccount,
        AddressID: form.address,
        ClReqID: uuid4(),
        Comments: undefined,
        ExternalComments: form.externalComment,
      };

      client.registerPublication({
        type: NEW_WITHDRAW_REQUEST,
        data: [data],
      });
    },
    [client]
  );

  const { apiEndpoint } = useEndpointsContext();
  const addressesEndpoint = `${apiEndpoint}/addresses`;

  const decryptCustomerAddress: CustomerDepositWithdraw['decryptCustomerAddress'] = useCallback(
    address => request(GET, `${addressesEndpoint}/${address}`),
    [addressesEndpoint]
  );

  const createCustomerAddress: CustomerDepositWithdraw['createCustomerAddress'] = useCallback(
    addressDetails => request(POST, addressesEndpoint, addressDetails),
    [addressesEndpoint]
  );

  return (
    <CustomerDepositWithdrawContext.Provider
      value={{
        customerBalances,
        depositCustomerBalance,
        withdrawCustomerBalance,
        customerAddressesByAddressID,
        decryptCustomerAddress,
        createCustomerAddress,
      }}
    >
      {children}
    </CustomerDepositWithdrawContext.Provider>
  );
};
