import {
  BlotterTableStorageContext,
  mapBlotterColumnStateToBlotterTableSort,
  useWLCustomerUserConfigContext,
  type BlotterTableState,
  type BlotterTableStorageContextProps,
  type ValueOf,
} from '@talos/kyoko';
import { useEffect, useMemo, useRef, type PropsWithChildren } from 'react';

function getExistingBlotter(
  config: ReturnType<typeof useWLCustomerUserConfigContext>['config'],
  blotterID: string
): ValueOf<BlotterTableState> {
  return config[`blotters.${blotterID}` as keyof typeof config] as ValueOf<BlotterTableState>;
}

export function BlotterTableStorageProvider({ children }: PropsWithChildren) {
  const { config, updateConfig, isLoaded } = useWLCustomerUserConfigContext();

  const configRef = useRef(config);
  useEffect(() => {
    configRef.current = config;
  }, [config]);

  const value = useMemo(
    () =>
      isLoaded
        ? {
            getState: blotterID => getExistingBlotter(configRef.current, blotterID),
            setColumnState: (blotterID, columns) => {
              const existingBlotterValue = getExistingBlotter(configRef.current, blotterID);
              const newBlotterState: ValueOf<BlotterTableState> = {
                ...existingBlotterValue,
                columns: columns,
                sort: mapBlotterColumnStateToBlotterTableSort(columns),
              };
              return updateConfig({
                [`blotters.${blotterID}`]: newBlotterState,
              });
            },
            setFilterState: (blotterID, filter) =>
              updateConfig({
                [`blotters.${blotterID}`]: { ...getExistingBlotter(configRef.current, blotterID), filter },
              }),
          }
        : null,
    [isLoaded, updateConfig]
  ) as BlotterTableStorageContextProps;

  return <BlotterTableStorageContext.Provider value={value}>{children}</BlotterTableStorageContext.Provider>;
}
