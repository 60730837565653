import type { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { useSecuritiesContext } from '../../contexts';
import { useConstant, useIntl } from '../../hooks';
import type { Security, WLOrderStrategy } from '../../types';
import { EMPTY_ARRAY } from '../../utils';
import { useWLCustomerMarketAccountContext } from '../WLCustomerMarketAccountsProvider';
import { useWLCustomerUserConfigContext } from '../WLCustomerUserConfigContextProvider';
import { getWLOrderFormStore } from './WLOrderFormStore';

export const WLOrderFormProvider = ({
  children,
  getStrategiesBySymbol,
}: PropsWithChildren<{ getStrategiesBySymbol: (s: Security) => WLOrderStrategy[] }>) => {
  const { config } = useWLCustomerUserConfigContext();
  const intl = useIntl();

  const { securitiesBySymbol, securitiesList } = useSecuritiesContext();
  const { customerMarketAccountsList = EMPTY_ARRAY, customerMarketAccountsBySourceAccountID = new Map() } =
    useWLCustomerMarketAccountContext();

  // this can be a constant since we only care about initializing the redux store, future updates will be handled by the useOmsDependencies hook
  const store = useConstant(
    getWLOrderFormStore(
      {
        securities: { securitiesBySymbol, securitiesList },
        symbol: config.symbol,
        strategiesBySymbol: new Map(securitiesList.map(s => [s.Symbol, getStrategiesBySymbol(s) ?? []])),
        marketAccounts: { customerMarketAccountsList, customerMarketAccountsBySourceAccountID },
      },
      intl
    )
  );

  return <Provider store={store}>{children}</Provider>;
};
