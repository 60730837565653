import type { ICellRendererParams } from 'ag-grid-enterprise';
import Big from 'big.js';
import { isEmpty } from 'lodash-es';
import { abbreviate } from '../../utils';
import { Text } from '../Text';
import { Placeholder, placeholder, VolumeCell } from './styles';
import type { WatchListRowData } from './types';

export interface VolumeCellRendererParams extends ICellRendererParams {
  data: WatchListRowData;
  value: WatchListRowData['volume'];
}

export const VolumeCellRenderer = function VolumeCellRenderer({ data, value }: VolumeCellRendererParams) {
  const isLoaded = value !== undefined;
  const hasData = !isEmpty(value);

  return (
    <VolumeCell>
      {isLoaded && hasData ? (
        <Text>{abbreviate(Big(value ?? 0).toFixed(), { precision: 2 })}</Text>
      ) : isLoaded ? (
        placeholder
      ) : (
        <Placeholder />
      )}
    </VolumeCell>
  );
};
