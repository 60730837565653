import {
  NEW_ORDER_SINGLE,
  OrdTypeEnum,
  OrderFormSides,
  QUOTE,
  QUOTE_CANCEL_REQUEST,
  QUOTE_REQUEST,
  SideEnum,
  formattedDateForSubscription,
  useGetNewEntityWithTranslatedText,
  useObservable,
  useSocketClient,
  useStaticSubscription,
  wsScanToMap,
  type CustomerQuote,
  type FormState,
} from '@talos/kyoko';
import { sortBy } from 'lodash-es';
import { createContext, memo, useCallback, useContext } from 'react';
import { map, shareReplay, type Observable } from 'rxjs';
import { v1 as uuid } from 'uuid';

type RequestQuoteProps = Omit<FormState, 'Parameters'> & { Symbol: string };

type QuoteProviderProps = {
  requestQuote: (
    { AllowedSlippage, Side, OrderQty, Currency, Symbol, MarketAccount }: RequestQuoteProps,
    transactTime?: Date
  ) => string;
  acceptQuote: ({ quote, side }: { quote: CustomerQuote & { AllowedSlippage?: string }; side: SideEnum }) => string;
  cancelQuote: (rFQID: string, quoteReqID?: string, transactTime?: Date | undefined) => void;
  openQuotes: Observable<CustomerQuote[]>;
};

interface AcceptQuoteParams {
  ClOrdID: string;
  Side: string;
  Price?: string;
  TransactTime: string;
  RFQID: string;
  Symbol: string;
  Currency: string;
  OrderQty: string;
  OrdType: OrdTypeEnum.LimitAllIn;
  MarketAccount: string;
  AllowedSlippage?: string;
}

const QuotesContext = createContext<QuoteProviderProps | undefined>(undefined);

export const useQuotesProvider = () => {
  const context = useContext(QuotesContext);
  if (context === undefined) {
    throw new Error('Missing QuotesContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export const QuotesProvider = memo((props: React.PropsWithChildren<unknown>) => {
  const client = useSocketClient();

  const { data: subscription } = useStaticSubscription<CustomerQuote>({
    name: QUOTE,
    tag: 'QuotesProvider',
    HideAPICalls: true,
    sort_by: '-Timestamp',
  });

  const updateQuoteWithTranslation = useGetNewEntityWithTranslatedText<CustomerQuote>();

  const openQuotes = useObservable<CustomerQuote[]>(
    () =>
      subscription.pipe(
        wsScanToMap({
          getUniqueKey: quote => quote.RFQID,
          newMapEachUpdate: false,
          getInsertable: updateQuoteWithTranslation,
        }),
        map(quotes => sortBy([...quotes.values()], item => item.SubmitTime)),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription, updateQuoteWithTranslation]
  );

  const requestQuote = useCallback(
    ({ Side, OrderQty, Currency, Symbol, MarketAccount }, transactTime = new Date()) => {
      const QuoteReqID: string = uuid();
      const data = {
        Side: Side === OrderFormSides.Twoway ? undefined : Side,
        QuoteReqID,
        OrderQty,
        Currency,
        Symbol,
        TransactTime: formattedDateForSubscription(transactTime),
        Parameters: {},
        MarketAccount,
      };

      client.registerPublication({
        type: QUOTE_REQUEST,
        data: [data],
      });
      return QuoteReqID;
    },
    [client]
  ) satisfies QuoteProviderProps['requestQuote'];

  const acceptQuote = useCallback(
    ({ quote, side }: { quote: CustomerQuote & { AllowedSlippage?: string }; side: SideEnum }) => {
      const clOrdID: string = uuid();
      const { AllowedSlippage, RFQID, Symbol, Currency, OrderQty, BidPx, OfferPx, MarketAccount } = quote;
      const price = side === SideEnum.Buy ? OfferPx : BidPx;

      const data: AcceptQuoteParams = {
        ClOrdID: clOrdID,
        Side: side,
        Price: price,
        TransactTime: formattedDateForSubscription(new Date()),
        RFQID,
        Symbol,
        Currency,
        OrderQty,
        OrdType: OrdTypeEnum.LimitAllIn,
        MarketAccount,
      };

      if (AllowedSlippage) {
        data.AllowedSlippage = AllowedSlippage;
      }

      client.registerPublication({
        type: NEW_ORDER_SINGLE,
        data: [data],
      });
      return clOrdID;
    },
    [client]
  );

  const cancelQuote = useCallback(
    (rFQID: string, quoteReqID?: string, transactTime = new Date()) =>
      client.registerPublication({
        type: QUOTE_CANCEL_REQUEST,
        data: [
          {
            RFQID: rFQID,
            QuoteReqID: quoteReqID,
            TransactTime: formattedDateForSubscription(transactTime),
          },
        ],
      }),
    [client]
  );

  return (
    <QuotesContext.Provider
      value={{
        requestQuote,
        acceptQuote,
        cancelQuote,
        openQuotes,
      }}
    >
      {props.children}
    </QuotesContext.Provider>
  );
});
