import { cloneDeep, get, isEqual, mapValues, merge, set } from 'lodash-es';
import { useMemo, useRef, useState } from 'react';
import { useUserContext } from '../../contexts';
import { useCallbackRef, useDisclosureWithContext, useGlobalToasts } from '../../hooks';
import { AppwideDrawerContentType, useAppwideDrawerContext } from '../../providers';
import { DELETE, EMPTY_ARRAY, PATCH, POST, PUT, request } from '../../utils';
import { Button, ButtonVariants } from '../Button';
import { FormControlSizes } from '../Form';
import { NotificationVariants } from '../Notification';
import { Text } from '../Text';
import type { EntityAdminConfirmationDialogContext } from './components/EntityAdminConfirmationDialog';
import type {
  EntityAdminRecord,
  HierarchicalColumnProps,
  InputsAndDropdownsDrawerOption,
  useEntityAdminProps,
} from './types';
import { useEntityAdminTabsContext } from './wrappers/EntityAdminTabsWrapper';

const BULK_EDIT_THROTTLE = 50;

export function useEntityAdmin<TRecord extends EntityAdminRecord, TDrawerRecord extends EntityAdminRecord = TRecord>(
  props: useEntityAdminProps<TRecord, TDrawerRecord>
) {
  const { add: addToast, remove: removeToast } = useGlobalToasts();
  const tabsContext = useEntityAdminTabsContext();

  /** We need this to trigger blotter updates that propagate from the drawer */
  const blotterTableApiRef = useRef<{ refresh?: (force?: boolean) => void }>({});

  const {
    entityName = 'Entity',
    // If tabsContext is not null, set useTabs to false so that we don't re-add tabs wrapper
    useTabs = props.persistKey != null && tabsContext == null,
    sendEmptyStringForNulledValues = false,
    postEntityInArray = false,
    usePutForPatchPost = false,
    bulkEditFields = EMPTY_ARRAY,
    confirmTextActions = EMPTY_ARRAY,
  } = props;

  const { openDrawer, closeDrawer, isOpen } = useAppwideDrawerContext();

  const [selectedEntity, setSelectedEntity] = useState<TDrawerRecord | undefined>(undefined);
  const [addingChildEntity, setAddingChildEntity] = useState<boolean>(false);
  const [bulkEditRows, setBulkEditRows] = useState<TRecord[]>([]);

  /** Manage the Confirmation Dialog internals here.
   * The EntityAdminConfirmationDialog component is used to render the dialog. */
  const confirmDialog = useDisclosureWithContext<EntityAdminConfirmationDialogContext>();

  const getPostPath = useMemo(
    () => props.getPostPath ?? ((_: TDrawerRecord) => props.path!),
    [props.getPostPath, props.path]
  );
  const getPatchDeletePath = useMemo(
    () =>
      props.getPatchDeletePath ??
      ((entity: TDrawerRecord) => `${getPostPath(entity)}/${encodeURIComponent(get(entity, props.entityIDField!))}`),
    [props.getPatchDeletePath, getPostPath, props.entityIDField]
  );
  const getEntityAsDrawerEntity = useMemo(
    () => props.getEntityAsDrawerEntity ?? ((entity: TRecord) => entity as unknown as TDrawerRecord),
    [props.getEntityAsDrawerEntity]
  );
  const getEntityForPatchPost = useMemo(
    () => props.getEntityForPatchPost ?? ((entity: TDrawerRecord) => entity),
    [props.getEntityForPatchPost]
  );
  const getEntityDrawerOptions = useMemo(
    () => props.getEntityDrawerOptions ?? (() => props.drawerOptions),
    [props.getEntityDrawerOptions, props.drawerOptions]
  );
  const drawerOptions = useMemo(
    () => getEntityDrawerOptions(selectedEntity, addingChildEntity),
    [addingChildEntity, getEntityDrawerOptions, selectedEntity]
  );

  const getBulkEditDrawerOptions = useCallbackRef((entities: TDrawerRecord[]) => {
    // Get regular drawer options for the first entity, and then modify them to be bulk-edit friendly.
    return getEntityDrawerOptions(entities.at(0), false)?.map<InputsAndDropdownsDrawerOption<TDrawerRecord>>(option => {
      if (option.type === 'divider') {
        return option;
      }

      const hasMultipleValues = entities.some(
        entity => get(entity, option.field) !== get(entities.at(0)!, option.field)
      );

      return {
        ...option,
        placeholder: hasMultipleValues ? 'Multiple Values' : option.placeholder,
        getIsDisabled: () => !bulkEditFields.includes(option.field),
        getIsRequired: form => (option.getIsRequired?.(form) ?? false) && !hasMultipleValues,
      };
    });
  });

  const getEntityDrawerTitle = useCallbackRef((setupEntities: TRecord[] | undefined, setupIsChildOverride: boolean) => {
    const childEntityName = props.addChildEntityButtonProps?.text;
    if (setupIsChildOverride) {
      return `New ${childEntityName}`;
    }
    if (setupEntities?.length) {
      const setupEntityIsChild = setupEntities[0][props.childIDField!] != null;
      const setupEntityName: string =
        setupEntities.length > 1
          ? setupEntityIsChild && childEntityName
            ? childEntityName
            : entityName
          : props.getEditEntityName?.(getEntityAsDrawerEntity(setupEntities[0])) ?? entityName;

      return setupEntities.length > 1 ? `Modify ${setupEntityName}s` : `Modify ${setupEntityName}`;
    }
    return `New ${entityName}`;
  });

  const openEntityDrawer: HierarchicalColumnProps<TRecord>['openEntityDrawer'] = useCallbackRef(
    (setupEntity: TRecord | undefined, setupIsChildEntity: boolean) => {
      const setupEntityAsDrawerEntity = setupEntity ? getEntityAsDrawerEntity(setupEntity) : undefined;

      setSelectedEntity(setupEntityAsDrawerEntity);
      setAddingChildEntity(setupIsChildEntity);

      const entityDrawerOptions = getEntityDrawerOptions(setupEntityAsDrawerEntity, setupIsChildEntity);
      openDrawer({
        type: AppwideDrawerContentType.EntityAdmin,
        title: getEntityDrawerTitle(setupEntity ? [setupEntity] : undefined, setupIsChildEntity),
        renderContent: () =>
          props.renderDrawer({
            drawerOptions: entityDrawerOptions ?? EMPTY_ARRAY,
            selectedEntity: setupEntityAsDrawerEntity,
            addingChildEntity: setupIsChildEntity,
            onSaveEntity: handleOnCreateOrUpdateEntityWithConfirmation,
            onDeleteEntity: handleOnDeleteEntitiesWithConfirmation,
          }),
      });
    }
  );

  const openBulkEditDrawer: HierarchicalColumnProps<TRecord>['openBulkEditDrawer'] = useCallbackRef(
    (selectedEntities: TRecord[] | undefined) => {
      if (bulkEditFields == null || selectedEntities == null || selectedEntities.length === 0) {
        return;
      }

      if (selectedEntities.length === 1) {
        openEntityDrawer(selectedEntities[0], false);
        return;
      }

      const entitiesAsDrawerEntities = selectedEntities.map(getEntityAsDrawerEntity);
      const bulkEditDrawerOptions = getBulkEditDrawerOptions(entitiesAsDrawerEntities);

      if (bulkEditDrawerOptions == null) {
        return;
      }

      const selectedEntity = bulkEditDrawerOptions.reduce((acc, option) => {
        if (option.type === 'divider') {
          return acc;
        }

        return set(
          { ...acc },
          option.field,
          option.placeholder === 'Multiple Values' ? undefined : get(entitiesAsDrawerEntities.at(0), option.field)
        );
      }, {} as TDrawerRecord);

      openDrawer({
        type: AppwideDrawerContentType.EntityAdmin,
        title: `Bulk ${getEntityDrawerTitle(selectedEntities, false)}`,
        renderContent: () =>
          props.renderDrawer({
            drawerOptions: bulkEditDrawerOptions,
            // Construct selectedEntity where only the non-placeholder fields are filled in.
            selectedEntity,
            addingChildEntity: false,
            onSaveEntity: bulkEditUpdate =>
              handleOnUpdateEntitiesWithConfirmation(entitiesAsDrawerEntities, bulkEditUpdate),
            onDeleteEntity: () => handleOnDeleteEntitiesWithConfirmation(entitiesAsDrawerEntities),
            bulkEditing: true,
          }),
        renderHeaderContent: () => (
          <Button variant={ButtonVariants.Primary} size={FormControlSizes.Tiny}>
            <Text data-testid="entity-admin-selected-entities-length-text">
              {selectedEntities.length} Rows selected
            </Text>
          </Button>
        ),
      });
    }
  );

  const closeEntityDrawer = useCallbackRef(() => {
    closeDrawer();
    setSelectedEntity(undefined);
    setAddingChildEntity(false);
  });

  const { orgApiEndpoint } = useUserContext();
  const getPathWithApiEndpoint = useCallbackRef((path: string) => `${props.baseUrl ?? orgApiEndpoint}${path}`);

  const postEntity = useCallbackRef((entity: TDrawerRecord) =>
    request<{ data: TRecord[] }>(
      usePutForPatchPost ? PUT : POST,
      getPathWithApiEndpoint(getPostPath(entity)),
      postEntityInArray ? [getEntityForPatchPost(entity)] : getEntityForPatchPost(entity)
    )
  );
  const patchEntity = useCallbackRef((entity: TDrawerRecord) =>
    request<{ data: TRecord[] }>(
      usePutForPatchPost ? PUT : PATCH,
      getPathWithApiEndpoint(getPatchDeletePath(entity)),
      getEntityForPatchPost(entity)
    )
  );
  const deleteEntity = useCallbackRef((entity: TDrawerRecord) =>
    request<void>(DELETE, getPathWithApiEndpoint(getPatchDeletePath(entity)))
  );

  const handleOnUpdateEntity = useCallbackRef(async (updatedEntity: TDrawerRecord) => {
    const clonedEntity = cloneDeep(updatedEntity);
    // Delete the fields that are disabled or hidden
    drawerOptions
      ?.filter(option => option.type !== 'divider')
      .forEach(option => {
        if (option.getIsDisabled?.(updatedEntity) || option.getIsHidden?.(updatedEntity)) {
          delete clonedEntity[option.field];
        }
      });

    const entityForPatch = mapValues<TDrawerRecord, string | number | null>(clonedEntity, value =>
      value === '' ? (sendEmptyStringForNulledValues ? '' : null) : value
    ) as TDrawerRecord;

    return patchEntity(entityForPatch)
      .then(({ data }) => {
        closeEntityDrawer();
        blotterTableApiRef.current.refresh?.();
        addToast({
          text: `${entityName} entity saved successfully.`,
          variant: NotificationVariants.Positive,
        });
        return data.at(0)!;
      })
      .catch(error => {
        addToast({
          text: error.toString() ?? `Failed to save ${entityName} entity.`,
          variant: NotificationVariants.Negative,
        });
        return Promise.reject();
      });
  });

  const handleOnDeleteEntity = useCallbackRef(async (selectedEntity: TDrawerRecord) => {
    return deleteEntity(selectedEntity)
      .then(() => {
        closeEntityDrawer();
        blotterTableApiRef.current.refresh?.(true);
        addToast({
          text: 'Entity deleted successfully.',
          variant: NotificationVariants.Positive,
        });
        return;
      })
      .catch(error => {
        addToast({
          text: error.toString() ?? 'Failed to delete entity',
          variant: NotificationVariants.Negative,
        });
        return Promise.reject();
      });
  });

  const handleOnCreateNewEntity = useCallbackRef(async (newEntity: TDrawerRecord) => {
    return postEntity(newEntity)
      .then(({ data }) => {
        closeEntityDrawer();
        blotterTableApiRef.current.refresh?.();
        addToast({
          text: `${entityName} entity created successfully.`,
          variant: NotificationVariants.Positive,
        });
        return data.at(0)!;
      })
      .catch(error => {
        addToast({
          text: error.toString() ?? `Failed to create ${entityName} entity.`,
          variant: NotificationVariants.Negative,
        });
        return Promise.reject();
      });
  });

  const handleOnCreateOrUpdateEntityWithConfirmation = useCallbackRef((entity: TDrawerRecord) => {
    if (selectedEntity == null || addingChildEntity) {
      if (confirmTextActions.includes('single-add')) {
        confirmDialog.open({
          ConfirmReason: `Are you sure you want to create this ${entityName}?`,
          RequiresConfirmText: true,
          ConfirmAction: () => handleOnCreateNewEntity(entity),
        });
        return;
      }
      return handleOnCreateNewEntity(entity);
    } else {
      if (isEqual(entity, selectedEntity)) {
        addToast({
          text: `No changes made to ${entityName}.`,
          variant: NotificationVariants.Warning,
        });
        return;
      }
      if (confirmTextActions.includes('single-edit')) {
        confirmDialog.open({
          ConfirmReason: `Are you sure you want to save changes to this ${entityName}?`,
          RequiresConfirmText: true,
          ConfirmAction: () => handleOnUpdateEntity(entity),
        });
        return;
      }
      return handleOnUpdateEntity(entity);
    }
  });

  const handleBulkEditEntities = useCallbackRef(
    async (bulkEditEntitiesRelevant: TDrawerRecord[], bulkEditUpdatesCleaned: Partial<TDrawerRecord>) => {
      closeEntityDrawer();
      addToast({
        text: `Bulk editing ${bulkEditEntitiesRelevant.length} entities...`,
        variant: NotificationVariants.Primary,
        id: 'bulk-edit-initiated',
        timeout: null, // Gets removed when the bulk edit is completed.
        dismissable: false,
      });
      setBulkEditRows(EMPTY_ARRAY);

      const patchPromises = bulkEditEntitiesRelevant.map(entity =>
        patchEntity(merge(entity, bulkEditUpdatesCleaned)).catch(error => {
          addToast({
            text: error.toString() ?? `Failed to bulk edit ${entityName}.`,
            variant: NotificationVariants.Negative,
          });
        })
      );

      Promise.all(patchPromises)
        .catch(error => {
          addToast({
            text: error.toString() ?? `Failed to bulk edit ${entityName}.`,
            variant: NotificationVariants.Negative,
          });
        })
        .finally(() => {
          removeToast('bulk-edit-initiated');
          addToast({
            text: `Bulk edit completed.`,
            variant: NotificationVariants.Positive,
          });
          setTimeout(() => {
            /**
             * Workaround with REST Blotters.
             * Wait extra 200ms to let back-end catch up.
             * We should instead be manually updating rest rows from the patch responses
             */
            blotterTableApiRef.current.refresh?.();
          }, 200);
        });
    }
  );

  const handleOnUpdateEntitiesWithConfirmation = useCallbackRef(
    (bulkEditEntities: TDrawerRecord[], bulkEditUpdates: EntityAdminRecord = {}) => {
      if (bulkEditEntities.length === 1) {
        return handleOnCreateOrUpdateEntityWithConfirmation(bulkEditEntities[0]);
      }

      const bulkEditUpdatesCleaned: Partial<TDrawerRecord> = {};
      for (const field of bulkEditFields) {
        const value = get(bulkEditUpdates, field);
        if (value != null && value !== '') {
          set(bulkEditUpdatesCleaned, field, value);
        }
      }

      const bulkEditEntitiesRelevant = bulkEditEntities.filter(entity =>
        // Only include entities that have at least 1 field updated.
        Object.keys(bulkEditUpdatesCleaned).some(field => get(entity, field) !== get(bulkEditUpdatesCleaned, field))
      );

      if (bulkEditEntitiesRelevant.length === 0) {
        addToast({
          text: `No changes made to bulk edit.`,
          variant: NotificationVariants.Warning,
        });
      } else {
        confirmDialog.open({
          ConfirmReason: `Are you sure you want to edit ${bulkEditEntitiesRelevant.length} entities?`,
          RequiresConfirmText: confirmTextActions.includes('bulk-edit'),
          ConfirmAction: () => handleBulkEditEntities(bulkEditEntitiesRelevant, bulkEditUpdatesCleaned),
        });
      }
    }
  );

  const handleBulkDeleteEntities = useCallbackRef((bulkEditEntities: TDrawerRecord[]) => {
    return Promise.all(
      bulkEditEntities.map((entity, index) =>
        setTimeout(
          () =>
            deleteEntity(entity).catch(error => {
              addToast({
                text: error.toString() ?? `Failed to delete ${entityName}.`,
                variant: NotificationVariants.Negative,
              });
            }),
          index * BULK_EDIT_THROTTLE
        )
      )
    )
      .then(() => {
        const totalMS = bulkEditEntities.length * BULK_EDIT_THROTTLE;
        addToast({
          text: `Deleting ${bulkEditEntities.length} entities...`,
          variant: NotificationVariants.Positive,
          id: 'bulk-delete-initiated',
        });
        setTimeout(() => {
          removeToast('bulk-delete-initiated');
          addToast({
            text: `Bulk delete completed.`,
            variant: NotificationVariants.Positive,
          });
          /**
           * Workaround with REST Blotters.
           * Wait extra 200ms to let back-end catch up.
           * We should instead be manually updating rest rows from the patch responses
           */
          blotterTableApiRef.current.refresh?.(true);
        }, totalMS + 200);
      })
      .catch(error => {
        addToast({
          text: error.toString() ?? `Failed to bulk delete ${entityName}.`,
          variant: NotificationVariants.Negative,
        });
      });
  });

  const handleOnDeleteEntitiesWithConfirmation = useCallbackRef(async (bulkEditEntities: TDrawerRecord[]) => {
    if (bulkEditEntities.length === 1) {
      confirmDialog.open({
        ConfirmReason: `Are you sure you want to delete this entity?`,
        RequiresConfirmText: confirmTextActions.includes('single-delete'),
        ConfirmAction: () => handleOnDeleteEntity(bulkEditEntities[0]),
      });
    } else if (bulkEditEntities.length > 1) {
      confirmDialog.open({
        ConfirmReason: `Are you sure you want to delete ${bulkEditEntities.length} entities?`,
        RequiresConfirmText: confirmTextActions.includes('bulk-delete'),
        ConfirmAction: () => handleBulkDeleteEntities(bulkEditEntities),
      });
    }
  });

  const value = useMemo(() => {
    return {
      ...props,
      getEntityForPatchPost,
      getEntityAsDrawerEntity,
      useTabs,
      selectedEntity,
      addingChildEntity,
      bulkEditRows,
      setBulkEditRows,
      setAddingChildEntity,
      handleOnUpdateEntitiesWithConfirmation,
      handleOnDeleteEntitiesWithConfirmation,
      handleOnUpdateEntity,
      openEntityDrawer,
      openBulkEditDrawer,
      closeEntityDrawer,
      drawerOptions,
      blotterTableApiRef,
      isEntityAdminDrawerOpen: isOpen,
      confirmDialog,
    };
  }, [
    props,
    handleOnUpdateEntity,
    getEntityForPatchPost,
    getEntityAsDrawerEntity,
    useTabs,
    selectedEntity,
    addingChildEntity,
    bulkEditRows,
    setBulkEditRows,
    handleOnUpdateEntitiesWithConfirmation,
    handleOnDeleteEntitiesWithConfirmation,
    openEntityDrawer,
    openBulkEditDrawer,
    closeEntityDrawer,
    drawerOptions,
    isOpen,
    confirmDialog,
  ]);

  return value;
}
