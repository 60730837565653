import mixpanel, { type Config } from 'mixpanel-browser';
import { memo, useCallback, useEffect, useState } from 'react';
import { GET_MIXPANEL_MOCK, MixpanelContext } from '../contexts/MixpanelContext';
import { EMPTY_OBJECT } from '../utils';

export type MixpanelInitProps = Partial<Pick<Config, 'ip'>>;

export const MixpanelProvider = memo(function MixpanelProvider({
  mixpanelInitProps = EMPTY_OBJECT,
  children,
}: React.PropsWithChildren<{ mixpanelInitProps?: MixpanelInitProps }>) {
  const [instance, setInstance] = useState(() => GET_MIXPANEL_MOCK());

  useEffect(() => {
    if (import.meta.env.VITE_AVA_ENV === 'prod' || import.meta.env.VITE_AVA_ENV === 'sandbox') {
      mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
        api_host: import.meta.env.VITE_MIXPANEL_PROXY_URL,
        ip: mixpanelInitProps.ip,
      });
      setInstance({
        set_group: function (...args) {
          mixpanel.set_group(...args);
        },
        opt_in_tracking: function (...args) {
          mixpanel.opt_in_tracking(...args);
        },
        opt_out_tracking: function (...args) {
          mixpanel.opt_out_tracking(...args);
        },
        remove_group: function (...args) {
          mixpanel.remove_group(...args);
        },
        add_group: function (...args) {
          mixpanel.add_group(...args);
        },
        identify: function (...args) {
          mixpanel.identify(...args);
        },
        register: function (...args) {
          mixpanel.register(...args);
        },
        time_event: function (...args) {
          mixpanel.time_event(...args);
        },
        track: function (...args) {
          args[1] = { ...args[1], windowWidth: window.innerWidth, windowHeight: window.innerHeight };
          mixpanel.track(...args);
        },
      });
    }
  }, [mixpanelInitProps.ip]);

  const getInstance = useCallback(() => instance, [instance]);

  return <MixpanelContext.Provider value={getInstance}>{children}</MixpanelContext.Provider>;
});
