import type { ICellRendererParams } from 'ag-grid-enterprise';
import { compact, isEmpty } from 'lodash-es';
import type { Security } from '../../types';
import { Box, HStack } from '../Core';
import { InlineFormattedNumber, NumberVariants } from '../FormattedNumber';
import { SecurityRenderer } from '../SecurityRenderer';
import { Sparkline } from './Sparkline';

export function SecurityCellRenderer({ data, ...params }: ICellRendererParams) {
  const security: Security = data.security;

  const sparklineData = data?.sparklineData?.Values ?? [];
  const percentChange = data?.sparklineData?.PercentChange;
  const hasSparklineData = !isEmpty(compact(sparklineData));

  const variant = percentChange?.gt(0)
    ? NumberVariants.Positive
    : percentChange?.lt(0)
    ? NumberVariants.Negative
    : undefined;

  let shouldRenderPercentChange = !!percentChange;
  if (percentChange !== undefined) {
    const columnWidth = params.column?.getActualWidth();
    shouldRenderPercentChange = columnWidth ? columnWidth > 150 : false;
  }

  return (
    <HStack w="100%" h="100%" position="relative">
      {hasSparklineData && (
        <Box left={0} right={0} top={0} bottom={0} position="absolute">
          <Sparkline
            h="33.3333%"
            sparklineFill="gradient"
            mt={params.eGridCell.offsetHeight * 0.6}
            data={sparklineData}
            variant={
              percentChange?.gt(0)
                ? NumberVariants.Positive
                : percentChange?.lt(0)
                ? NumberVariants.Negative
                : undefined
            }
          />
        </Box>
      )}
      <Box flex="1 1 0" overflow="hidden">
        <SecurityRenderer
          security={security}
          currency={security.BaseCurrency}
          showDescription={false}
          showIcon={true}
          iconSize={24}
          justifyContent="flex-start"
        />
      </Box>
      {shouldRenderPercentChange && (
        <Box px="spacingSmall" fontSize="fontSizeSmall">
          <InlineFormattedNumber
            showSign={true}
            number={percentChange}
            variant={variant}
            round={true}
            increment="0.01"
            currency="%"
          />
        </Box>
      )}
    </HStack>
  );
}
