import { AuthContextProvider, PortalContextProvider } from '@talos/kyoko';
import { Login } from 'components/Login';
import { EssentialProviders, Providers } from 'components/Providers';
import { createBrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';

import { ROUTER_CREATE_FUTURE_FLAGS, ROUTER_PROVIDER_FUTURE_FLAGS } from '@talos/kyoko/src/routed';
import { Layout } from './components/Layout';
import { Unauthorized } from './components/Unauthorized';

function AppInner() {
  return (
    <PortalContextProvider>
      <AuthContextProvider
        loginUrl={import.meta.env.VITE_AUTH_LOGIN_URL}
        logoutUrl={import.meta.env.VITE_AUTH_LOGOUT_URL}
        sessionCookieName={import.meta.env.VITE_SESSION_COOKIE}
      >
        <EssentialProviders>
          <Routes>
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/*"
              element={
                <Providers>
                  <Layout />
                </Providers>
              }
            />
          </Routes>
        </EssentialProviders>
      </AuthContextProvider>
    </PortalContextProvider>
  );
}

const browserRouter = createBrowserRouter([{ path: '*', element: <AppInner /> }, {}], {
  future: ROUTER_CREATE_FUTURE_FLAGS,
});

export const App = () => {
  return <RouterProvider future={ROUTER_PROVIDER_FUTURE_FLAGS} router={browserRouter} />;
};

export default App;
