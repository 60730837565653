import { values } from 'lodash-es';
import { useMemo } from 'react';
import { useWLCustomerMarketAccountContext } from '../providers/WLCustomerMarketAccountsProvider';
import { useWLUser } from '../providers/WLUserContextProvider';
import type { CustomerMarketAccount, MarketAccountAuthorizations } from '../types';
import { WL_WRITE_ORDERS } from './useWLRoleAuth';

export function useWLTradableCustomerMarketAccounts() {
  const { MarketAccountAuthorizations } = useWLUser();
  const { customerMarketAccountsList } = useWLCustomerMarketAccountContext();
  return useMemo(
    () => getTradableAccounts(customerMarketAccountsList, MarketAccountAuthorizations),
    [customerMarketAccountsList, MarketAccountAuthorizations]
  );
}

function getTradableAccounts(
  accounts?: CustomerMarketAccount[],
  authorizations?: MarketAccountAuthorizations
): Map<string, CustomerMarketAccount> | null {
  // returning null means all accounts tradable. If there are no authorizations it means read/write to everything.
  // Also if there are read authorizations, but no write authorizations, it means we can write/trade to everything (because no write permissions have been restricted to any account).
  if (!accounts || !authorizations || !values(authorizations).some(a => a.includes(WL_WRITE_ORDERS))) {
    return null;
  }

  return new Map<string, CustomerMarketAccount>(
    accounts
      .map(account => ({
        account,
        hasWriteAuthorization: !!authorizations[account.SourceAccountID]?.some(a => a === WL_WRITE_ORDERS),
      }))
      .filter(({ hasWriteAuthorization }) => hasWriteAuthorization)
      .map(({ account }) => [account.SourceAccountID, account])
  );
}
