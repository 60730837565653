import Big, { type BigSource } from 'big.js';
import { get } from 'lodash-es';
import { useMemo } from 'react';

import { useIntl, useWLHomeCurrency, type Balance, type Column, type ColumnDef } from '@talos/kyoko';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  valueHomeCurrency: {
    defaultMessage: 'Value ({homeCurrency})',
    id: 'Blotters.BalancesBlotter.valueHomeCurrency',
  },
});

export type PipedBalance = Balance & { RowID: string };

export function useColumns(colorful: boolean | undefined) {
  const { homeCurrency } = useWLHomeCurrency();
  const { formatMessage } = useIntl();

  return useMemo<Column[]>(
    () =>
      [
        { type: 'currency', field: 'Currency', sortable: true, params: { colorful } },
        { type: 'account', field: 'MarketAccount', sortable: true },
        { type: 'size', field: 'Amount', params: { currencyField: 'Currency' } },
        { type: 'size', field: 'AvailableAmount', flex: 1, params: { currencyField: 'Currency' } },
        {
          type: 'size',
          field: 'Equivalent.Amount',
          title: formatMessage(messages.valueHomeCurrency, { homeCurrency: homeCurrency }),
          sortable: true,
          minWidth: 128,
          flex: 1,
          params: { currency: homeCurrency },
        },
        {
          type: 'custom',
          id: 'allocation',
          params: {
            headerName: 'Allocation',
            cellRenderer: 'meterColumn',
            menuTabs: [],
            minWidth: 120,
            maxWidth: 480,
            flex: 1,

            valueGetter: ({ api, data, context }) => {
              const key = 'Equivalent.Amount';
              const value: string = get(data, key);
              if (value == null || value === '0') {
                return { value: 0 };
              }

              if (Big(value).lt(0)) {
                return { value: 0 };
              }
              let total = Big(0);
              api.forEachNode(node => {
                const nodeValue: string = get(node.data, key);
                const value = Big(nodeValue ?? '0').gt(0) ? nodeValue : '0';
                total = total.add(value);
              });
              if (total.eq(0)) {
                return { value: 0 };
              }
              return { value: Big(value).div(total), color: context.current.theme?.backgroundProgressBarMeter };
            },

            comparator: (valueA: { value: BigSource }, valueB: { value: BigSource }) => {
              return Big(valueA.value).gt(valueB.value) ? 1 : -1;
            },
          },
        },
      ] satisfies ColumnDef<PipedBalance>[],
    [colorful, homeCurrency, formatMessage]
  );
}
