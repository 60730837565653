import styled from 'styled-components';
import type { BoxProps } from './Box';
import { Stack } from './Stack';

/** Simple flex div with 'flex-direction: column' and centered content
 *
 * Set flex properties without creating a new component.
 *
 * Default Props:
 * - justifyContent: 'center'
 * - aligntItems: 'center'
 */
export const VStack = styled(Stack)<BoxProps>``;

VStack.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center',
};
