import { defineMessages } from 'react-intl';
import { useIntl } from '../../hooks';
import { ThemeTypes } from '../../styles';
import { IconButton } from '../Button';
import { FormControlSizes } from '../Form';
import { IconName } from '../Icons';
import { SwitchWrapper } from './styles';

const messages = defineMessages({
  dark: {
    defaultMessage: 'Dark',
    id: 'ThemeToggle.dark',
  },
  auto: {
    defaultMessage: 'Auto',
    id: 'ThemeToggle.auto',
  },
  light: {
    defaultMessage: 'Light',
    id: 'ThemeToggle.light',
  },
});

export interface ThemeToggleProps {
  /**
   * The currently selected theme type.
   */
  themeType: ThemeTypes;

  /**
   * This should be a setter function that will change the selected theme.
   * Ideally, the selection should be saved to the user's config somehow as well.
   */
  onChangeThemeType: (newThemeType: ThemeTypes) => void;

  size?: FormControlSizes;
}

/**
 * Toggle for selecting themeType, Dark/Auto/Light.
 */
export function ThemeToggle({ themeType, onChangeThemeType, size = FormControlSizes.Small }: ThemeToggleProps) {
  const intl = useIntl();

  return (
    <SwitchWrapper size={size}>
      <IconButton
        icon={IconName.Moon}
        ghost={themeType !== ThemeTypes.dark}
        size={size}
        onClick={() => onChangeThemeType(ThemeTypes.dark)}
        title={intl.formatMessage(messages.dark)}
      />
      <IconButton
        icon={IconName.Theme}
        ghost={themeType !== ThemeTypes.auto}
        size={size}
        onClick={() => onChangeThemeType(ThemeTypes.auto)}
        title={intl.formatMessage(messages.auto)}
      />
      <IconButton
        icon={IconName.Sun}
        ghost={themeType !== ThemeTypes.light}
        size={size}
        onClick={() => onChangeThemeType(ThemeTypes.light)}
        title={intl.formatMessage(messages.light)}
      />
    </SwitchWrapper>
  );
}
