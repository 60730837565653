import styled from 'styled-components';
import { Flex } from './Flex';

/** Simple flex div with 'flex-direction: column'
 *
 * Set flex properties without creating a new component.
 */
export const Stack = styled(Flex)``;
Stack.defaultProps = {
  flexDirection: 'column',
};
