import {
  Box,
  Button,
  Grid,
  Icon,
  IconName,
  Modal,
  Spinner,
  Text,
  useDisclosure,
  useEventListener,
  useWLIsAuthenticationRequiredConfig,
  useWLOrgConfigContext,
  useWLUser,
  VStack,
} from '@talos/kyoko';
import { createContext, type PropsWithChildren, useCallback, useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { authenticate, createCustomerCredential } from './utils';

export type WebAuthnContextType = {
  isAuthenticationRequired: boolean;
  tryUpdatingAuthenticationRequired: (value: boolean) => Promise<boolean>;
  isAuthenticated: boolean;
  triggerAuthentication: () => Promise<boolean>;
};
export const MOBILE_ORDER_SIGNING_LOCALE_STORAGE_KEY = 'mobile-order-signing';

export const WebAuthnContext = createContext<WebAuthnContextType | null>(null);
export const useWebAuthn = () => {
  const context = useContext(WebAuthnContext);

  return context;
};

export const WebAuthContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    config: { enableWebAuthn },
  } = useWLOrgConfigContext();

  if (!enableWebAuthn) {
    return <>{children}</>;
  }

  return <InternalWebAuthContextProvider>{children}</InternalWebAuthContextProvider>;
};

const InternalWebAuthContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticationRequired, setIsAuthenticationRequired } = useWLIsAuthenticationRequiredConfig();

  const {
    config: { title: relyingParty },
  } = useWLOrgConfigContext();

  const [authenticated, setAuthenticated] = useState(false);
  const user = useWLUser();
  const [loading, setLoading] = useState(false);
  const showAuthModal = !authenticated && isAuthenticationRequired && !loading;
  const showLoadingModal = loading;
  const showModal = showAuthModal || showLoadingModal;
  const modal = useDisclosure({ isOpen: showModal });

  const onTriggerAuthentication = useCallback(async () => {
    setLoading(true);
    try {
      const storedId = localStorage.getItem('credentialIdBase64');
      if (storedId) {
        const result = await authenticate();
        setAuthenticated(result);
        return result;
      } else {
        const result = await createCustomerCredential(user, relyingParty);
        setAuthenticated(result);
        return result;
      }
    } catch {
      console.error('Failed to authenticate');
      return false;
    } finally {
      setLoading(false);
    }
  }, [user, relyingParty]);

  const tryUpdatingAuthenticationRequired = useCallback(
    async (value: boolean) => {
      return onTriggerAuthentication().then(res => {
        if (res) {
          setIsAuthenticationRequired(value);
        }
        return false;
      });
    },
    [onTriggerAuthentication, setIsAuthenticationRequired]
  );

  useEventListener('beforeunload', () => {
    setAuthenticated(false);
  });
  useEventListener('visibilitychange' as any, () => {
    if (document.visibilityState !== 'visible') {
      setAuthenticated(false);
    }
  });

  return (
    <WebAuthnContext.Provider
      value={{
        isAuthenticated: authenticated,
        isAuthenticationRequired: isAuthenticationRequired ?? false,
        triggerAuthentication: onTriggerAuthentication,
        tryUpdatingAuthenticationRequired,
      }}
    >
      <Modal {...modal} closeOnClickOutside={false} closeOnEscape={false} preventClosing={true}>
        <>
          {showAuthModal && (
            <VStack
              alignSelf="center"
              background="backgroundModal"
              p="spacingLarge"
              borderRadius="borderRadiusMedium"
              textAlign="center"
              w="300px"
              minHeight="170px"
              data-testid="web-authn-modal-login"
            >
              <Text fontSize="fontSizeLarge" fontWeight="bold" color="colorTextImportant">
                Identity verification required
              </Text>
              <Text py="spacingDefault">
                To continue, please verify your identity. <br /> You can disable this in the drawer settings.
              </Text>

              <Button
                data-testid="web-authn-verify-identity"
                onClick={onTriggerAuthentication}
                endIcon={IconName.LockClosed}
              >
                Verify identity
              </Button>
            </VStack>
          )}
          {showLoadingModal && (
            <VStack
              alignSelf="center"
              background="backgroundModal"
              p="spacingLarge"
              borderRadius="borderRadiusMedium"
              gap="spacingLarge"
              w="300px"
              minHeight="170px"
              data-testid="web-authn-modal-loading"
            >
              <Text>Processing authentication...</Text>
              <Grid placeItems="center" position="relative">
                <Spinner size={80} />
                <Box position="absolute">
                  <Icon size={24} icon={IconName.LockClosed} />
                </Box>
              </Grid>
            </VStack>
          )}
        </>
      </Modal>
      {children}
    </WebAuthnContext.Provider>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const AuthModalContainer = styled(Grid)`
  position: absolute;
  inset: 0;
  place-items: center;
  background: ${({ theme }) => theme.backgroundOverlay};

  opacity: 0;
  animation: ${fadeIn} 400ms ease-out forwards;
  animation-delay: 200ms;
`;
