import type { LayoutComponentScreen } from '../types';
import {
  BalancesScreen,
  CreditScreen,
  LadderScreen,
  OrderCardsScreen,
  OrderFormsScreen,
  TradesScreen,
  TransfersScreen,
  WatchListScreen,
} from './screenLibrary';

export interface MobileLayoutComponentScreen extends LayoutComponentScreen {
  params?: Record<string, string>;
}

export const tradingMobileScreens: MobileLayoutComponentScreen[] = [
  {
    ...WatchListScreen,
    params: {
      securityRoute: '/ladder',
    },
  },
  LadderScreen,
  OrderFormsScreen,
  OrderCardsScreen,
  BalancesScreen,
  TradesScreen,
  CreditScreen,
  TransfersScreen,
];
