import type { SeriesSunburstOptions } from 'highcharts/highcharts';
import { groupBy, sumBy } from 'lodash-es';
import { useMemo } from 'react';
import { Box, Flex, VStack } from '../../Core';
import { InlineFormattedNumber } from '../../FormattedNumber';
import { Text } from '../../Text';
import { getDataPointsForSeries } from '../utils';
import type { ChartTooltipProps } from './ChartTooltip';
import { ChartTooltipValue } from './ChartTooltipValue';
import { ChartTooltipWrapper } from './styles';
import { ChartTooltipValueType } from './types';

export function SunburstTooltip({
  data,
  homeCurrency,
  valueType,
  title,
}: ChartTooltipProps & { data: { series?: SeriesSunburstOptions } }) {
  const point = data.point;
  const dataPoints = getDataPointsForSeries(data.series, data.series.chart);
  const grouped = groupBy(dataPoints, d => d.options?.parent ?? 'undefined');
  const group = point.parent && grouped[point.parent];
  const groupTotal = group && sumBy(group, 'value');
  const groupShare = point.value && groupTotal ? point.value / groupTotal : undefined;

  const all = grouped['undefined'];
  const allTotal = all[0]?.value;
  const share = point.value && allTotal && allTotal > 0 && point.value / allTotal;

  const parentName = useMemo(() => dataPoints.find(d => d.id === point.parent)?.name, [dataPoints, point.parent]);

  if (!data || data.key === 'hole') {
    return null;
  }

  return (
    <ChartTooltipWrapper>
      {title}
      <Flex gap="spacingDefault">
        <Box
          background={typeof data?.color === 'string' ? data?.color : undefined}
          w="0.25rem"
          borderRadius="0.125rem"
        />
        <VStack alignItems="flex-end" textAlign="right" gap="spacingSmall">
          <Text fontSize="fontSizeDefault">{data.key}</Text>
          <div>
            <ChartTooltipValue data={data.point.value} valueType={valueType} currency={homeCurrency} />
          </div>
          {valueType === ChartTooltipValueType.currency && share && (
            <InlineFormattedNumber number={share * 100} increment="0.01" round={true} currency="% of total" />
          )}
          {valueType === ChartTooltipValueType.currency &&
            groupShare &&
            share !== groupShare &&
            parentName !== 'hole' && (
              <div>
                <InlineFormattedNumber
                  number={groupShare * 100}
                  increment="0.01"
                  round={true}
                  currency={`% of ${parentName}`}
                />
              </div>
            )}
        </VStack>
      </Flex>
    </ChartTooltipWrapper>
  );
}
