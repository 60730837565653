import type { AgGridEvent } from 'ag-grid-community';
import type { GridApi } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { useEffect } from 'react';
import { iif, of } from 'rxjs';
import type { RefRate } from '../../../contexts';
import { useDynamicCallback, useNamedState, useObservableValue, useRefRates } from '../../../hooks';
import { useWatchlistVisibility } from '../../../providers';
import { EMPTY_ARRAY } from '../../../utils/empty';
import type { WatchListColumnTypes, WatchListRowData, WatchListRowNode } from '../types';
import { useRenderedRows } from './useRenderedRows';

const REQUIRED_COLUMNS: WatchListColumnTypes[] = ['refRate'];

/**
 * A hook to subscribe to Reference Rates only for the rows that are currently rendered on a grid.
 * Grid row data will be updated when the ref-rate data is updated, and then an async update will
 * be triggered on those rows that were updated.
 *
 * @param gridApi Api object for the grid this hook belongs to
 */
export function useDynamicRefRateSubscription(
  gridApi: GridApi | null,
  enabledColumns: { type: WatchListColumnTypes }[]
) {
  const [renderedRows, setRenderedRows] = useNamedState<string[]>([], 'renderedRows');
  const loadDataForRenderedRows = useDynamicCallback((_event: AgGridEvent<unknown>, renderedRowIds: string[]) => {
    setRenderedRows(enabledColumns.some(c => REQUIRED_COLUMNS.includes(c.type)) ? renderedRowIds : EMPTY_ARRAY);
  });
  const refRatesSubscription = useRefRates(renderedRows);

  const isWatchlistVisible = useWatchlistVisibility();
  // Subscribe to refrates only when the watchlist is visible, due to the nature of useObservableValue, the
  // subscription will be automatically unsubscribed when the watchlist is hidden.
  const latestRefRates = useObservableValue(
    () => iif(() => isWatchlistVisible, refRatesSubscription, of(new Map<string, RefRate | undefined>())),
    [refRatesSubscription, isWatchlistVisible],
    new Map()
  );

  useEffect(() => {
    const updates: WatchListRowData[] = [];
    for (const [symbol, refRate] of latestRefRates) {
      const gridRow = gridApi?.getRowNode(symbol) as WatchListRowNode;
      if (!gridRow) {
        continue;
      }
      // Here we copy the logic from the RefRate provider, with a fallback to the
      // sparkline close price value if there's no ref rate available.
      const prevRefRate = refRate?.prevPrice ?? gridRow.data?.refRate?.currentPrice ?? gridRow.data?.refRate?.prevPrice;
      const newRate = refRate?.currentPrice ?? gridRow.data?.sparklineData?.Close ?? prevRefRate;

      if ((isNil(prevRefRate) && !isNil(newRate)) || (!isNil(prevRefRate) && !newRate?.eq(prevRefRate))) {
        gridRow.data.refRate = {
          currentPrice: newRate,
          prevPrice: prevRefRate,
          isLoaded: refRate?.isLoaded ?? true,
        };
      } else if (refRate) {
        gridRow.data.refRate = {
          currentPrice: prevRefRate,
          prevPrice: prevRefRate,
          isLoaded: refRate.isLoaded,
        };
      }
      updates.push(gridRow.data);
    }
    if (updates.length) {
      gridApi?.applyTransactionAsync({ update: updates });
    }
  }, [gridApi, latestRefRates]);

  useRenderedRows(gridApi, loadDataForRenderedRows);
}
