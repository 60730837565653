import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { Direction } from '../../types/Direction';
import { getMutedButtonStyles, setAlpha } from '../utils';
import { base } from './BaseTheme';
import { ThemeTypes } from './types';

export const lightColors: Colors = {
  gray: {
    main: '#ffffff',
    '000': hsla(240, 0.12, 0.88, 1),
    '010': hsla(240, 0.12, 0.93, 1),
    '020': hsla(240, 0.12, 0.96, 1),
    '030': hsla(240, 0.12, 0.91, 1),
    '040': hsla(240, 0.06, 0.85, 1),
    '050': hsla(240, 0.06, 0.81, 1),
    '060': hsla(240, 0.06, 0.77, 1),
    '070': hsla(240, 0.06, 0.73, 1),
    '080': hsla(240, 0.06, 0.61, 1),
    '090': hsla(240, 0.04, 0.42, 1),
    '100': hsla(240, 0.15, 0.14, 1),
  },

  black: {
    default: '#000000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#ffffff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.1)',
  },

  primary: {
    default: '#314A8C',
    lighten: '#4B64A6',
    dim: 'rgba(49, 74, 140, 0.4)',
    mute: 'rgba(49, 74, 140, 0.1)',
  },

  green: {
    default: '#2E7E57',
    lighten: '#2C8459',
    dim: 'rgba(51, 126, 90, 0.4)',
    mute: 'rgba(51, 126, 90, 0.1)',
  },

  red: {
    default: '#A63240',
    lighten: '#C63949',
    dim: 'rgba(146, 46, 67, 0.4)',
    mute: 'rgba(146, 46, 67, 0.1)',
  },

  yellow: {
    default: '#B68616',
    lighten: '#C39018',
    dim: 'rgba(182, 134, 22, 0.4)',
    mute: 'rgba(182, 134, 22, 0.1)',
  },

  blue: {
    default: '#3388E0',
    lighten: '#499EF6',
    dim: 'rgba(51, 136, 224, 0.4)',
    mute: 'rgba(51, 136, 224, 0.1)',
  },

  orange: {
    default: '#DD4E00',
    lighten: '#E45000',
    dim: 'rgba(230, 82, 0, 0.4)',
    mute: 'rgba(230, 82, 0, 0.1)',
  },

  purple: {
    default: '#844BCC',
    lighten: '#894FD6',
  },
};

export const LightTheme: DefaultTheme = mapLightTheme(lightColors, {});

export function mapLightTheme(colors: Colors, customMappings: Partial<DefaultTheme>): DefaultTheme {
  const backgroundDefaultButton = setAlpha(0.08, colors.gray['100']);
  const backgroundDefaultButtonHover = setAlpha(0.12, colors.gray['100']);
  const backgroundDefaultButtonFocus = setAlpha(0.12, colors.gray['100']);
  const backgroundDefaultButtonActive = setAlpha(0.08, colors.gray['100']);
  const backgroundDefaultButtonDisabled = setAlpha(0.04, colors.gray['100']);

  return {
    ...base,
    name: 'Light',
    type: ThemeTypes.light,

    colors,

    backgroundBody: colors.gray['000'],
    backgroundShadow: colors.black.mute,
    backgroundLayoutWrapper: 'transparent',

    backgroundContent: colors.gray.main,
    backgroundDrawer: colors.gray.main,
    backdropFilterDrawer: 'none',
    backgroundCard: setAlpha(0.04, colors.gray['100']),
    backgroundPopover: colors.gray.main,
    backgroundPopoverHighlight: colors.gray['030'],
    backgroundModal: colors.gray.main,
    borderColorModal: colors.gray.main,
    backgroundContentHover: colors.gray['020'],
    borderColorPopover: setAlpha(0.08, colors.gray['100']),

    backgroundOverlay: setAlpha(0.9, colors.gray.main),

    backgroundSidebar: colors.gray.main,
    backgroundSidebarItemActive: colors.gray['040'],
    backgroundSidebarItemHover: colors.gray['030'],
    borderColorSidebar: colors.gray.main,
    backdropFilterSidebar: 'none',

    colorTextSidebarItem: colors.gray['090'],
    colorTextSidebarItemActive: colors.gray['100'],
    colorTextSidebarItemHover: colors.gray['100'],

    boxShadowContent: 'none',
    boxShadowHeader: 'none',
    boxShadowDraggedItem: `0 2px 25px ${colors.black.mute}`,
    backdropFilterContent: 'none',
    backdropFilterHeader: 'none',

    colorBlotterTableHeader: colors.gray['090'],

    backgroundBlotterTableRow: colors.gray.main,
    backgroundBlotterTableRowSelected: colors.gray['030'],
    backgroundBlotterTableRowHover: colors.gray['020'],
    borderColorBlotterTable: setAlpha(0.12, colors.gray['100']),

    borderColorContent: colors.gray.main,
    backgroundHeader: colors.gray.main,
    borderColorHeader: colors.gray.main,

    backgroundScrollbar: 'transparent',
    colorScrollbar: colors.gray['030'],
    colorScrollbarHover: colors.gray['050'],

    backgroundDefaultButton: backgroundDefaultButton,
    backgroundDefaultButtonHover: backgroundDefaultButtonHover,
    backgroundDefaultButtonFocus: backgroundDefaultButtonFocus,
    backgroundDefaultButtonActive: backgroundDefaultButtonActive,
    backgroundDefaultButtonDisabled: backgroundDefaultButtonDisabled,

    backgroundPriorityButton: setAlpha(0.24, colors.gray['100']),
    backgroundPriorityButtonHover: setAlpha(0.32, colors.gray['100']),
    backgroundPriorityButtonFocus: setAlpha(0.32, colors.gray['100']),
    backgroundPriorityButtonActive: setAlpha(0.24, colors.gray['100']),
    backgroundPriorityButtonDisabled: setAlpha(0.12, colors.gray['100']),

    backgroundPositiveButton: colors.green.default,
    backgroundPositiveButtonHover: colors.green.lighten,
    backgroundPositiveButtonFocus: colors.green.lighten,
    backgroundPositiveButtonActive: colors.green.default,
    backgroundPositiveButtonDisabled: setAlpha(0.5, colors.green.default),

    backgroundNegativeButton: colors.red.default,
    backgroundNegativeButtonHover: colors.red.lighten,
    backgroundNegativeButtonFocus: colors.red.lighten,
    backgroundNegativeButtonActive: colors.red.default,
    backgroundNegativeButtonDisabled: setAlpha(0.5, colors.red.default),

    backgroundWarningButton: colors.yellow.default,
    backgroundWarningButtonHover: colors.yellow.lighten,
    backgroundWarningButtonFocus: colors.yellow.lighten,
    backgroundWarningButtonActive: colors.yellow.default,
    backgroundWarningButtonDisabled: setAlpha(0.5, colors.yellow.default),

    backgroundPrimaryButton: colors.primary.default,
    backgroundPrimaryButtonHover: colors.primary.lighten,
    backgroundPrimaryButtonFocus: colors.primary.lighten,
    backgroundPrimaryButtonActive: colors.primary.default,
    backgroundPrimaryButtonDisabled: setAlpha(0.5, colors.primary.default),

    boxShadowPrimaryButton: 'none',
    boxShadowPrimaryButtonHover: 'none',
    boxShadowPrimaryButtonFocus: 'none',
    boxShadowPrimaryButtonActive: 'none',
    boxShadowPrimaryButtonDisabled: 'none',

    boxShadowPositiveButton: 'none',
    boxShadowPositiveButtonHover: 'none',
    boxShadowPositiveButtonFocus: 'none',
    boxShadowPositiveButtonActive: 'none',
    boxShadowPositiveButtonDisabled: 'none',

    boxShadowNegativeButton: 'none',
    boxShadowNegativeButtonHover: 'none',
    boxShadowNegativeButtonFocus: 'none',
    boxShadowNegativeButtonActive: 'none',
    boxShadowNegativeButtonDisabled: 'none',

    boxShadowPriorityButton: 'none',
    boxShadowPriorityButtonHover: 'none',
    boxShadowPriorityButtonFocus: 'none',
    boxShadowPriorityButtonActive: 'none',
    boxShadowPriorityButtonDisabled: 'none',

    boxShadowDefaultButton: 'none',
    boxShadowDefaultButtonHover: 'none',
    boxShadowDefaultButtonFocus: 'none',
    boxShadowDefaultButtonActive: 'none',
    boxShadowDefaultButtonDisabled: 'none',

    boxShadowInput: 'none',
    boxShadowInputHover: 'none',
    boxShadowInputFocus: 'none',
    boxShadowInputInvalid: 'none',
    boxShadowInputReadOnly: 'none',
    boxShadowInputDisabled: 'none',
    boxShadowInputInvalidFocus: 'none',

    backgroundPrimary: colors.primary.default,
    backgroundPositive: colors.green.default,
    backgroundNegative: colors.red.default,
    backgroundPrimarySubtle: colors.primary.dim,
    backgroundPositiveSubtle: colors.green.dim,
    backgroundNegativeSubtle: colors.red.dim,
    backgroundPrimaryMuted: colors.primary.mute,
    backgroundPositiveMuted: colors.green.mute,
    backgroundNegativeMuted: colors.red.mute,

    backgroundInput: setAlpha(0.04, colors.gray['100']),
    backgroundInputDisabled: setAlpha(0.16, colors.gray['000']),
    backgroundInputReadOnly: setAlpha(0.2, colors.gray['000']),
    backgroundInputInvalid: 'transparent',

    backgroundSelect: setAlpha(0.08, colors.gray['100']),
    backgroundSelectHover: setAlpha(0.12, colors.gray['100']),
    backgroundSelectFocus: setAlpha(0.12, colors.gray['100']),
    backgroundSelectDisabled: setAlpha(0.12, colors.gray['100']),
    backgroundSelectReadOnly: setAlpha(0.08, colors.gray['100']),
    backgroundSelectInvalid: setAlpha(0.08, colors.gray['100']),
    backgroundSelectDropdown: colors.gray['010'],

    backgroundToggle: setAlpha(0.12, colors.gray['100']),
    backgroundToggleChecked: colors.primary.default,
    backgroundToggleSwitch: colors.gray.main,
    backgroundToggleSwitchChecked: colors.white.default,

    backgroundToggleSwitchButton: setAlpha(0.32, colors.gray['000']),
    backgroundToggleSwitchButtonActive: setAlpha(0.12, colors.gray.main),

    backgroundProgressBar: colors.gray['010'],
    backgroundProgressBarIndeterminate: colors.gray['020'],
    backgroundProgressBarMeter: colors.primary.default,

    backgroundCheckbox: colors.gray['020'],
    backgroundCheckboxHover: colors.gray['020'],
    backgroundCheckboxChecked: colors.primary.default,
    backgroundCheckboxCheckedHover: colors.primary.lighten,

    colorCheckboxCheck: colors.white.default,

    borderColorCheckbox: setAlpha(0.08, colors.gray['100']),
    borderColorCheckboxFocus: colors.blue.lighten,
    borderColorCheckboxHover: setAlpha(0.16, colors.gray['100']),
    borderColorCheckboxChecked: colors.primary.default,
    borderColorCheckboxCheckedFocus: colors.blue.lighten,

    backgroundRadiobutton: colors.gray['010'],
    backgroundRadiobuttonHover: colors.gray['020'],
    backgroundRadiobuttonChecked: colors.primary.default,
    backgroundRadiobuttonCheckedHover: colors.primary.lighten,

    colorRadiobuttonDot: colors.white.default,

    borderColorRadiobutton: setAlpha(0.08, colors.gray['100']),
    borderColorRadiobuttonFocus: colors.blue.lighten,
    borderColorRadiobuttonHover: setAlpha(0.16, colors.gray['100']),
    borderColorRadiobuttonChecked: colors.primary.default,
    borderColorRadiobuttonCheckedFocus: colors.blue.lighten,

    colorTextDefault: colors.gray['090'],
    colorTextSubtle: colors.gray['080'],
    colorTextMuted: colors.gray['070'],
    colorTextImportant: colors.gray['100'],
    colorTextAttention: colors.gray['100'],

    colorTextPrimary: colors.primary.lighten,
    colorTextPrimarySubtle: colors.primary.default,
    colorTextPrimaryMuted: colors.primary.dim,
    colorTextPositive: colors.green.lighten,
    colorTextPositiveSubtle: colors.green.default,
    colorTextPositiveMuted: colors.green.dim,
    colorTextNegative: colors.red.lighten,
    colorTextNegativeSubtle: colors.red.default,
    colorTextNegativeMuted: colors.red.mute,
    colorTextWarning: colors.yellow.lighten,
    colorTextWarningSubtle: colors.yellow.default,
    colorTextWarningMuted: colors.yellow.dim,

    colorOrderStatusRejected: colors.yellow.lighten,
    colorOrderStatusFilled: colors.green.lighten,

    colorTextDefaultButton: colors.gray['100'],
    colorTextDefaultButtonHover: colors.gray['100'],
    colorTextDefaultButtonFocus: colors.gray['100'],
    colorTextDefaultButtonActive: colors.gray['100'],
    colorTextDefaultButtonDisabled: setAlpha(0.5, colors.gray['100']),

    colorTextDefaultDimButton: colors.gray['090'],

    colorTextPriorityButton: colors.gray['100'],
    colorTextPriorityButtonHover: colors.gray['100'],
    colorTextPriorityButtonFocus: colors.gray['100'],
    colorTextPriorityButtonActive: colors.gray['100'],
    colorTextPriorityButtonDisabled: setAlpha(0.5, colors.gray['100']),

    colorTextPriorityDimButton: colors.gray['090'],

    colorTextPositiveButton: colors.white.default,
    colorTextPositiveButtonHover: colors.white.default,
    colorTextPositiveButtonFocus: colors.white.default,
    colorTextPositiveButtonActive: colors.white.default,
    colorTextPositiveButtonDisabled: setAlpha(0.5, colors.white.default),

    colorTextNegativeButton: colors.white.default,
    colorTextNegativeButtonHover: colors.white.default,
    colorTextNegativeButtonFocus: colors.white.default,
    colorTextNegativeButtonActive: colors.white.default,
    colorTextNegativeButtonDisabled: setAlpha(0.5, colors.white.default),

    colorTextWarningButton: colors.white.default,
    colorTextWarningButtonHover: colors.white.default,
    colorTextWarningButtonFocus: colors.white.default,
    colorTextWarningButtonActive: colors.white.default,
    colorTextWarningButtonDisabled: setAlpha(0.5, colors.white.default),

    colorTextPrimaryButton: colors.white.default,
    colorTextPrimaryButtonHover: colors.white.default,
    colorTextPrimaryButtonFocus: colors.white.default,
    colorTextPrimaryButtonActive: colors.white.default,
    colorTextPrimaryButtonDisabled: setAlpha(0.5, colors.white.default),

    colorDataBlue: '#2C59D0',

    borderColorInput: setAlpha(0.12, colors.gray['100']),
    borderColorInputHover: setAlpha(0.2, colors.gray['100']),
    borderColorInputFocus: setAlpha(0.56, colors.gray['100']),

    borderColorInputInvalid: colors.red.lighten,
    borderColorInputInvalidFocus: colors.red.lighten,
    borderColorInputDisabled: setAlpha(0.08, colors.gray['100']),
    borderColorInputReadOnly: setAlpha(0.08, colors.gray['100']),

    borderColorSelect: 'transparent',
    borderColorSelectHover: 'transparent',
    borderColorSelectFocus: setAlpha(0.16, colors.gray['100']),
    borderColorSelectInvalid: colors.red.lighten,
    borderColorSelectDisabled: 'transparent',
    borderColorSelectReadOnly: 'transparent',

    borderColorDefaultButton: 'transparent',
    borderColorDefaultButtonHover: 'transparent',
    borderColorDefaultButtonFocus: 'transparent',
    borderColorDefaultButtonActive: 'transparent',
    borderColorDefaultButtonDisabled: 'transparent',

    borderColorPriorityButton: 'transparent',
    borderColorPriorityButtonHover: 'transparent',
    borderColorPriorityButtonFocus: 'transparent',
    borderColorPriorityButtonActive: 'transparent',
    borderColorPriorityButtonDisabled: 'transparent',

    borderColorPositiveButton: 'transparent',
    borderColorPositiveButtonHover: 'transparent',
    borderColorPositiveButtonFocus: 'transparent',
    borderColorPositiveButtonActive: 'transparent',
    borderColorPositiveButtonDisabled: 'transparent',

    borderColorNegativeButton: 'transparent',
    borderColorNegativeButtonHover: 'transparent',
    borderColorNegativeButtonFocus: 'transparent',
    borderColorNegativeButtonActive: 'transparent',
    borderColorNegativeButtonDisabled: 'transparent',

    borderColorPrimaryButton: 'transparent',
    borderColorPrimaryButtonHover: 'transparent',
    borderColorPrimaryButtonFocus: 'transparent',
    borderColorPrimaryButtonActive: 'transparent',
    borderColorPrimaryButtonDisabled: 'transparent',

    borderColorWarningButton: 'transparent',
    borderColorWarningButtonHover: 'transparent',
    borderColorWarningButtonFocus: 'transparent',
    borderColorWarningButtonActive: 'transparent',
    borderColorWarningButtonDisabled: 'transparent',

    borderColorChartAxis: colors.gray['040'],
    borderColorChartGridLine: colors.gray['040'],
    borderColorChartCrosshair: colors.gray['070'],
    backgroundChartUpColor: colors.green.lighten,
    backgroundChartDownColor: colors.red.lighten,
    borderColorChartUpColor: colors.green.lighten,
    borderColorChartDownColor: colors.red.lighten,
    backgroundChart: colors.gray.main,

    borderColorDivider: colors.gray['000'],
    backgroundDivider: setAlpha(0.12, colors.gray['100']),

    borderColorTimeline: colors.gray['070'],
    backgroundTimelineContent: colors.gray['030'],
    backgroundTimelineOverlay: 'linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0))',

    borderColorSelectionTable: colors.gray.main,
    borderColorSelectionTableActive: colors.gray['030'],

    colorTab: colors.gray['090'],
    colorTabHover: colors.gray['100'],
    colorTabActive: colors.gray['090'],
    colorTabSelected: colors.gray['100'],
    borderColorTab: setAlpha(0.12, colors.gray['100']),
    backgroundFilledTab: colors.gray.main,
    backgroundFilledTabList: colors.gray['010'],
    borderColorTabSelected: colors.gray['100'],

    boxShadowTab: 'none',
    boxShadowTabActive: 'none',

    backgroundResizeHandle: colors.gray['060'],
    backgroundResizeHandleHover: colors.gray['040'],

    backgroundLoader: colors.gray['100'],

    borderColorDrawer: colors.gray['020'],

    backgroundTabInput: colors.primary.dim,
    colorTabInputButton: colors.gray['080'],
    colorTabInputButtonHover: colors.gray['080'],
    colorTabInputButtonActive: colors.gray['100'],

    colorStepIconOpen: colors.gray['070'],
    colorStepIconActive: colors.primary.lighten,
    colorStepIconDone: colors.primary.lighten,
    colorStepConnectionOpen: colors.gray['070'],
    colorStepConnectionDone: colors.primary.lighten,

    backgroundAccordionContent: colors.gray['020'],

    colorTextVolumeLadderSpread: colors.gray['100'],
    backgroundVolumeLadderBidSpread: colors.primary.dim,
    backgroundVolumeLadderOfferSpread: colors.primary.dim,
    backgroundVolumeLadderTransparencyDirection: Direction.Asc,

    backgroundDropdownResults: colors.gray.main,
    backgroundDropdownInput: colors.gray.main,
    backgroundDropdownSelected: colors.gray['020'],
    backgroundDropdownHovered: colors.gray['020'],
    backgroundDropdownGroupHeader: colors.gray['020'],
    boxShadowDropdown: '0px 8px 32px rgba(0, 0, 0, 0.2)',

    backgroundModalHeader: colors.gray['020'],

    colorModeCurrencyIcon: 'monochrome',
    colorCurrencyIcon: colors.gray.main,

    colorStackedNumberHighlight: colors.gray['100'],
    colorStackedNumberNoHighlight: colors.gray['090'],

    columnDividerDefault: setAlpha(0.12, colors.gray['100']),
    columnDividerHover: colors.gray['080'],

    chamferMultiplier: 0,
    buttonGroupSharedBackground: 'transparent',

    ...customMappings,
    ...getMutedButtonStyles(colors, customMappings),
  };
}
