import { useEffect, useLayoutEffect, useRef, useState, type ReactNode } from 'react';
import { useDynamicCallback } from '../../hooks';
import { Box, Flex } from '../Core';
import { Icon, IconName } from '../Icons';
import { Text } from '../Text';
import { FadedScrollableEdge, SlideContainer, SlideItem } from './styles';

interface SlideSelectorProps<T> {
  items: NonNullable<T>[];
  selection: T | undefined;
  setSelection: (selection: T | undefined) => void;
  getId: (selection: NonNullable<T>) => string;
  getLabel: (selection: NonNullable<T>) => ReactNode;
  getDescription?: (selection: NonNullable<T>) => ReactNode;
  disabled?: boolean;
}

export function SlideSelector<T>({
  items,
  selection,
  setSelection,
  getId,
  getLabel,
  disabled,
  getDescription,
  ...props
}: SlideSelectorProps<T>) {
  const ref = useRef<HTMLDivElement>(null);

  const selectedID = selection ? getId(selection) : null;
  // Logic for faded edges
  const [scrollState, setScrollState] = useState<{ left: boolean; right: boolean }>({ left: true, right: true });
  const updateScrollState = useDynamicCallback((scrollContainer: HTMLDivElement | null) => {
    if (scrollContainer) {
      const scrollLeft = scrollContainer.scrollLeft;
      const scrollWidth = scrollContainer.scrollWidth;
      const clientWidth = scrollContainer.clientWidth;

      setScrollState({
        right: scrollLeft + clientWidth >= scrollWidth,
        left: scrollLeft <= 0,
      });
    }
  });

  useEffect(() => {
    // When updating selectedID, update the scrollState
    updateScrollState(ref.current);
  }, [selectedID, updateScrollState]);

  useEffect(() => {
    const internalRef = ref.current;
    const internalScrollHandler = () => {
      updateScrollState(internalRef);
    };

    internalRef?.addEventListener('scroll', internalScrollHandler);
    return () => internalRef?.removeEventListener('scroll', internalScrollHandler);
  }, [ref, updateScrollState]);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      const children = ref.current?.children;
      if (children && selectedID != null) {
        let idx = -1;
        for (const child of children) {
          idx++;
          const childId = child.getAttribute('data-id');
          if (childId === selectedID) {
            if (idx === 0) {
              ref.current.scroll({ left: 0, behavior: 'smooth' });
            } else if (idx === children.length - 1) {
              ref.current.scroll({ left: ref.current.scrollWidth, behavior: 'smooth' });
            } else {
              // Consider only scrolling if the entire element is not in view
              child.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
            }
          }
        }
      }
      // Small timeout here to allow element scrollWidth to get updated, in case the width of children change.
    }, 50);
    return () => clearTimeout(timeout);
  }, [selectedID]);

  return (
    <Box {...props} w="100%" overflow="hidden" position="relative" opacity={disabled ? 0.3 : 1}>
      <FadedScrollableEdge opacity={scrollState.left ? 0 : 1} />

      <SlideContainer
        ref={ref}
        data-testid="slide-container"
        // Disable scrolling and pointer events when disabled
        style={{ overflow: disabled ? 'hidden' : undefined, pointerEvents: disabled ? 'none' : undefined }}
      >
        {items.map(item => {
          const id = getId(item);
          const label = getLabel(item);
          const isSelected = id === selectedID;

          return (
            <SlideItem
              key={id}
              onClick={e => {
                e.preventDefault();
                if (!disabled) {
                  if (isSelected) {
                    setSelection(undefined);
                  } else {
                    setSelection(item);
                  }
                }
              }}
              selected={isSelected}
              data-selected={isSelected}
              data-id={id}
              data-label={typeof label === 'string' ? label : undefined}
            >
              <Flex alignItems="center" gap="spacingSmall">
                {isSelected ? <Icon icon={IconName.Check} /> : ''} {label}
              </Flex>
              {getDescription && <Text fontSize="fontSizeSm">{getDescription(item)}</Text>}
            </SlideItem>
          );
        })}
      </SlideContainer>
      <FadedScrollableEdge opacity={scrollState.right ? 0 : 1} />
    </Box>
  );
}
