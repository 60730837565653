import { EntityAdminWS, Text, useCurrentFeeTier, useIntl, useSymbolsFilter, type FeeTier } from '@talos/kyoko';
import type { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  feeTiers: {
    defaultMessage: 'Fee Tiers',
    id: 'FeeTiers.feeTiers',
  },
  feeTier: {
    defaultMessage: 'Fee Tier',
    id: 'FeeTiers.feeTier',
  },
  subtitle: {
    defaultMessage: 'View your Fee Tiers here',
    id: 'FeeTiers.subtitle',
  },
  yourCurrentFeeTierPrefix: {
    defaultMessage: 'Your Current Fee Tier is',
    id: 'FeeTiers.yourCurrentFeeTierPrefix',
  },
  name: {
    defaultMessage: 'Name',
    id: 'FeeTiers.name',
  },
});

export const FeeTiers = () => {
  const symbolFilter = useSymbolsFilter();
  const currentFeeTier = useCurrentFeeTier();
  const { formatMessage } = useIntl();

  const groupColumnDef: ColDef<FeeTier, string> = useMemo(
    () => ({
      headerName: formatMessage(messages.name),
      valueFormatter: ({ context, value }) => {
        const security = value ? context.current.securitiesBySymbol?.get(value) : null;
        return security?.DisplaySymbol ?? security?.Symbol ?? value ?? '';
      },
    }),
    [formatMessage]
  );

  return (
    <EntityAdminWS<FeeTier>
      wsRequest={wsRequest}
      title={formatMessage(messages.feeTiers)}
      subtitle={
        <Text>
          {formatMessage(messages.subtitle)}
          {'. '}
          {currentFeeTier != null ? (
            <Text>
              {formatMessage(messages.yourCurrentFeeTierPrefix)}
              {': '}
              <Text color="colorTextAttention">{currentFeeTier}</Text>{' '}
            </Text>
          ) : null}
        </Text>
      }
      entityName={formatMessage(messages.feeTier)}
      entityIDField="Tier"
      childIDField="Symbol"
      filterableProperties={[symbolFilter]}
      columns={[
        { type: 'text', field: 'Tier', hide: true, sort: '+' },
        { type: 'security', field: 'Symbol', hide: true, sort: '+' },
        { type: 'percent', field: 'Fee', width: 120 },
      ]}
      groupColumnDef={groupColumnDef}
      persistKey="fee-tiers"
    />
  );
};

const wsRequest = { name: 'FeeTier', tag: 'FEE_TIERS_BLOTTER' };
