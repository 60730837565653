import {
  Box,
  Divider,
  FormattedMessage,
  FormControlSizes,
  FormGroup,
  IconButton,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  ThemeToggle,
  ThemeTypes,
  useAuthContext,
  useMixpanel,
  useScreenOrientation,
  useWLLayoutType,
  useWLOrgConfigContext,
  useWLThemeType,
  useWLUser,
  type CustomerUser,
} from '@talos/kyoko';
import { noop } from 'lodash-es';
import { cloneElement, useCallback, useState, type Dispatch, type SetStateAction } from 'react';
import { defineMessages } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import { createGlobalStyle, useTheme } from 'styled-components';
import { MobileLabelMessages } from '../../../layouts/mobileScreens/messages';
import type { LayoutComponentScreen, SingleLayoutComponent } from '../../../layouts/types';
import { Logo } from '../../Logo';
import { Part } from '../Part';
import { useLayoutComponent } from '../useLayoutComponent';
import { usePermissionedMobileScreens } from '../usePermissionedMobileScreens';
import { MobileHeader } from './MobileHeader';
import { MobileTabBar } from './MobileTabBar';
import { Menu, MenuItem, MobileLayoutWrapper, Overlay } from './styles';
import { useWebAuthn, WebAuthContextProvider } from './WebAuthn/WebAuthnContext';
import { WebAuthnToggle } from './WebAuthn/WebAuthnToggle';

const MENU_CUTOFF = 5;

export function MobileLayout() {
  const { layoutType } = useWLLayoutType();
  const orientation = useScreenOrientation();
  const isPortrait = orientation === 'portrait-primary' || orientation === 'portrait-secondary';

  const layoutComponent = useLayoutComponent(layoutType, true);

  return (
    <WebAuthContextProvider>
      <MobileLayoutInternal layoutComponent={layoutComponent} isPortrait={isPortrait} />
    </WebAuthContextProvider>
  );
}

export function MobileLayoutInternal({
  layoutComponent,
  isPortrait,
}: {
  layoutComponent: SingleLayoutComponent;
  isPortrait: boolean;
}) {
  const screens = usePermissionedMobileScreens(layoutComponent);
  const [isOpen, setIsOpen] = useState(false);
  const user = useWLUser();

  const tabBarItems = screens.slice(0, MENU_CUTOFF);
  const menuItems = screens.slice(MENU_CUTOFF);

  return (
    <>
      <MobileGlobalStyles />
      <MobileLayoutWrapper
        isPortrait={isPortrait}
        flex="1"
        flexDirection="column"
        gap="spacingTiny"
        overflow="hidden"
        position="relative"
      >
        {isPortrait && <MobileHeader onMenuClick={setIsOpen} />}
        <Part resizing={false} onMouseDown={noop} column="1" row="1" flex="1 1 0" overflow="hidden" borderRadius={0}>
          <Routes>
            {screens.map(screen => (
              <Route key={screen.path} path={screen.path} element={cloneElement(screen.component)} />
            ))}
          </Routes>
        </Part>
        {isPortrait && (
          <>
            <MobileTabBar items={tabBarItems} />
            <MobileSidebarMenu isOpen={isOpen} setIsOpen={setIsOpen} menuItems={menuItems} user={user} />
          </>
        )}
      </MobileLayoutWrapper>
    </>
  );
}

const sidebarMessages = defineMessages({
  logOut: {
    id: 'Layout.MobileLayout.logOut',
    defaultMessage: 'Log out',
  },
  themeType: {
    id: 'Layout.MobileLayout.themeType',
    defaultMessage: 'Theme Type',
  },
});

function MobileSidebarMenu({
  isOpen,
  setIsOpen,
  menuItems,
  user,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  menuItems: LayoutComponentScreen[];
  user: CustomerUser;
}) {
  const theme = useTheme();
  const { themeType, setThemeType } = useWLThemeType();
  const mixpanel = useMixpanel();
  const { logout } = useAuthContext();
  const { config } = useWLOrgConfigContext();

  const handleThemeType = useCallback(
    (themeType: ThemeTypes) => {
      mixpanel.track(MixpanelEvent.ChangeThemeType, { [MixpanelEventProperty.Type]: themeType });
      setThemeType(themeType);
    },
    [mixpanel, setThemeType]
  );
  const webAuth = useWebAuthn();

  return (
    <>
      <Menu isOpen={isOpen}>
        <Logo themeType={theme.type} />
        <Box
          position="absolute"
          right="spacingMedium"
          top={`calc(${theme.spacingMedium}px + env(safe-area-inset-top))`}
          opacity={0.5}
        >
          <IconButton onClick={() => setIsOpen(false)} size={FormControlSizes.Small} icon={IconName.Close} ghost />
        </Box>
        <Divider my="spacingDefault" />
        {menuItems.map(item => (
          <MenuItem
            key={item.path}
            to={item.path}
            data-testid={`header-nav-${item.path}`}
            onClick={() => setIsOpen(false)}
            startIcon={item.icon}
          >
            <FormattedMessage {...MobileLabelMessages[item.label]} />
          </MenuItem>
        ))}
        <Box mt="auto">
          {config.enableWebAuthn && webAuth && (
            <FormGroup
              px="spacingDefault"
              mb="spacingMedium"
              inline
              label="Require identity verification"
              justifyContent="space-between"
              alignItems="center"
            >
              <WebAuthnToggle webAuth={webAuth} />
            </FormGroup>
          )}
          <FormGroup
            px="spacingDefault"
            mb="spacingMedium"
            inline
            label={<FormattedMessage {...sidebarMessages.themeType} />}
            justifyContent="space-between"
          >
            <ThemeToggle
              themeType={themeType ?? ThemeTypes.dark}
              onChangeThemeType={handleThemeType}
              size={FormControlSizes.Default}
            />
          </FormGroup>
          <Divider my="spacingDefault" />
          <MenuItem onClick={logout} startIcon={IconName.Logout}>
            <FormattedMessage {...sidebarMessages.logOut} />
          </MenuItem>
          <Divider mb="spacingDefault" />
          <MenuItem startIcon={IconName.UserCircle}>{user.DisplayName}</MenuItem>
        </Box>
      </Menu>
      <Overlay hidden={!isOpen} onClick={() => setIsOpen(false)} />
    </>
  );
}

const MobileGlobalStyles = createGlobalStyle`
  html {
    height: calc(100% + env(safe-area-inset-top));
  }
  body {
    height: 100%;
    @media screen and (max-width: 768px) {
      position: fixed;
    }
  }

  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select:focus,
  textarea {
      font-size: 16px;
    }
`;
