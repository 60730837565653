import { parseDate, type WhitelabelParametersFormState } from '@talos/kyoko';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * Calculate the end time of an order, updating if the end time is specified as a Duration.
 *
 * @param params WhitelabelParametersFormState for a new or existing order
 * @returns Order end time
 */
export function useOrderEndTime(params: WhitelabelParametersFormState) {
  const { EndTime } = params;
  const formEndTime = useMemo(() => (EndTime === undefined ? undefined : new Date(EndTime)), [EndTime]);
  const [orderEndTime, setOrderEndTime] = useState<Date | null>(parseDate(null));

  const recalcEndTime = useCallback(
    function recalcEndTime() {
      if (!isNil(formEndTime)) {
        return setOrderEndTime(parseDate(formEndTime));
      } else {
        setOrderEndTime(null);
      }
    },
    [formEndTime]
  );

  useEffect(() => {
    recalcEndTime();
    const timer = setInterval(recalcEndTime, 1000);
    return () => clearInterval(timer);
  }, [recalcEndTime]);

  return orderEndTime;
}
