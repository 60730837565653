import { Box, type CustomerOrder } from '@talos/kyoko';
import { memo, useEffect, useRef } from 'react';
import type { ListChildComponentProps } from 'react-window';
import { ExecutionCard } from '../OrderForms/ExecutionCard';

export interface WindowedExecutionCardDataProps {
  items: CustomerOrder[];
  setRowHeight: (index: number, size: number) => void;
  handleCancelOrder: (orderID: string) => void;
  handleModifyOrder: (order: CustomerOrder) => void;
  handleResubmitOrder: (order: CustomerOrder) => void;
  handleArchiveOrder: ((orderID: string) => void) | null;
}
export const DEFAULT_CARD_HEIGHT = 320;
export const WindowedExecutionCard = memo(function WindowedExecutionCard({
  index,
  style,
  data: { items, setRowHeight, handleCancelOrder, handleModifyOrder, handleResubmitOrder, handleArchiveOrder },
}: ListChildComponentProps<WindowedExecutionCardDataProps>) {
  const order = items[index];
  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(index, rowRef.current.clientHeight);
    }
  }, [index, setRowHeight, order]);

  if (!order) {
    return null;
  }

  return (
    <div style={style} key={order.OrderID} data-testid={`order-card-${order.OrderID}`}>
      <Box ref={rowRef} p="spacingDefault">
        <ExecutionCard
          key={order.OrderID}
          attached={false}
          order={order}
          onCloseCard={undefined}
          onCancelOrder={handleCancelOrder}
          onArchiveOrder={handleArchiveOrder}
          onResubmitOrder={handleResubmitOrder}
          onModifyOrder={handleModifyOrder}
        />
      </Box>
    </div>
  );
});
