import { Toggle } from '@talos/kyoko';

import { useCallback } from 'react';
import type { WebAuthnContextType } from './WebAuthnContext';

export const WebAuthnToggle = ({ webAuth }: { webAuth: WebAuthnContextType }) => {
  const { isAuthenticationRequired, tryUpdatingAuthenticationRequired } = webAuth;

  const onHandleOnChange = useCallback(
    async (newValue: boolean) => {
      return tryUpdatingAuthenticationRequired(newValue);
    },
    [tryUpdatingAuthenticationRequired]
  );

  return (
    <Toggle
      checked={!!isAuthenticationRequired}
      onChange={newValue => {
        onHandleOnChange(newValue);
      }}
    />
  );
};
