import styled, { css, keyframes } from 'styled-components';
import { Box } from '../Core';

const clockwise = keyframes`
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
`;

const spin = keyframes`
  0% {
    stroke-dashoffset: 100;
    transform: rotate(0);
  }
  40%,
  42% {
    stroke-dashoffset: 13;
    transform: rotate(45deg);
  }
  100% {
    stroke-dashoffset: 100;
    transform: rotate(360deg);
  }
`;

export const Timer = styled.svg<{ reverse: boolean; rotation: boolean }>`
  position: absolute;
  ${({ rotation }) =>
    rotation &&
    css`
      animation: ${clockwise} 2.7s linear infinite;
    `};
  animation-direction: ${({ reverse }) => (reverse ? 'reverse' : 'normal')};
  transform-origin: center center;
`;

export const Loader = styled.circle<{ reverse: boolean }>`
  animation: ${spin} 1.5s ease-in-out infinite;
  animation-direction: ${({ reverse }) => (reverse ? 'reverse' : 'normal')};
  transform-origin: center center;
  stroke-dasharray: 113;
  stroke-dashoffset: 100;
  stroke-linecap: round;
  stroke: ${({ theme }) => theme.colorTextImportant};
`;

export const SpinnerWrapper = styled.div<{ size: number }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  vertical-align: middle;
  align-self: center;
  margin: 0 auto;
`;

export const SpinnerContainer = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  pointer-events: none;
`;
