import { useLocation } from 'react-router-dom';
import { useNavigationItems } from './useNavigationItems';

export function useCurrentNavigationItem(isMobile: boolean) {
  const navItems = useNavigationItems();
  const location = useLocation();
  const path = location.pathname;
  /**
   * Usually for mobile, we'll just render the single "Market" nav item.
   */
  return isMobile ? navItems[0] : navItems?.find(ni => ni.path === path);
}
