import { createContext, useCallback, useContext, useState, type PropsWithChildren } from 'react';
import type { DefaultTheme } from 'styled-components';

type CustomThemeContextType = {
  customTheme: DefaultTheme | null;
  setCustomTheme: (theme: DefaultTheme) => void;
  customThemeEnabled: boolean;
  setEnableCustomTheme: (enabled: boolean) => void;
};
export const CustomThemeContext = createContext<CustomThemeContextType>(null!);

export function CustomThemeContextProvider({ children }: PropsWithChildren) {
  const [customTheme, setCustomTheme] = useState<DefaultTheme | null>(null);
  const [customThemeEnabled, setEnableCustomTheme] = useState<boolean>(false);

  const updateCustomTheme = useCallback((theme: DefaultTheme) => {
    setCustomTheme(theme);
  }, []);

  return (
    <CustomThemeContext.Provider
      value={{ customTheme, setCustomTheme: updateCustomTheme, customThemeEnabled, setEnableCustomTheme }}
    >
      {children}
    </CustomThemeContext.Provider>
  );
}

export function useCustomThemeContext() {
  const context = useContext(CustomThemeContext);
  if (!context) {
    throw new Error('useCustomThemeContext must be used within a CustomThemeContextProvider');
  }
  return context;
}
