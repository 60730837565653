import { defineMessages } from 'react-intl';

// https://docs.google.com/spreadsheets/d/1Ht-caaxmKB-5atMeSoCc5rvZUZOTNyX_qCW9SOUcMQA/edit?gid=1896266845#gid=1896266845

export const backendTextOrderRateLimitBreachedText =
  'Order rate limit breached. Please reach out to support to increase this limit.';
export const backendTextOuoteRejectedText = 'Quote rejected. Please contact support';
export const backendTextOuoteRequestRejectedText = 'QuoteRequest rejected';
export const backendTextOrderRejectedText1 = 'Order rejected';
export const backendTextOrderRejectedText2 = 'Order Rejected';
export const backendTextErrorSubmittingOrderText = 'Error submitting order';
export const backendTextSelectedStrategyParamsInvalidText = 'Selected strategy params are invalid';
export const backendTextInternalErrorText = 'Internal error. Please contact support';
export const backendTextRejectedReplace = 'Order Cancel Replace Request Rejected';
export const backendTextPriceRequiredForLimitOrder = 'price required for limit order';
export const backendTextNoAvailableMarkets = 'no available market for order';
export const backendTextInternalErrorSamplingMarket = 'Internal error sampling market data. Please contact support';
export const backendTextDuplicateClOrdID = 'duplicate ClOrdID';
export const backendTextRFQNotFound = 'RFQ not found';
export const backendTextOrderRejectedTryAgain = 'Order rejected. Please try again.';
export const backendTextRFQIDRequired = 'RFQID is required for CustomerNewOrderSingle';
export const backendTextBuyStopPriceGreater = 'Buy Stop price must be greater than the current market price';
export const backendTextSellStopPriceLess = 'Sell Stop price must be less than the current market price';
export const backendTextUnableToSubmitOrder = 'Unable to submit order - please try again later.';
export const backendTextRFQNotApproved = 'RFQ not yet approved for trading';
export const backendTextNoMarketToTrade = 'Cannot find market to trade on';
export const backendTextQuoteExpired = 'Quote is already expired';
export const backendTextOrderRequestStale1 = 'order request is stale';
export const backendTextOrderRequestStale2 = 'Order request is stale';
export const backendTextSecurityDisabled = 'Security disabled';
export const backendTextOrderAlreadySubmittedForQuote = 'Order has already been submitted for quote';
export const backendTextTimeoutRiskApproval = 'timeout getting risk approval';
export const backendTextExceededLimits = 'exceeded limits';

// Patterns
export const backendTextGreaterThanMaxSizeLimit = 'Order quantity greater than max order size limit';
export const backendTextCustomerSecurityNotFound = 'Customer security not found';
export const backendTextStrategyNotAllowedForSecurity = 'Selected OrdType and Strategy is not allowed for security';
export const backendTextNoQuoteAvailable = 'No quote available to execute order';
export const backendTextInvalidCurrency = 'Invalid currency';
export const backendTextOrderQtyNotMatchingRFQ = 'Order quantity does not match RFQ order quantity';
export const backendTextUnsupportedTimeInForce = 'Unsupported TimeInForce for order';
export const backendTextInsufficientBalance = 'Insufficient balance';
export const backendTextInvalidPricePrecision = 'Invalid price, decrease decimal precision';
export const backendTextInvalidQuantity = 'Invalid quantity';

// PATTERNS
export const BackendTextPatterns: { pattern: RegExp; id: keyof typeof backendTextTranslations }[] = [
  { pattern: /Insufficient balance: .+ exceeds available .+/, id: 'insufficientBalance' },
  { pattern: /Order to .+? greater than max order size limit .+/, id: 'greaterThanMaxSizeLimit' },
  {
    pattern: /Order with OrdType .+ and Strategy .+ is not allowed for security .+/,
    id: 'strategyNotAllowedForSecurity',
  },
  { pattern: /No quote available to execute .+ order/, id: 'noQuoteAvailable' },
  { pattern: /invalid currency, should be .+/, id: 'invalidCurrency' },
  { pattern: /OrderQty (.+) does not match RFQ OrderQty (.+)/, id: 'orderQtyNotMatchingRFQ' },
  { pattern: /unsupported timeInForce for .+ order/, id: 'unsupportedTimeInForce' },
  { pattern: /customer security not found for .+ securityID/, id: 'customerSecurityNotFound' },
  { pattern: /invalid price: (.+), must be multiplication of (.+)/, id: 'invalidPricePrecision' },
  { pattern: /invalid quantity: (.+), must be (.+) or greater/, id: 'invalidQuantity' },
];

export const backendTextTranslations = defineMessages({
  orderRateLimitBreachedText: {
    id: 'tokens.backendText.orderRateLimitBreachedText',
    defaultMessage: 'Order rate limit breached. Please reach out to support to increase this limit.',
  },
  quoteRejectedText: {
    id: 'tokens.backendText.quoteRejectedText',
    defaultMessage: 'Quote rejected. Please contact support',
  },
  quoteRequestRejectedText: {
    id: 'tokens.backendText.quoteRequestRejectedText',
    defaultMessage: 'QuoteRequest rejected',
  },
  orderRejectedText: {
    id: 'tokens.backendText.orderRejectedText',
    defaultMessage: 'Order rejected',
  },
  errorSubmittingOrderText: {
    id: 'tokens.backendText.errorSubmittingOrderText',
    defaultMessage: 'Error submitting order',
  },
  selectedStrategyParamsInvalidText: {
    id: 'tokens.backendText.selectedStrategyParamsInvalidText',
    defaultMessage: 'Selected strategy params are invalid',
  },
  internalErrorText: {
    id: 'tokens.backendText.internalErrorText',
    defaultMessage: 'Internal error. Please contact support',
  },
  rejectedReplace: {
    id: 'tokens.backendText.rejectedReplace',
    defaultMessage: 'Order Cancel Replace Request Rejected',
  },
  priceRequiredForLimitOrder: {
    id: 'tokens.backendText.priceRequiredForLimitOrder',
    defaultMessage: 'price required for limit order',
  },
  sellLessThanMaxSizeLimit: {
    id: 'tokens.backendText.sellLessThanMaxSizeLimit',
    defaultMessage: 'Sell Stop price must be less than the current market price',
  },
  buyGreaterThanMaxSizeLimit: {
    id: 'tokens.backendText.buyGreaterThanMaxSizeLimit',
    defaultMessage: 'Buy Stop price must be greater than the current market price',
  },
  greaterThanMaxSizeLimit: {
    id: 'tokens.backendText.greaterThanMaxSizeLimit',
    defaultMessage: 'Order quantity greater than max order size limit',
  },
  customerSecurityNotFound: {
    id: 'tokens.backendText.customerSecurityNotFound',
    defaultMessage: 'Customer security not found',
  },
  strategyNotAllowedForSecurity: {
    id: 'tokens.backendText.strategyNotAllowedForSecurity',
    defaultMessage: 'Selected OrdType and Strategy is not allowed for security',
  },
  noQuoteAvailable: {
    id: 'tokens.backendText.noQuoteAvailable',
    defaultMessage: 'No quote available to execute order',
  },
  invalidCurrency: {
    id: 'tokens.backendText.invalidCurrency',
    defaultMessage: 'Invalid currency',
  },
  orderQtyNotMatchingRFQ: {
    id: 'tokens.backendText.orderQtyNotMatchingRFQ',
    defaultMessage: 'Order quantity does not match RFQ order quantity',
  },
  unsupportedTimeInForce: {
    id: 'tokens.backendText.unsupportedTimeInForce',
    defaultMessage: 'Unsupported TimeInForce for order',
  },
  insufficientBalance: {
    id: 'tokens.backendText.insufficientBalance',
    defaultMessage: 'Insufficient balance',
  },
  invalidPricePrecision: {
    id: 'tokens.backendText.invalidPricePrecision',
    defaultMessage: 'Invalid price, decrease decimal precision',
  },
  noAvailableMarkets: {
    defaultMessage: 'No available market for order',
    id: 'tokens.backendText.noAvailableMarkets',
  },
  internalErrorSamplingMarket: {
    defaultMessage: 'Internal error sampling market data. Please contact support',
    id: 'tokens.backendText.internalErrorSamplingMarket',
  },
  duplicateClOrdID: {
    defaultMessage: 'Duplicate ClOrdID',
    id: 'tokens.backendText.duplicateClOrdID',
  },
  rFQNotFound: {
    defaultMessage: 'RFQ not found',
    id: 'tokens.backendText.rFQNotFound',
  },
  orderRejectedTryAgain: {
    defaultMessage: 'Order rejected. Please try again.',
    id: 'tokens.backendText.orderRejectedTryAgain',
  },
  rFQIDRequired: {
    defaultMessage: 'RFQID is required for CustomerNewOrderSingle',
    id: 'tokens.backendText.rFQIDRequired',
  },
  unableToSubmitOrder: {
    id: 'tokens.backendText.unableToSubmitOrder',
    defaultMessage: 'Unable to submit order - please try again later.',
  },
  rfqNotApproved: {
    id: 'tokens.backendText.rfqNotApproved',
    defaultMessage: 'RFQ not yet approved for trading',
  },
  quoteExpired: {
    id: 'tokens.backendText.quoteExpired',
    defaultMessage: 'Quote is already expired',
  },
  noMarketToTrade: {
    id: 'tokens.backendText.noMarketToTrade',
    defaultMessage: 'Cannot find market to trade on',
  },
  orderRequestStale: {
    id: 'tokens.backendText.orderRequestStale',
    defaultMessage: 'Order request is stale',
  },
  securityDisabled: {
    id: 'tokens.backendText.securityDisabled',
    defaultMessage: 'Security disabled',
  },
  orderAlreadySubmittedForQuote: {
    id: 'tokens.backendText.orderAlreadySubmittedForQuote',
    defaultMessage: 'Order has already been submitted for quote',
  },
  timeoutRiskApproval: {
    id: 'tokens.backendText.timeoutRiskApproval',
    defaultMessage: 'timeout getting risk approval',
  },
  exceedingLimits: {
    id: 'tokens.backendText.exceedingLimits',
    defaultMessage: 'exceeded limits',
  },
  invalidQuantity: {
    id: 'tokens.backendText.invalidQuantity',
    defaultMessage: 'Invalid quantity',
  },
});

export const BackendTextToKeyRecord = {
  [backendTextOrderRateLimitBreachedText]: 'orderRateLimitBreachedText',
  [backendTextOuoteRejectedText]: 'quoteRejectedText',
  [backendTextOuoteRequestRejectedText]: 'quoteRequestRejectedText',
  [backendTextOrderRejectedText1]: 'orderRejectedText',
  [backendTextOrderRejectedText2]: 'orderRejectedText',
  [backendTextErrorSubmittingOrderText]: 'errorSubmittingOrderText',
  [backendTextSelectedStrategyParamsInvalidText]: 'selectedStrategyParamsInvalidText',
  [backendTextInternalErrorText]: 'internalErrorText',
  [backendTextRejectedReplace]: 'rejectedReplace',
  [backendTextPriceRequiredForLimitOrder]: 'priceRequiredForLimitOrder',
  [backendTextSellStopPriceLess]: 'sellLessThanMaxSizeLimit',
  [backendTextBuyStopPriceGreater]: 'buyGreaterThanMaxSizeLimit',
  [backendTextGreaterThanMaxSizeLimit]: 'greaterThanMaxSizeLimit',
  [backendTextCustomerSecurityNotFound]: 'customerSecurityNotFound',
  [backendTextStrategyNotAllowedForSecurity]: 'strategyNotAllowedForSecurity',
  [backendTextNoQuoteAvailable]: 'noQuoteAvailable',
  [backendTextInvalidCurrency]: 'invalidCurrency',
  [backendTextOrderQtyNotMatchingRFQ]: 'orderQtyNotMatchingRFQ',
  [backendTextUnsupportedTimeInForce]: 'unsupportedTimeInForce',
  [backendTextInsufficientBalance]: 'insufficientBalance',
  [backendTextInvalidPricePrecision]: 'invalidPricePrecision',
  [backendTextNoAvailableMarkets]: 'noAvailableMarkets',
  [backendTextInternalErrorSamplingMarket]: 'internalErrorSamplingMarket',
  [backendTextDuplicateClOrdID]: 'duplicateClOrdID',
  [backendTextOrderRejectedTryAgain]: 'orderRejectedTryAgain',
  [backendTextRFQNotFound]: 'rFQNotFound',
  [backendTextRFQIDRequired]: 'rFQIDRequired',
  [backendTextUnableToSubmitOrder]: 'unableToSubmitOrder',
  [backendTextRFQNotApproved]: 'rfqNotApproved',
  [backendTextNoMarketToTrade]: 'noMarketToTrade',
  [backendTextQuoteExpired]: 'quoteExpired',
  [backendTextOrderRequestStale1]: 'orderRequestStale',
  [backendTextOrderRequestStale2]: 'orderRequestStale',
  [backendTextSecurityDisabled]: 'securityDisabled',
  [backendTextOrderAlreadySubmittedForQuote]: 'orderAlreadySubmittedForQuote',
  [backendTextTimeoutRiskApproval]: 'timeoutRiskApproval',
  [backendTextExceededLimits]: 'exceedingLimits',
  [backendTextInvalidQuantity]: 'invalidQuantity',
} satisfies Record<
  (typeof backendTextTranslations)[keyof typeof backendTextTranslations]['defaultMessage'],
  keyof typeof backendTextTranslations
>;
