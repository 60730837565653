import {
  DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
  ORDER,
  setFocusedOrderID,
  useAccordionFilterBuilder,
  useConstant,
  useGetNewEntityWithTranslatedTextPipe,
  usePersistedBlotterTable,
  useWLOrderFormDispatch,
  useWsBlotterTable,
  type CustomerOrder,
  type OrdStatusEnum,
  type SideEnum,
  type WebsocketRequest,
} from '@talos/kyoko';
import { useCallback, useEffect, useMemo } from 'react';

import { useOMSStateContext } from 'providers/OMSStateProvider';

import type { CellClickedEvent } from 'ag-grid-community';
import { onlyServerFilterKeys, useOrdersFilter } from 'components/Filters/FilterBuilder/useOrdersFilter';
import { useOrderHistoryBlotterColumns } from './useOrderHistoryBlotterColumns';

interface OrderHistoryWebsocketRequest extends WebsocketRequest {
  MarketAccounts?: string[];
  Sides?: SideEnum[];
  Statuses?: OrdStatusEnum[];
  Strategies?: string[];
  Symbols?: string[];
}

interface UseOrderHistoryBlotterProps {
  maxRows: number;
}

const filter = { StartDate: Sugar.Date.create('a year ago') };

const DEFAULT_SORT = '-SubmitTime';

export const useOrderHistoryBlotter = ({ maxRows }: UseOrderHistoryBlotterProps) => {
  const { selectedClOrdID } = useOMSStateContext();

  const request: OrderHistoryWebsocketRequest = useConstant({
    name: ORDER,
    tag: 'ORDER_HISTORY',
    sort_by: DEFAULT_SORT,
  });

  const columns = useOrderHistoryBlotterColumns();
  const persistedBlotterTable = usePersistedBlotterTable<CustomerOrder>(`orderHistory`, {
    columns: columns,
    sort: DEFAULT_SORT,
    filter,
  });

  const filteredOrders = useOrdersFilter({ persistedBlotterTable });
  const { clientSideFilter: clientLocalFilter, blotterTableFilterProps, filterBuilderProps } = filteredOrders;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: false },
    filterBuilderProps,
  });
  const orderFormDispatch = useWLOrderFormDispatch();
  const handleOnCellClicked = useCallback(
    (event: CellClickedEvent<CustomerOrder, any>) => {
      if (event.data != null) {
        orderFormDispatch(setFocusedOrderID(event.data.OrderID));
      }
    },
    [orderFormDispatch]
  );
  const translateCustomerOrderTextPipe = useGetNewEntityWithTranslatedTextPipe<CustomerOrder>();
  const blotterTable = useWsBlotterTable<WebsocketRequest, CustomerOrder>({
    initialRequest: request,
    columns: persistedBlotterTable.columns,
    filter: onlyServerFilterKeys(filteredOrders.filter),
    persistence: persistedBlotterTable,
    pipe: translateCustomerOrderTextPipe,
    rowID: 'OrderID',
    clientLocalFilter,
    flashRows: ['add'],
    startingRowLimit: maxRows,
    gridOptions: {
      rowSelection: DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
      rowHeight: 32,
      onCellClicked: handleOnCellClicked,
    },
  });

  useEffect(() => {
    for (const blotterRow of blotterTable.getRows()) {
      blotterRow.setSelected(blotterRow.data.ClOrdID === selectedClOrdID);
    }
  }, [blotterTable, selectedClOrdID]);

  return useMemo(
    () => ({
      blotterTable,
      blotterTableFilterProps,
      filterBuilderAccordion,
    }),
    [blotterTable, blotterTableFilterProps, filterBuilderAccordion]
  );
};
