import { EMPTY_ARRAY, useWLOrgConfigContext, useWLRoleAuth, type WLOrgConfig } from '@talos/kyoko';
import { compact, concat } from 'lodash-es';
import { useMemo } from 'react';
import { AddressesScreen, FeeTiersScreen, SettingsScreen } from '../../layouts/mobileScreens/screenLibrary';
import type { LayoutComponent } from '../../layouts/types';
import { checkRequiredPermissions } from '../../utils/layoutPermissions';

export function usePermissionedMobileScreens(
  layoutComponent: LayoutComponent
): NonNullable<LayoutComponent['mobileScreens']> {
  const { isAuthorized } = useWLRoleAuth();
  const { config } = useWLOrgConfigContext();

  return useMemo(
    () => getPermissionedMobileScreens(layoutComponent.mobileScreens, config, isAuthorized),
    [config, isAuthorized, layoutComponent.mobileScreens]
  );
}

export const getPermissionedMobileScreens = (
  mobileScreens: LayoutComponent['mobileScreens'] = EMPTY_ARRAY,
  config: WLOrgConfig,
  isAuthorized: (action: string) => boolean
) => {
  const screens = [...mobileScreens];
  // Add screens from config
  if (config.navigationItems) {
    for (const navItem of config.navigationItems) {
      switch (navItem.path) {
        case '/settings':
          screens.push({
            ...SettingsScreen,
            requiredPermissions: compact(concat(SettingsScreen.requiredPermissions, navItem.requiredPermissions)),
            requiredConfigs: compact(concat(SettingsScreen.requiredConfigs, navItem.requiredConfigs)),
          });
          break;
        case '/addresses':
          screens.push({
            ...AddressesScreen,
            requiredPermissions: compact(concat(AddressesScreen.requiredPermissions, navItem.requiredPermissions)),
            requiredConfigs: compact(concat(AddressesScreen.requiredConfigs, navItem.requiredConfigs)),
          });
          break;
        case '/fee-tiers':
          screens.push({
            ...FeeTiersScreen,
            requiredPermissions: compact(concat(FeeTiersScreen.requiredPermissions, navItem.requiredPermissions)),
            requiredConfigs: compact(concat(FeeTiersScreen.requiredConfigs, navItem.requiredConfigs)),
          });
          break;
      }
    }
  }

  return screens.filter(screen => {
    return (
      checkRequiredPermissions(screen.requiredPermissions, isAuthorized) &&
      (screen.requiredConfigs == null || screen.requiredConfigs?.every(prop => config[prop]))
    );
  });
};
