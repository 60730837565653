import type { FocusEventHandler, MouseEvent } from 'react';
import { useTheme } from 'styled-components';
import { useDynamicCallback } from '../../../hooks';
import { IconButton } from '../../Button';
import { Box, Grid, HStack } from '../../Core';
import { Icon, IconName } from '../../Icons';
import { FormControlSizes } from '../types';
import { getFontSize, getOneFormControlSizeSmaller } from '../utils';
import { BaseSelectWrapper, EllipsisText, StyledSelection, SuffixIconButtons } from './styles';
import type { BaseSelectProps } from './types';

/**
 * Simple component that just hooks up a dropdown to a SelectLike header
 */
export const BaseSelect = <T,>({
  children,
  prefix,
  suffix,
  style,
  className,
  invalid,
  touched,
  disabled,
  wrapperRef,
  width,
  size = FormControlSizes.Default,
  onClick,
  onClearClick,
  onFocus,
  clearable,
  getLabel,
  isDropdownOpened,
  placeholder = '',
  centered = false,
  id,
  placeholderColor,
  getDescription,
  gridTemplateColumns,
  showTitle = true,
  showChevron = true,
  dataTestID,
  ...props
}: BaseSelectProps<T>) => {
  const theme = useTheme();

  const handleOpenCloseClick = useDynamicCallback((e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (disabled) {
      return;
    }

    onClick?.(e);
  });

  const handleClearClick = useDynamicCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClearClick?.(e);
  });

  const handleFocus: FocusEventHandler<HTMLElement> = useDynamicCallback(e => {
    if (!disabled) {
      onFocus?.(e);
    }
  });

  const effectiveColumns = gridTemplateColumns ?? getColumns(!!prefix, !!suffix, !!clearable, !!showChevron);

  return (
    <>
      <BaseSelectWrapper
        size={size}
        style={style}
        className={className}
        invalid={invalid}
        touched={touched}
        disabled={disabled}
        ref={wrapperRef}
        width={width}
        isDropdownOpened={isDropdownOpened}
        isValueDefined={props.value != null}
        onFocus={handleFocus}
        onClick={handleOpenCloseClick}
        id={id}
        tabIndex={0} // <-- this tabIndex allows this label to receive focus
        data-testid={dataTestID ?? 'base-select-button'}
        data-label={props.value ? getLabel(props.value) : ''}
        centered={centered}
        showDescription={getDescription != null}
        readOnly={props.readOnly}
      >
        <Grid
          gridTemplateColumns={effectiveColumns}
          w={centered ? undefined : '100%'}
          maxWidth="100%"
          gap={prefix || props.value || suffix || placeholder ? 'spacingSmall' : 0}
          alignItems="center"
          lineHeight={1}
        >
          {prefix && (
            <Box whiteSpace="nowrap" data-testid="select-button-prefix">
              {prefix}
            </Box>
          )}
          <Box overflow="hidden">
            <StyledSelection>
              {props.customButtonLayout != null ? (
                <props.customButtonLayout item={props.value} />
              ) : props.value == null ? (
                <EllipsisText color={placeholderColor ?? theme.colors.gray['080']}>{placeholder}</EllipsisText>
              ) : (
                <>
                  <EllipsisText title={showTitle ? getLabel(props.value) : undefined}>
                    {getLabel(props.value)}
                  </EllipsisText>
                  {getDescription != null && (
                    <EllipsisText
                      color="colorTextSubtle"
                      fontSize={`${getFontSize(theme, getOneFormControlSizeSmaller(size))}rem`}
                      title={showTitle ? getDescription(props.value) : undefined}
                    >
                      {getDescription(props.value)}
                    </EllipsisText>
                  )}
                </>
              )}
            </StyledSelection>
          </Box>
          {(suffix || clearable || showChevron) && (
            <HStack>
              {suffix}
              <SuffixIconButtons>
                {clearable && !disabled && props.value != null && (
                  <IconButton
                    icon={IconName.Close}
                    size={(size ?? FormControlSizes.Default) - 0.5}
                    onClick={handleClearClick}
                    round
                    ghost
                    tabIndex={-1}
                    onFocus={e => e.stopPropagation()}
                    data-testid="dropdown-clear"
                  />
                )}
                {showChevron && (
                  <Icon icon={IconName.ChevronDown} color={props.readOnly || disabled ? 'colorTextMuted' : undefined} />
                )}
              </SuffixIconButtons>
            </HStack>
          )}
        </Grid>
      </BaseSelectWrapper>
      {children} {/* <-- dropdown is rendered here */}
    </>
  );
};

function getColumns(prefix: boolean, suffix: boolean, clearable: boolean, showChevron: boolean) {
  return `${prefix ? 'min-content ' : ''} 1fr ${suffix || clearable || showChevron ? 'min-content' : ''}`;
}
