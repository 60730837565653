import { Box, Grid } from '@talos/kyoko';
import styled from 'styled-components';

export const NavigationHeaderWrapper = styled(Box)`
  background: ${({ theme }) => theme.backgroundHeader};
  ${({ theme }) =>
    theme.borderColorHeader !== theme.backgroundHeader ? `border-bottom: solid 1px ${theme.borderColorHeader}` : ''};
  backdrop-filter: ${({ theme }) => theme.backdropFilterHeader};
  box-shadow: ${({ theme }) => theme.boxShadowHeader};
  // Put Edit Layout dropdown on top of semi-transparent content
  z-index: 2;
`;
NavigationHeaderWrapper.defaultProps = {
  px: '1.25rem',
  pt: 'spacingDefault',
  pb: 'spacingDefault',
};

export const HEADER_CONTENT_HEIGHT = 38;

export const HeaderContent = styled.div<{ maxWidth?: number }>`
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 0 ${({ theme }) => theme.spacingMedium}px;
  height: ${HEADER_CONTENT_HEIGHT}px;
`;

export const Section = styled(Grid)`
  grid-auto-flow: column;
  align-items: center;
  grid-gap: ${({ theme }) => theme.spacingDefault}px;
`;
