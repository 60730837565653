import type { Store } from '@reduxjs/toolkit';
import { firstValueFrom, Observable, timeout, type Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { findLast } from 'lodash-es';
import { NEW_ORDER_SINGLE, ORDER, QUOTE, QUOTE_CANCEL_REQUEST, QUOTE_REQUEST } from '../../../tokens';
import {
  OrderFormSides,
  OrdTypeEnum,
  QuoteStatusEnum,
  SideEnum,
  type CustomerOrder,
  type CustomerQuote,
} from '../../../types';
import { formattedDateForSubscription, logger } from '../../../utils';
import type { IWebSocketClient } from '../../WebSocketClient';
import {
  cleanRFQ,
  selectQuoteReqID,
  setError,
  setOrderStep,
  setQuote,
  type OrderFormState,
  type OrderState,
} from '../state';
import { EXEC_REPORT_RESPONSE_TIMEOUT_MS } from '../tokens';
import type { RootState } from '../WLOrderFormStore';
import type { WhiteLabelAcceptQuote } from './types';

// Its what backend expects. not ClOrdId, it expects ClOrderID.
// https://docs.talostrading.com/#customer-websocket-subscriptions:~:text=this%20execution%20report.-,Order,-Request%20stream%20of
// When this is fixed in the back-end, this should be changed to ClOrdID
type OrderActionRequest = {
  name: string;
  ClOrderID: string;
  ClOrdID: string;
  tag: string;
};

/**
 * Handles websocket communication around Whitelabel-Mobile RFQs
 * - Does transformation of UI form model to order placement calls
 * - Triggers state changes and showing errors when hearing acks on rfqs
 */
export class RFQService {
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<RootState>,
    private webSocketClient: IWebSocketClient<unknown> // We subscribe to the quotes to be able to accept and reject them
  ) {}

  public requestQuote() {
    const { quantityField, sideField, symbolField, orderCurrencyField, marketAccountField } = this.formState;

    const QuoteReqID: string = uuid();
    const transactTime = formattedDateForSubscription(new Date());

    const quoteRequestData = {
      Side: sideField.value === OrderFormSides.Twoway ? undefined : sideField.value,
      QuoteReqID,
      OrderQty: quantityField.value,
      Currency: orderCurrencyField.value,
      Symbol: symbolField.value?.Symbol,
      TransactTime: transactTime,
      Parameters: {},
      MarketAccount: marketAccountField.value?.SourceAccountID,
    };

    this.webSocketClient.registerPublication({
      type: QUOTE_REQUEST,
      data: [quoteRequestData],
    });
    this.store.dispatch(setOrderStep({ step: 'rfqRequested', quoteReqID: QuoteReqID }));

    const quoteObs = new Observable<CustomerQuote>(observer => {
      const quoteRequest = {
        name: QUOTE,
        tag: 'RFQService',
        Symbol: symbolField.value?.Symbol,
        HideAPICalls: true,
        sort_by: '-Timestamp',
        QuoteReqID,
      };

      const address = uuid();
      this.webSocketClient.registerSubscription(address, [quoteRequest], (err, json) => {
        if (json?.data?.length) {
          const quote = findLast(json.data, (d: CustomerQuote) => d.QuoteReqID === QuoteReqID) as
            | CustomerQuote
            | undefined;
          if (quote) {
            observer.next(quote);
          }
        }
      });

      return () => this.webSocketClient.unregisterSubscription(address);
    });

    const sub = quoteObs.subscribe(q => {
      // if we do not have a quote request id, we do not want to set the quote,
      // and we also want to unsubscribe from the quote since we're no longer interested in it
      const reqID = selectQuoteReqID(this.store.getState());
      if (!reqID) {
        sub.unsubscribe();
        return;
      }
      this.store.dispatch(setQuote(q));
      // If the quote is canceled (i.e timed out), set the step to expired and then clean up.
      if (q.QuoteStatus === QuoteStatusEnum.Canceled) {
        sub.unsubscribe();
        if (this.state.orderStep === 'rfqRequested') {
          this.store.dispatch(setOrderStep({ step: 'rfq_expired' }));
          setTimeout(() => {
            this.store.dispatch(cleanRFQ());
          }, EXEC_REPORT_RESPONSE_TIMEOUT_MS);
        }
      }
    });

    this.subscriptions.push(sub);
  }

  public acceptQuote = ({ side, allowedSlippage }: WhiteLabelAcceptQuote) => {
    const clOrdID: string = uuid();
    const quote = this.store.getState().order.quote;
    if (!quote || quote.QuoteReqID !== this.store.getState().order.quoteReqID) {
      return;
    }

    const acceptQuote = () => {
      this.store.dispatch(setOrderStep({ step: 'rfq_accepted' }));
      const price = side === SideEnum.Buy ? quote.OfferPx : quote.BidPx;

      this.webSocketClient.registerPublication({
        type: NEW_ORDER_SINGLE,
        data: [
          {
            ClOrdID: clOrdID,
            Price: price,
            Side: side,
            RFQID: quote.RFQID,
            Symbol: quote.Symbol,
            Currency: quote.Currency,
            OrderQty: quote.OrderQty,
            OrdType: OrdTypeEnum.LimitAllIn,
            MarketAccount: quote.MarketAccount,
            AllowedSlippage: allowedSlippage,
          },
        ],
      });
    };

    this.createActionPromise({ clOrderID: clOrdID, action: acceptQuote })
      .then(order => {
        this.store.dispatch(setOrderStep({ step: 'new_confirmation', orderID: order.OrderID }));
      })
      .catch(() => {
        this.store.dispatch(setError({ text: 'no_order_response', clOrderID: clOrdID }));
      })
      .finally(() => {
        setTimeout(() => {
          this.store.dispatch(cleanRFQ());
        }, EXEC_REPORT_RESPONSE_TIMEOUT_MS);
      });

    return clOrdID;
  };

  public cancelQuote = (rFQID?: string, quoteReqID?: string) => {
    this.store.dispatch(setOrderStep({ step: 'rfq_cancelled' }));
    if (rFQID) {
      this.webSocketClient.registerPublication({
        type: QUOTE_CANCEL_REQUEST,
        data: [
          {
            RFQID: rFQID,
            QuoteReqID: quoteReqID,
            TransactTime: formattedDateForSubscription(new Date()),
          },
        ],
      });
    }
  };

  private createActionPromise = ({
    clOrderID,
    action,
  }: {
    clOrderID: string;
    action: () => void;
  }): Promise<CustomerOrder> => {
    const observable = new Observable<CustomerOrder>(observer => {
      const orderRequest: OrderActionRequest = {
        name: ORDER,
        tag: 'RFQService/createActionPromise',
        ClOrdID: clOrderID,
        // Its what backend expects. not ClOrdId, it expects ClOrderID.
        ClOrderID: clOrderID,
      };

      const address = uuid();
      this.webSocketClient.registerSubscription(address, [orderRequest], (err, json) => {
        if (json?.data?.length) {
          const report = findLast(json.data, (d: CustomerOrder) => d.ClOrdID === clOrderID) as
            | CustomerOrder
            | undefined;
          if (report) {
            observer.next(report);
          }
        }
        if (err) {
          logger.error(new Error('Received error when subscribing to order in RFQ form'), {
            extra: {
              request: orderRequest,
              error: err,
            },
          });
        }
      });
      // perform the action after subscription is set up, this ensures that we'll get the first update.
      action();

      return () => this.webSocketClient.unregisterSubscription(address);
    });
    return firstValueFrom(
      observable.pipe(
        // If there is no response within 5 seconds, we reject the promise
        timeout(EXEC_REPORT_RESPONSE_TIMEOUT_MS)
      )
    );
  };

  private get formState(): OrderFormState {
    return this.state.form;
  }

  private get state(): OrderState {
    return this.store.getState().order;
  }

  public destroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
