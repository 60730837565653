import { useCallback, useMemo } from 'react';
import { map, pipe } from 'rxjs';
import type { ColDefIntl, CompositePipeFunction } from '../components';
import { BackendTextPatterns, BackendTextToKeyRecord, backendTextTranslations } from '../tokens';
import { isKeyIn } from '../utils';
import { useIntl } from './useIntl';
export const useTranslateBackendText = () => {
  const intl = useIntl();
  return useCallback(
    (backendText: string | undefined): typeof backendText => {
      return translateBackendText(backendText, intl);
    },
    [intl]
  );
};

const translateBackendText = (backendText: string | undefined, intl: ColDefIntl) => {
  // Only translate backend text in whitelabel and whitelabel-mobile
  if (
    (import.meta.env.VITE_APP !== 'whitelabel' && import.meta.env.VITE_APP !== 'whitelabel-mobile') ||
    intl.preferServerEnglishText
  ) {
    return backendText;
  }
  if (typeof backendText !== 'string') {
    return '';
  }
  if (isKeyIn(backendText, BackendTextToKeyRecord)) {
    const intlKey = BackendTextToKeyRecord[backendText];
    return intl.formatMessage(backendTextTranslations[intlKey]);
  }
  const candiateKey = getRegexBasedId(backendText);
  if (candiateKey) {
    return intl.formatMessage(backendTextTranslations[candiateKey]);
  }
  return backendText;
};

export const getRegexBasedId = (text: string) => {
  for (let i = 0; i < BackendTextPatterns.length; i++) {
    const pattern = BackendTextPatterns[i];
    if (pattern.pattern.test(text)) {
      return pattern.id;
    }
  }
  return undefined;
};

export function useGetNewEntityWithTranslatedText<T extends { Text?: string; OrigText?: string }>() {
  const translateBackendText = useTranslateBackendText();
  return useCallback(
    (entity: T) => {
      if (entity) {
        entity.OrigText = entity.Text;
        entity.Text = translateBackendText(entity.Text);
      }
      return entity;
    },
    [translateBackendText]
  );
}

export function useGetNewEntityWithTranslatedTextPipe<
  T extends { Text?: string; OrigText?: string }
>(): CompositePipeFunction<T, T> {
  const getNewEntityWithTranslatedText = useGetNewEntityWithTranslatedText<T>();
  return useMemo(
    () =>
      pipe(
        map(res => ({
          ...res,
          data: res.data.map(getNewEntityWithTranslatedText),
        }))
      ),
    [getNewEntityWithTranslatedText]
  );
}
