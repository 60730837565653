import { useCallback, useMemo, useRef, useState } from 'react';

import { maxBy, sortBy } from 'lodash-es';
import { defineMessages, useIntl } from 'react-intl';
import { useFavoriteSecurities, useMixpanel, useRecentSymbols, useSecuritiesContext } from '../../contexts';
import { useDynamicCallback } from '../../hooks';
import { MixpanelEvent, MixpanelEventProperty } from '../../tokens';
import type { Security } from '../../types';
import { getGroup, getProductGroupLabel } from '../../utils';
import { Button, ButtonVariants, IconButton } from '../Button';
import { Flex, VStack } from '../Core';
import { CurrencyPair } from '../CurrencyPair';
import { Divider } from '../Divider';
import {
  AutocompleteDropdown,
  BaseSelect,
  FormControlSizes,
  FuseAutocompleteResult,
  Input,
  useAutocompleteGroupSorter,
  useDropdownPopper,
  useSearchSelect,
  type AutocompleteGroup,
  type FuseSearchResult,
  type RenderResultFunc,
} from '../Form';
import { getGroupsMetadata } from '../Form/Autocomplete/utils';
import { NoResults, Title } from '../Form/AutocompleteDropdown/styles';
import { ALL_TAB } from '../Form/AutocompleteDropdown/types';
import { Icon, IconName } from '../Icons';
import { FormattedMessage } from '../Intl';
import { SecurityRenderer } from '../SecurityRenderer';
import { Text } from '../Text';
import { FavoritesButton, GroupsPrefixWrapper, StandardSecurityButton } from './styles';
import { SymbolSelectorAppearance, type SymbolSelectorProps } from './types';

const messages = defineMessages({
  filterSymbolList: {
    defaultMessage: 'Filter Symbol List',
    id: 'SymbolSelector.filterSymbolList',
  },
  favorites: {
    defaultMessage: 'Favorites',
    id: 'SymbolSelector.favorites',
  },
  noResultsFound: {
    defaultMessage: 'No Results Found',
    id: 'SymbolSelector.noResultsFound',
  },
  selectSymbol: {
    defaultMessage: 'Select symbol',
    id: 'SymbolSelector.selectSymbol',
  },
});

const THRESHOLD = 0.35;
const LIST_HEIGHT = 400;

const getLabel = (sec: Security) => {
  return sec.DisplaySymbol ?? sec.Symbol;
};
const getDescription = (sec: Security) => {
  return sec.Description;
};

const groupOrder = ['All', 'Spot', 'Perp', 'Future', 'Option', 'Multi', 'ETF', 'Swap', 'Calendar Spread'];

const WEIGHT = 1000;
const RANK_WEIGHT = 1 / 1_000_000_000;
const SEARCH_KEYS = [
  { name: 'label', weight: WEIGHT },
  { name: 'item.searchSymbol', weight: WEIGHT },
  // Add a low score to description matches
  { name: 'description', weight: 1 / WEIGHT },
];

const getGroupLabel = (security: Security) => getProductGroupLabel(getGroup(security));

/** WARNING: Be careful not to confuse this with **SearchSelect** this uses useSearchSelect, but is a top-level variant */
export const SymbolSelector = ({
  className,
  startOpen = false,
  symbol,
  onIsOpenChange,
  onSymbolChanged,
  availableSecurities,
  disabled,
  dropdownPlacement = 'bottom-start',
  dropdownWidth,
  dropdownId,
  maxHeight = LIST_HEIGHT,
  size = FormControlSizes.Default,
  appearance: variant,
  portalize,
  showClear,
  showGroups = false,
  RenderGroupHeader,
  centerBasicSelect = false,
  showFavorites = false,
  showSelectedSymbolDescription: showDescription = true,
  suffix,
  placeholder,
  readOnly,
  invalid,
  touched,
  ...props
}: SymbolSelectorProps) => {
  const mixpanel = useMixpanel();
  const { recentSymbolsList, updateRecentSymbols } = useRecentSymbols();
  const { searchableSecurities } = useSecuritiesContext();
  const { favoriteSecurities, setFavoriteSecurities } = useFavoriteSecurities();
  const favoriteSecuritiesSet = useMemo(() => new Set(favoriteSecurities), [favoriteSecurities]);
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
  const { formatMessage } = useIntl();

  const searchableItems = useMemo(() => {
    const securitiesList = availableSecurities ? availableSecurities : searchableSecurities;

    return sortBy(securitiesList, s => {
      const reversedList = recentSymbolsList.slice(0, 5).reverse();
      // Symbols used more often will be adjusted towards the top
      // based on their position index within recent symbols
      const recentSymbolIndex = reversedList.findIndex(item => item === s.DisplaySymbol);
      // put favorites at the top
      if (showFavorites && favoriteSecuritiesSet.has(s.Symbol)) {
        return -1;
      }
      if (recentSymbolIndex > -1) {
        return recentSymbolIndex;
      } else if (s.Rank) {
        // Fallback to secmaster Rank (* 100 to put it below recency index)
        return s.Rank * 100;
      }
    });
  }, [recentSymbolsList, showFavorites, favoriteSecuritiesSet, availableSecurities, searchableSecurities]);

  const security = useMemo(() => {
    return searchableItems.find(s => s.Symbol === symbol);
  }, [searchableItems, symbol]);

  const handleChange = useCallback(
    (security?: Security) => {
      if (security) {
        updateRecentSymbols(security.Symbol);
        onSymbolChanged(security.Symbol);
      }
    },
    [updateRecentSymbols, onSymbolChanged]
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const maxRank = useMemo(() => {
    return maxBy(searchableItems, security => security.Rank)?.Rank ?? 1;
  }, [searchableItems]);

  // This is used by the SearchSelect to apply some sorting modification on the search result
  const searchResultsSorter = useCallback(
    (a: FuseSearchResult<Security>, b: FuseSearchResult<Security>, searchString: string) => {
      if (searchString.toLowerCase() === a.item.label.toLowerCase()) {
        return -1;
      }

      if (searchString.toLowerCase() === b.item.label.toLowerCase()) {
        return 1;
      }

      const reversedList = recentSymbolsList.slice(0, 5).reverse();
      // Symbols used more often will be adjusted towards the top
      // based on their position index within recent symbols
      const aIndex = reversedList.findIndex(item => item === a.item.label);
      const bIndex = reversedList.findIndex(item => item === b.item.label);
      if (aIndex > -1 || bIndex > -1) {
        return bIndex - aIndex;
      } else if (a.score != null && b.score != null && a.item.item.Rank && b.item.item.Rank) {
        // Use combination of rank and score when rank is available
        return a.score + (a.item.item.Rank / maxRank) * RANK_WEIGHT <
          b.score + (b.item.item.Rank / maxRank) * RANK_WEIGHT
          ? -1
          : a.score + (a.item.item.Rank / maxRank) * RANK_WEIGHT > b.score + (b.item.item.Rank / maxRank) * RANK_WEIGHT
          ? 1
          : 0;
      } else if (a.score != null && b.score != null && (a.score || b.score)) {
        return a.score < b.score ? -1 : a.score > b.score ? 1 : 0;
      } else if (
        a.item.item.DisplaySymbol != null &&
        b.item.item.DisplaySymbol != null &&
        (a.item.item.DisplaySymbol || b.item.item.DisplaySymbol)
      ) {
        return (a.item.item.DisplaySymbol ?? a.item.item.Symbol).localeCompare(
          b.item.item.DisplaySymbol ?? b.item.item.Symbol
        );
      } else {
        return a.refIndex < b.refIndex ? -1 : a.refIndex > b.refIndex ? 1 : 0;
      }
    },
    [recentSymbolsList, maxRank]
  );

  const items = useMemo((): Security[] | AutocompleteGroup<Security>[] => {
    if (!showGroups) {
      return searchableItems;
    }
    const groupsMetadata = getGroupsMetadata(searchableItems, getGroupLabel);
    const groups = [...groupsMetadata.values()];
    if (!showOnlyFavorites) {
      return groups;
    }
    // We want to keep all groups even if they're empty because there are no favorite items there
    return groups.map(group => {
      return {
        ...group,
        items: group.items.filter(s => favoriteSecuritiesSet.has(s.Symbol)),
      };
    });
  }, [searchableItems, favoriteSecuritiesSet, showGroups, showOnlyFavorites]);

  const groupSorter = useAutocompleteGroupSorter(groupOrder);

  const searchSelect = useSearchSelect<Security>({
    selection: security,
    items,
    getGroup: showGroups ? getGroupLabel : undefined,
    inputRef,
    initialSortByLabel: false,
    customFuseSearchResultsSorter: searchResultsSorter,
    getLabel,
    getDescription,
    searchKeys: SEARCH_KEYS,
    minMatchCharLength: 2,
    matchThreshold: THRESHOLD,
    disableFuzzyMatching: true,
    fuseDistance: 100,
    onChange: handleChange,
    inputValueChangeOnItemSelection: 'clear',
    /**
     * This prop should be used very intentionally by its consumer. For now,
     * there is only 1 consumer in the whole codebase that uses it from the
     * SymbolSelector layer itself, and that's only so that it can be notified
     * on the chance that the selector closed without any value being picked,
     * so that it can reset a piece of its own internal state.
     */ onIsOpenChange,
    scrollIntoView: target => {
      if (target) {
        (target as any)['scrollIntoViewIfNeeded']
          ? (target as any)['scrollIntoViewIfNeeded']()
          : target.scrollIntoView(false);
      }
    },
    groupSorter,
    initialIsOpen: startOpen,
  });

  const { getInputProps, openMenu, isOpen } = searchSelect;

  const dropdownReferenceElement = useRef<HTMLDivElement | null>(null);

  const dropdownPopper = useDropdownPopper({
    isOpen,
    dropdownWidth,
    dropdownPlacement: dropdownPlacement,
    referenceElement: dropdownReferenceElement.current ?? null,
  });

  const renderGhostVariant = useCallback(() => {
    return (
      <StandardSecurityButton ghost onClick={openMenu} disabled={disabled} type="button" endIcon={IconName.ChevronDown}>
        {symbol ? (
          <SecurityRenderer
            symbol={symbol}
            security={security}
            currency={security?.BaseCurrency}
            showDescription={showDescription}
            iconSize={showDescription ? 24 : 16}
          />
        ) : (
          <Text>
            <FormattedMessage {...messages.selectSymbol} />
          </Text>
        )}
      </StandardSecurityButton>
    );
  }, [disabled, openMenu, security, symbol, showDescription]);

  const renderCurrencyPairVariant = useCallback(() => {
    return (
      <Button
        ghost
        onClick={openMenu}
        onFocus={openMenu}
        disabled={disabled}
        size={FormControlSizes.Small}
        endIcon={IconName.ChevronDown}
      >
        <CurrencyPair security={security} />
      </Button>
    );
  }, [openMenu, disabled, security]);

  const renderBasicVariant = useCallback(() => {
    return (
      <BaseSelect
        isDropdownOpened={isOpen}
        onClick={openMenu}
        onFocus={openMenu}
        getLabel={getLabel}
        value={security}
        size={size}
        placeholder={placeholder ?? formatMessage(messages.selectSymbol)}
        clearable={showClear}
        onClearClick={() => onSymbolChanged(undefined)}
        disabled={disabled}
        centered={centerBasicSelect}
        readOnly={readOnly}
        invalid={invalid}
        touched={touched}
      />
    );
  }, [
    isOpen,
    openMenu,
    security,
    size,
    placeholder,
    formatMessage,
    showClear,
    disabled,
    centerBasicSelect,
    onSymbolChanged,
    readOnly,
    invalid,
    touched,
  ]);

  const renderSearchVariant = useCallback(() => {
    return (
      <IconButton
        size={size}
        icon={IconName.Search}
        variant={isOpen ? ButtonVariants.Priority : ButtonVariants.Default}
        onClick={openMenu}
        onFocus={openMenu}
        disabled={disabled}
        data-testid="search-all-symbol-selector"
      />
    );
  }, [openMenu, size, isOpen, disabled]);

  const toggleFavorite = useDynamicCallback((security: Security, isFavorite: boolean) => {
    const newFavorites = isFavorite
      ? favoriteSecurities.filter(symbol => symbol !== security.Symbol)
      : favoriteSecurities.concat([security.Symbol]);
    setFavoriteSecurities(newFavorites);
    mixpanel.track(MixpanelEvent.FavoriteSymbol);
  });

  const renderFavoriteIcon = useCallback(
    (security: Security) => {
      if (!showFavorites) {
        return null;
      }
      const isFavorite = favoriteSecuritiesSet.has(security.Symbol);
      return (
        <Icon
          icon={isFavorite ? IconName.StarSolid : IconName.Star}
          color={isFavorite ? 'yellow.lighten' : 'gray.080'}
          size="fontSizeLarge"
          onClick={e => {
            toggleFavorite(security, isFavorite);
            e.stopPropagation();
          }}
          p="spacingComfortable"
          spaceBefore="-spacingComfortable"
        />
      );
    },
    [favoriteSecuritiesSet, toggleFavorite, showFavorites]
  );

  const renderResult: RenderResultFunc<Security> = useDynamicCallback((searchResults, disabled) =>
    FuseAutocompleteResult(searchResults, disabled, renderFavoriteIcon)
  );

  const handleFavoritesClick = useCallback(
    (value: boolean) => {
      mixpanel.track(MixpanelEvent.FavoriteFilter, {
        [MixpanelEventProperty.Value]: value,
      });
      setShowOnlyFavorites(value);
    },
    [mixpanel, setShowOnlyFavorites]
  );

  const groupsPrefix = useMemo(() => {
    if (!showFavorites || !showGroups) {
      return null;
    }
    return (
      <GroupsPrefixWrapper>
        <IconButton
          ghost
          onClick={() => handleFavoritesClick(!showOnlyFavorites)}
          icon={showOnlyFavorites ? IconName.StarSolid : IconName.Star}
          color={showOnlyFavorites ? 'yellow.lighten' : 'gray.080'}
          className="toggle-favorites"
        />
        <Divider orientation="vertical" mr="spacingSmall" ml="spacingSmall" />
      </GroupsPrefixWrapper>
    );
  }, [showFavorites, showGroups, showOnlyFavorites, handleFavoritesClick]);

  const renderEmpty = useDynamicCallback(() => {
    if (!showGroups || !showOnlyFavorites) {
      return (
        <NoResults data-testid="dropdown-no-results">
          <Title>
            <FormattedMessage {...messages.noResultsFound} />
          </Title>
        </NoResults>
      );
    } else {
      const selectedGroup = searchSelect.tabs.items[searchSelect.tabs.selectedIndex].group;
      const group = selectedGroup === ALL_TAB.group ? null : selectedGroup;
      return (
        <VStack data-testid="dropdown-no-results" gap="spacingMedium" p="spacingLarge">
          <Title>
            There are no favorites{' '}
            {group && (
              <>
                for <Text color="colorTextAttention">{group}</Text>
              </>
            )}
          </Title>
          <Button startIcon={IconName.Reply} onClick={() => handleFavoritesClick(false)}>
            View All {group} Symbols
          </Button>
        </VStack>
      );
    }
  });

  return (
    <Flex
      className={className}
      data-testid="symbol-selector"
      {...props}
      ref={dropdownReferenceElement}
      position="relative"
    >
      {variant === SymbolSelectorAppearance.Basic
        ? renderBasicVariant()
        : variant === SymbolSelectorAppearance.Search
        ? renderSearchVariant()
        : variant === SymbolSelectorAppearance.CurrencyPair
        ? renderCurrencyPairVariant()
        : renderGhostVariant()}
      <div>
        <AutocompleteDropdown
          {...searchSelect}
          {...dropdownPopper}
          renderResult={renderResult}
          size={FormControlSizes.Default}
          maxHeight={maxHeight}
          portalize={portalize}
          showGroupTabs={showGroups}
          groupSorter={groupSorter}
          RenderGroupHeader={RenderGroupHeader}
          suffix={suffix}
          renderEmpty={renderEmpty}
          groupsPrefix={groupsPrefix}
          id={dropdownId}
          childrenAboveResults={
            <Input
              {...getInputProps(
                { ref: inputRef },
                {
                  // If portalize is used, the ref error is suppressed since
                  // the ref is not available in the same context
                  suppressRefError: portalize,
                }
              )}
              data-testid="symbol-selector-input"
              placeholder={formatMessage(messages.filterSymbolList)}
              disabled={disabled}
              size={FormControlSizes.Default}
              suffix={
                showOnlyFavorites && (
                  <FavoritesButton
                    startIcon={IconName.StarSolid}
                    endIcon={IconName.Close}
                    size={FormControlSizes.Tiny}
                    onClick={() => handleFavoritesClick(false)}
                  >
                    <FormattedMessage {...messages.favorites} />
                  </FavoritesButton>
                )
              }
            />
          }
        />
      </div>
    </Flex>
  );
};
