import { defineMessages } from 'react-intl';

import { useCurrency } from '../../../hooks/useCurrency';
import { OrderFormSides } from '../../../types/OrderFormSides';
import { InlineFormattedNumber } from '../../FormattedNumber/InlineFormattedNumber';
import { FormattedMessage } from '../../Intl';
import { BalanceLabel, MaxAvailableWrapper } from './styles';

const messages = defineMessages({
  maxAvailable: {
    defaultMessage: 'Max Available',
    id: 'Forms.FormBalances.maxAvailable',
  },
});

export interface MaxAvailableProps {
  maxAvailable?: string;
  currency?: string;
  side?: OrderFormSides;
  estimate?: boolean;
}

export const MaxAvailable = ({ maxAvailable, currency, side, estimate }: MaxAvailableProps) => {
  const currencyInfo = useCurrency(currency);

  if (side === OrderFormSides.Twoway || !maxAvailable) {
    return null;
  }

  return (
    <MaxAvailableWrapper data-testid="form-balances-max-available">
      <BalanceLabel>
        <FormattedMessage {...messages.maxAvailable} />
      </BalanceLabel>

      <InlineFormattedNumber
        number={maxAvailable}
        increment={currencyInfo?.DefaultIncrement}
        currency={currency}
        estimate={estimate}
        align="right"
      />
    </MaxAvailableWrapper>
  );
};
