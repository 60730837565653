import {
  Button,
  ButtonGroup,
  ButtonVariants,
  CopyButton,
  DarkTheme,
  DrawerContent,
  DrawerFooter,
  Flex,
  FormControlSizes,
  getTypedKeys,
  Grid,
  HelpIcon,
  HStack,
  Icon,
  IconName,
  Input,
  LightTheme,
  mapDarkTheme,
  mapLightTheme,
  setAlpha,
  Text,
  ThemeTypes,
  ToggleButton,
  useEffectiveThemeType,
  useThrottledValue,
  useWLThemeType,
  VStack,
} from '@talos/kyoko';

import { darken, desaturate } from 'polished';
import { useCallback, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { useCustomThemeContext } from '../../providers';
import { generateTheme, type GenerateThemeType } from './generateTheme';

export function DemoColorGenerator() {
  const { themeType } = useWLThemeType();
  const effectiveTheme = useEffectiveThemeType(themeType!);

  const [themeConfigured, setThemeConfigured] = useState<ThemeTypes.dark | ThemeTypes.light>(
    effectiveTheme.includes('light') ? ThemeTypes.light : ThemeTypes.dark
  );

  const [_colorsOverride, setColorsOverride] = useLocalStorage<ColorsOverrideType>(
    'custom-theme',
    initialColorsOverride
  );
  // We throttle this value because of the color picker input should performant,
  // and the selected color rerendering all of the time causes significant performance issues.
  const customColors = useThrottledValue(_colorsOverride ?? initialColorsOverride, 200);

  const { setCustomTheme, setEnableCustomTheme, customTheme } = useCustomThemeContext();

  const handleOnClickApplyTheme = useCallback(
    (themeType: ThemeTypes.dark | ThemeTypes.light) => {
      setEnableCustomTheme(true);
      const newCustomTheme = generateTheme(customColors[themeType], themeType);
      setCustomTheme(newCustomTheme);
    },
    [customColors, setCustomTheme, setEnableCustomTheme]
  );

  const onHandleChangeTheme = useCallback(
    (themeType: ThemeTypes.dark | ThemeTypes.light) => {
      setThemeConfigured(themeType);
      handleOnClickApplyTheme(themeType);
    },
    [handleOnClickApplyTheme]
  );

  const copyThemeJSON = useCallback(() => {
    if (!customTheme) {
      return;
    }
    const darkCustomTheme = generateTheme(customColors[ThemeTypes.dark], ThemeTypes.dark);
    const lightCustomTheme = generateTheme(customColors[ThemeTypes.light], ThemeTypes.light);

    // only get the diff
    const darkTheme = mapDarkTheme(darkCustomTheme.colors, {});
    const lightTheme = mapLightTheme(lightCustomTheme.colors, {});

    const darkCopiedTheme = {};
    getTypedKeys(darkTheme).forEach(key => {
      if (darkTheme[key] !== darkCustomTheme[key] || typeof darkTheme[key] === 'object') {
        darkCopiedTheme[key] = darkCustomTheme[key];
      }
    });

    const lightCopiedTheme = {};
    getTypedKeys(lightTheme).forEach(key => {
      if (lightTheme[key] !== lightCustomTheme[key] || typeof lightTheme[key] === 'object') {
        lightCopiedTheme[key] = lightCustomTheme[key];
      }
    });

    const copiedTheme = {
      dark: darkCopiedTheme,
      light: lightCopiedTheme,
    };

    const copyText = JSON.stringify(copiedTheme, null, 2);

    navigator.clipboard.writeText(copyText);
  }, [customColors, customTheme]);

  const currentThemeConfigured = customColors[themeConfigured];

  return (
    <Flex flex="1" justifyContent="space-between" flexDirection="column" h="100%">
      <DrawerContent>
        <VStack alignSelf="flex-start" gap="spacingDefault">
          <HStack justifyContent="space-between" w="100%">
            <ButtonGroup h="24px">
              <ToggleButton
                selected={themeConfigured === ThemeTypes.dark}
                selectedVariant={ButtonVariants.Primary}
                onClick={() => {
                  onHandleChangeTheme(ThemeTypes.dark);
                }}
              >
                Dark <Icon icon={IconName.Moon} />
              </ToggleButton>
              <ToggleButton
                selected={themeConfigured === ThemeTypes.light}
                selectedVariant={ButtonVariants.Primary}
                onClick={() => {
                  onHandleChangeTheme(ThemeTypes.light);
                }}
              >
                Light <Icon icon={IconName.Sun} />
              </ToggleButton>
            </ButtonGroup>
            <Button
              flex="0"
              size={FormControlSizes.Small}
              onClick={() => {
                setColorsOverride(initialColorsOverride);
                setCustomTheme(generateTheme(initialColorsOverride[themeConfigured], themeConfigured));
              }}
              variant={ButtonVariants.Default}
            >
              Reset
            </Button>
          </HStack>

          <Grid w="100%" gridTemplateColumns="repeat(2,1fr)" mt="spacingDefault" gap="spacingDefault">
            {currentThemeConfigured &&
              getTypedKeys(currentThemeConfigured).map(colorKey => {
                if (colorKey === 'blue' || colorKey === 'purple') {
                  return null;
                }

                return (
                  <Flex flexDirection="column" key={colorKey} gap="spacingSmall">
                    <Flex fontWeight="bold" gap="spacingSmall">
                      <Text>{nameResolutionMap[colorKey]}</Text>
                      <HelpIcon tooltip={tooltipResolutionMap[colorKey]} />
                    </Flex>
                    <Flex
                      border={`1px solid ${desaturate(0.5, darken(0.2, customColors[themeConfigured][colorKey]))}`}
                      borderRadius="borderRadiusDefault"
                      p="spacingDefault"
                      gap="spacingDefault"
                      background={setAlpha(0.2, customColors[themeConfigured][colorKey])}
                      cursor="pointer"
                      onClick={() => {
                        document.getElementById(`color-picker-${colorKey}`)?.click();
                      }}
                      alignItems="flex-end"
                    >
                      <Text textTransform="uppercase">{customColors[themeConfigured][colorKey]}</Text>

                      <Input
                        id={`color-picker-${colorKey}`}
                        style={{ marginLeft: 'auto' }}
                        value={customColors[themeConfigured][colorKey]}
                        onChange={e => {
                          setColorsOverride(() => {
                            const themeKey = themeConfigured;
                            return {
                              ...customColors,
                              [themeKey]: {
                                ...customColors[themeKey],
                                [colorKey]: e.target.value,
                              },
                            };
                          });
                        }}
                        inputType="color"
                      />
                    </Flex>
                  </Flex>
                );
              })}
          </Grid>
        </VStack>
        <Text mt="auto" py="spacingDefault" px="spacingTiny">
          The tradingview pane colors are not updated with this tool. They will be aligned once the theme is
          implemented.
        </Text>
      </DrawerContent>
      <DrawerFooter mt="auto">
        <ButtonGroup gap="spacingDefault">
          <CopyButton
            flex="1"
            size={FormControlSizes.Default}
            variant={ButtonVariants.Default}
            onClick={copyThemeJSON}
            disabled={!customTheme}
          >
            Copy
          </CopyButton>
          <Button
            flex="4"
            onClick={() => {
              handleOnClickApplyTheme(themeConfigured);
            }}
            variant={ButtonVariants.Primary}
          >
            Apply
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </Flex>
  );
}

type ColorsOverrideType = {
  [ThemeTypes.dark]: GenerateThemeType;
  [ThemeTypes.light]: GenerateThemeType;
};

const nameResolutionMap: Record<keyof GenerateThemeType, string> = {
  primary: 'Brand',
  gray: 'Background',
  green: 'Positive',
  red: 'Negative',
  yellow: 'Warning',
  orange: 'Attention',
  white: 'White',
  black: 'Black',

  // not used currently
  blue: 'Blue',
  // not used currently
  purple: 'Purple',
};

const tooltipResolutionMap: Record<keyof GenerateThemeType, string> = {
  primary: 'Brand color, will be used by most buttons and accents',
  gray: 'Background color, will be used to create background and shades',
  green: 'Positive color, will be used to signal buy or successful actions',
  red: 'Negative color, will be used to signal sell or unsuccessful actions',
  yellow: 'Warning color, will be used for rejected orders and attention',
  orange: 'Attention color, will be used to draw attention to important information',
  white: 'White color, used for text within buttons and dividers',
  black: 'Black color, used for text within buttons and dividers',

  // not used currently
  blue: 'Blue color',
  // not used currently
  purple: 'Purple color',
};

const initialColorsOverride: ColorsOverrideType = {
  [ThemeTypes.dark]: {
    primary: DarkTheme.colors.primary.default,
    gray: DarkTheme.colors.gray.main,
    green: DarkTheme.colors.green.default,
    red: DarkTheme.colors.red.default,
    yellow: DarkTheme.colors.yellow.default,
    orange: DarkTheme.colors.orange.default,
    blue: DarkTheme.colors.blue.default,
    purple: DarkTheme.colors.purple.default,
    white: DarkTheme.colors.white.default,
    black: DarkTheme.colors.black.default,
  },
  [ThemeTypes.light]: {
    primary: LightTheme.colors.primary.default,
    gray: LightTheme.colors.gray.main,
    green: LightTheme.colors.green.default,
    red: LightTheme.colors.red.default,
    yellow: LightTheme.colors.yellow.default,
    orange: LightTheme.colors.orange.default,
    blue: LightTheme.colors.blue.default,
    purple: LightTheme.colors.purple.default,
    white: LightTheme.colors.white.default,
    black: LightTheme.colors.black.default,
  },
};
