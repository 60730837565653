import { useCallback } from 'react';
import { useWLUser } from '../providers/WLUserContextProvider';
import type { CustomerUser } from '../types';

// Tabs
export enum WL_BLOTTER_TAB {
  ActiveOrders = 'ActiveOrders',
  Balances = 'Balances',
  Credits = 'Credits',
  OrderHistory = 'OrderHistory',
  Trades = 'Trades',
  Transfers = 'Transfers',
  FundingEvents = 'FundingEvents',
  Positions = 'Positions',
}

// Permissions
export const WL_READ_MARKETDATA = 'read::marketdata';
export const WL_READ_ORDERS = 'read::orders';
export const WL_WRITE_ENTITY = 'write::entity';
export const WL_WRITE_ORDERS = 'write::orders';
export const WL_WRITE_SUBMIT_ORDERS = 'write::submit::orders';
export const WL_WRITE_MODIFY_ORDERS = 'write::modify::orders';
export const WL_WRITE_CANCEL_ORDERS = 'write::cancel::orders';
export const WL_WRITE_PAUSE_ORDERS = 'write::pause::orders';
export const WL_READ_POSITIONS = 'read::positions';
export const WL_READ_CFD = 'read::cfd';
export const WL_READ_SECMASTER = 'read::secmaster';
export const WL_ADMIN_LIMITS = 'admin::limits';
export const WL_ADMIN_ORDERS = 'admin::orders';
export const WL_WRITE_QUOTES = 'write::quotes';
export const WL_ADMIN_POSITIONS = 'admin::positions';
export const WL_READ_PRICING = 'read::pricing';
export const WL_READ_QUOTES = 'read::quotes';
export const WL_READ_ENTITY = 'read::entity';
export const WL_ADMIN_CONFIGURATIONS = 'admin::ui::configuration';

// actions
export const WL_USE_SYMBOL_SELECTOR = 'symbolselector.use';
export const WL_VIEW_BLOTTERS = 'blotters.view';
export const WL_VIEW_BLOTTERS_ACTIVE_ORDERS = 'blotters.activeorders';
export const WL_VIEW_BLOTTERS_ORDER_HISTORY = 'blotters.orderhistory';
export const WL_VIEW_BLOTTERS_TRADES = 'blotters.trades';
export const WL_VIEW_BLOTTERS_BALANCES = 'blotters.balances';
export const WL_VIEW_BLOTTERS_CREDITS = 'blotters.credits';
export const WL_VIEW_BLOTTERS_TRANSFERS = 'blotters.transfers';
export const WL_VIEW_BLOTTERS_FUNDING_EVENTS = 'blotters.fundingevents';
export const WL_VIEW_BLOTTERS_WITHDRAW = 'blotters.withdraw';
export const WL_VIEW_BLOTTERS_POSITIONS = 'blotters.positions';
export const WL_VIEW_SETTINGS = 'settings.view';
export const WL_VIEW_FEE_TIERS = 'feetiers.view';
export const WL_SHOW_CANDLESTICKS_AND_MARKETDATA = 'marketdata.view';
export const WL_MARKET_DATA = 'marketdata.read';
export const WL_INITIATE_ORDER = 'order.init';
export const WL_MODIFY_ORDER = 'order.modify';
export const WL_CANCEL_ORDER = 'order.cancel';
export const WL_PAUSE_ORDER = 'order.pause';
export const WL_INITIATE_RFQ = 'rfq.init';
export const WL_VIEW_FUNDING_SCHEDULE = 'funding.schedule.view';
export const WL_SEE_CONFIG_TOOLS = 'config.tools.view';

type WLRuleMapping = { [key: string]: string[] };

export const WL_RULES: WLRuleMapping = {
  [WL_USE_SYMBOL_SELECTOR]: [WL_READ_SECMASTER],
  [WL_VIEW_BLOTTERS]: [WL_READ_ORDERS, WL_READ_POSITIONS],
  [WL_VIEW_BLOTTERS_ACTIVE_ORDERS]: [WL_READ_ORDERS],
  [WL_VIEW_BLOTTERS_ORDER_HISTORY]: [WL_READ_ORDERS],
  [WL_VIEW_BLOTTERS_TRADES]: [WL_READ_ORDERS],
  [WL_VIEW_BLOTTERS_BALANCES]: [WL_READ_POSITIONS],
  [WL_VIEW_BLOTTERS_CREDITS]: [WL_READ_POSITIONS],
  [WL_VIEW_BLOTTERS_TRANSFERS]: [WL_READ_POSITIONS],
  [WL_VIEW_BLOTTERS_FUNDING_EVENTS]: [WL_READ_CFD],
  [WL_VIEW_FUNDING_SCHEDULE]: [WL_READ_CFD],
  [WL_VIEW_BLOTTERS_WITHDRAW]: [WL_ADMIN_POSITIONS],
  [WL_VIEW_BLOTTERS_POSITIONS]: [WL_READ_CFD],
  [WL_VIEW_SETTINGS]: [WL_READ_ENTITY],
  [WL_VIEW_FEE_TIERS]: [WL_READ_ENTITY],
  [WL_SHOW_CANDLESTICKS_AND_MARKETDATA]: [WL_READ_MARKETDATA],
  [WL_INITIATE_ORDER]: [WL_WRITE_ORDERS, WL_WRITE_SUBMIT_ORDERS],
  [WL_MODIFY_ORDER]: [WL_WRITE_ORDERS, WL_WRITE_MODIFY_ORDERS],
  [WL_CANCEL_ORDER]: [WL_WRITE_ORDERS, WL_WRITE_CANCEL_ORDERS],
  [WL_PAUSE_ORDER]: [WL_WRITE_ORDERS, WL_WRITE_PAUSE_ORDERS],
  [WL_INITIATE_RFQ]: [WL_WRITE_QUOTES],
  [WL_SEE_CONFIG_TOOLS]: [WL_ADMIN_CONFIGURATIONS],
};

export const useWLRoleAuth = () => {
  const user = useWLUser();
  const isAuthorized = useCallback((action: string) => isWLUserAuthorized(user, action, WL_RULES), [user]);
  const isRole = useCallback((role: string) => isWLUserRole(user, role), [user]);

  return { isAuthorized, isRole };
};

export const isWLUserRole = (user: CustomerUser, role: string) =>
  user && user.Permissions && user.Permissions.includes(role);

export const isWLUserAuthorized = (user: CustomerUser, action: string, rules: WLRuleMapping) => {
  if (user == null || user.Permissions == null) {
    return false;
  }
  return rules[action]?.some(permission => user.Permissions?.includes(permission));
};

export const useWLUserPermissions = (): string[] => {
  const user = useWLUser();
  return user.Permissions ?? [];
};
