import { IconButton, IconName } from '@talos/kyoko';
import type { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { CreditUtilization } from '../../../CreditUtilization';
import { Logo } from '../../../Logo';
import { HeaderContent, MobileHeaderWrapper, Section } from './styles';

export function MobileHeader({ onMenuClick }: { onMenuClick: Dispatch<SetStateAction<boolean>> }) {
  const { type } = useTheme();
  const navigate = useNavigate();

  return (
    <MobileHeaderWrapper>
      <HeaderContent>
        <Section mr="auto" zIndex={1}>
          <IconButton
            data-testid="mobile-header-menu-button"
            onClick={() => onMenuClick(prev => !prev)}
            icon={IconName.Menu}
            ghost
          />
        </Section>
        <Section position="absolute" left="30%" right="30%" onClick={() => navigate('/')}>
          <Logo themeType={type} width="100%" />
        </Section>
        <Section ml="auto" zIndex={1}>
          <CreditUtilization />
        </Section>
      </HeaderContent>
    </MobileHeaderWrapper>
  );
}
