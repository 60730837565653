import { useCallback, useEffect, useState, type RefObject } from 'react';

export enum ResizerPlacement {
  LEFT,
  TOP,
  RIGHT,
  BOTTOM,
}

function canResize(change: number, currVal: number, maxVal: number | null): boolean {
  if (change < 0 || maxVal == null) {
    return true;
  } else {
    return currVal + change < maxVal;
  }
}

export function movementAndPlacementToSizeChange(movement: number, placement: ResizerPlacement): number {
  switch (placement) {
    case ResizerPlacement.LEFT:
    case ResizerPlacement.TOP:
      return -movement;
    case ResizerPlacement.RIGHT:
    case ResizerPlacement.BOTTOM:
      return movement;

    default:
      return 0;
  }
}

export type ResizerInputs = {
  resizeableRef: RefObject<HTMLElement>;
  resizeX?: boolean;
  resizeY?: boolean;
  maxWidthPx?: number | null;
  maxHeightPx?: number | null;
  placement: ResizerPlacement;
};

export const useResizer = ({
  resizeableRef,
  resizeX = false,
  resizeY = false,
  maxWidthPx = null,
  maxHeightPx = null,
  placement,
}: ResizerInputs) => {
  const [mouseDown, setMouseDown] = useState(false);

  const onResize = useCallback(
    (movementX: number, movementY: number) => {
      if (resizeableRef.current) {
        const element = resizeableRef.current;
        const width = element.offsetWidth;
        const height = element.offsetHeight;
        if (resizeX) {
          const widthChange = movementAndPlacementToSizeChange(movementX, placement);
          if (canResize(widthChange, width, maxWidthPx)) {
            element.style.width = `${width + widthChange}px`;
          }
        }
        if (resizeY) {
          const heightChange = movementAndPlacementToSizeChange(movementY, placement);
          if (canResize(heightChange, height, maxHeightPx)) {
            element.style.height = `${height + heightChange}px`;
          }
        }
      }
    },
    [resizeableRef, maxWidthPx, resizeX, resizeY, maxHeightPx, placement]
  );

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (e.buttons === 0) {
        setMouseDown(false);
      } else {
        onResize(e.movementX, e.movementY);
      }
    };

    if (mouseDown) {
      window.addEventListener('mousemove', handleMouseMove);
    }

    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [mouseDown, onResize]);

  useEffect(() => {
    const handleMouseUp = () => setMouseDown(false);
    window.addEventListener('mouseup', handleMouseUp);
    return () => window.removeEventListener('mouseup', handleMouseUp);
  }, []);

  const onMouseDown: React.MouseEventHandler<HTMLDivElement> = e => {
    setMouseDown(true);
    e.preventDefault(); // stop from highlighting text and stuff when resizing
  };

  return {
    onMouseDown,
  };
};
