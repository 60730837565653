import Big from 'big.js';
import { last, max, min } from 'lodash-es';
import { transparentize } from 'polished';
import { useState, type CSSProperties } from 'react';
// @ts-expect-error - react-sparklines does not have types
import { Sparklines, SparklinesCurve, SparklinesReferenceLine, SparklinesText } from 'react-sparklines';
import { map, type Observable } from 'rxjs';
import { useTheme } from 'styled-components';

import { useElementId, useElementSize, useObservableValue } from '../../hooks';
import { format } from '../../utils/number';

import type { OHLCData, Security } from '../../types';
import { SparklineWrapper } from './styles';

if (import.meta.env.VITE_AVA_ENV === 'local') {
  // eslint-disable-next-line react/forbid-foreign-prop-types
  delete SparklinesText.propTypes.text;
}

export const Sparkline = ({
  dataObservable,
  limit,
  security,
  description,
  fill: customFill,
  color: customColor,
  showReferenceLines = true,
  style,
}: SparklineProps) => {
  const [opacity, setOpacity] = useState(0);

  const data = useObservableValue<OHLCData[]>(
    () =>
      dataObservable?.pipe(
        map((prices: OHLCData[]) => {
          const values: OHLCData[] = [];
          prices?.forEach((d, index) => {
            const prev = values[index - 1];
            values.push(
              d.Close === '0' && d.Open === '0' && d.High === '0' && d.Low === '0'
                ? {
                    Open: prev?.Close ?? '0',
                    High: prev?.Close ?? '0',
                    Low: prev?.Close ?? '0',
                    Close: prev?.Close ?? '0',
                    Timestamp: d.Timestamp,
                    Volume: d.Volume,
                  }
                : d
            );
          });
          return values.slice(-limit);
        })
      ),
    [dataObservable, limit],
    []
  );
  const opens = data.map(d => d.Open);
  const highs = data.map(d => d.High);
  const lows = data.map(d => d.Low);
  const closes = data.map(d => d.Close);

  const {
    elementRef,
    size: { offsetWidth, offsetHeight },
  } = useElementSize<HTMLDivElement>();
  const {
    colors,
    borderColorChartCrosshair,
    baseSize,
    fontFamily,
    fontSizeTiny,
    colorTextDefault,
    colorTextSubtle,
    colorTextPositive,
    colorTextNegative,
    borderColorChartUpColor,
    borderColorChartDownColor,
  } = useTheme();
  const { DefaultPriceIncrement } = security ?? {};

  const color =
    customColor ?? Big(last(closes) ?? 0).lt(closes[0] ?? 0) ? borderColorChartDownColor : borderColorChartUpColor;
  const gradientID = useElementId('sparkline');
  const id = gradientID('gradient');
  const fill = customFill ?? `url(#${id})`;

  const startValue = Big(opens[0] ?? 0);
  const maxValue = Big(max(highs.map(v => parseFloat(v ?? '0'))) ?? 0);
  const minValue = Big(min(lows.map(v => parseFloat(v ?? '0'))) ?? 0);
  const range = maxValue.minus(minValue);
  const adjustedMin = minValue.minus(range.times(0.1));
  const adjustedMax = maxValue.add(range.times(0.2));
  const fullRange = adjustedMax.minus(adjustedMin);
  const startPoint = !fullRange.eq(0)
    ? Big(offsetHeight ?? 0)
        .minus(
          Big(closes[0] ?? '0')
            .minus(adjustedMin)
            .div(fullRange)
            .times(offsetHeight ?? 0)
        )
        .toNumber()
    : 0;
  const minPoint = !fullRange.eq(0)
    ? Big(offsetHeight ?? 0)
        .minus(
          minValue
            .minus(adjustedMin)
            .div(fullRange)
            .times(offsetHeight ?? 0)
        )
        .toNumber()
    : 0;
  const maxPoint = !fullRange.eq(0)
    ? Big(offsetHeight ?? 0)
        .minus(
          maxValue
            .minus(adjustedMin)
            .div(fullRange)
            .times(offsetHeight ?? 0)
        )
        .toNumber()
    : 0;

  const transparentColor = transparentize(1, color);

  return (
    <SparklineWrapper
      style={style}
      onMouseEnter={() => setOpacity(1)}
      onMouseLeave={() => setOpacity(0)}
      ref={elementRef}
    >
      <Sparklines
        width={offsetWidth}
        height={offsetHeight}
        data={closes}
        limit={limit}
        max={adjustedMax.toNumber()}
        min={adjustedMin.toNumber()}
        margin={0}
      >
        <SparklinesCurve color={color} style={{ fill, fillOpacity: 0.25 }} />
        <defs>
          <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={color} />
            <stop offset="100%" stopColor={transparentColor} />
          </linearGradient>
        </defs>
        {showReferenceLines && description ? (
          <SparklinesText
            point={{ x: 16, y: 12 }}
            text={description}
            style={{ fill: colorTextSubtle }}
            fontSize={baseSize * fontSizeTiny}
            fontFamily={fontFamily}
          />
        ) : (
          <></>
        )}
        {showReferenceLines ? (
          <SparklinesReferenceLine
            type="custom"
            value={minPoint}
            style={{ stroke: colors.red.lighten, strokeOpacity: opacity * 0.25, strokeDasharray: '6 3' }}
          />
        ) : (
          <></>
        )}
        {showReferenceLines ? (
          <SparklinesReferenceLine
            type="custom"
            value={maxPoint}
            style={{ stroke: colors.green.lighten, strokeOpacity: opacity * 0.25, strokeDasharray: '6 3' }}
          />
        ) : (
          <></>
        )}
        {showReferenceLines ? (
          <SparklinesReferenceLine
            type="custom"
            value={startPoint}
            style={{ stroke: borderColorChartCrosshair, strokeOpacity: opacity * 1, strokeDasharray: '3 3' }}
          />
        ) : (
          <></>
        )}
        {showReferenceLines ? (
          <SparklinesText
            point={{ x: 16, y: maxPoint }}
            text={
              <>
                H:{' '}
                <tspan fill={colorTextPositive}>
                  {format(maxValue?.toFixed(), { spec: DefaultPriceIncrement, round: true })}
                </tspan>
              </>
            }
            fontSize={baseSize * fontSizeTiny}
            fontFamily={fontFamily}
          />
        ) : (
          <></>
        )}
        {showReferenceLines ? (
          <SparklinesText
            point={{ x: 16, y: startPoint }}
            text={
              <>
                O:{' '}
                <tspan fill={colorTextDefault}>
                  {format(startValue?.toFixed(), { spec: DefaultPriceIncrement, round: true })}
                </tspan>
              </>
            }
            fontSize={baseSize * fontSizeTiny}
            fontFamily={fontFamily}
          />
        ) : (
          <></>
        )}
        {showReferenceLines ? (
          <SparklinesText
            point={{ x: 16, y: minPoint }}
            text={
              <>
                L:{' '}
                <tspan fill={colorTextNegative}>
                  {format(minValue?.toFixed(), { spec: DefaultPriceIncrement, round: true })}
                </tspan>
              </>
            }
            fontSize={baseSize * fontSizeTiny}
            fontFamily={fontFamily}
          />
        ) : (
          <></>
        )}
      </Sparklines>
    </SparklineWrapper>
  );
};

type SparklineProps = {
  limit: number;
  description?: string;
  dataObservable: Observable<OHLCData[]>;
  security?: Security;
  showReferenceLines?: boolean;
  fill?: string;
  color?: string;
  style?: CSSProperties;
};
