import {
  CustomerUserConfigLayoutNavigationTypes,
  CustomerUserConfigLayoutType,
  ResizerPlacement,
  WL_BLOTTER_TAB,
  WL_INITIATE_ORDER,
  WL_INITIATE_RFQ,
  WL_SHOW_CANDLESTICKS_AND_MARKETDATA,
  WL_VIEW_BLOTTERS,
  WL_VIEW_BLOTTERS_BALANCES,
  WL_VIEW_BLOTTERS_TRADES,
  WL_VIEW_FEE_TIERS,
  WL_VIEW_SETTINGS,
} from '@talos/kyoko';
import { Addresses } from '../components/Addresses';
import { Blotters } from '../components/Blotters/Blotters';
import { Candlesticks } from '../components/Candlesticks';
import { FeeTiers } from '../components/FeeTiers';
import { Settings } from '../components/Settings';
import { VolumeLadder } from '../components/VolumeLadder';
import { BLOTTERS_MIN_HEIGHT, MAIN_HEADER_HEIGHT, VOLUME_LADDER_HEIGHT } from '../styles/dimensions';
import { SimpleRFQLayout, ViewOnlySimpleRFQLayout } from './SimpleRFQLayout';
import { TradingLadderLayout } from './TradingLadderLayout';
import { TradingLayout, ViewOnlyLayout } from './TradingLayoutSpec';
import { WatchListTradingLayout } from './WatchListTradingLayout';
import { blottersOnlyMobileScreens, viewOnlyMobileScreens } from './mobileScreens';
import type { LayoutComponent, LayoutSpec } from './types';

// Re-export these under their original names for convenience, until all consumers can be redirected to @talos/kyoko
export { CustomerUserConfigLayoutType as LayoutType, CustomerUserConfigLayoutNavigationTypes as NavigationTypes };

export const ViewOnlyLadderLayout: LayoutComponent = {
  headerSymbolSelector: false,
  columns: ['minmax(18rem,25rem)', 'minmax(16rem,1fr)', 'minmax(16rem,1fr)'],
  rows: ['minmax(100px,1fr)', 'minmax(100px,1fr)', 'minmax(12rem,1fr)'],
  parts: [
    {
      column: '1',
      row: '1/3',
      component: <VolumeLadder />,
    },
    {
      column: '2/4',
      row: '1/3',
      component: <Candlesticks />,
    },
    {
      column: '1/4',
      row: '3',
      component: <Blotters />,
      minHeight: BLOTTERS_MIN_HEIGHT,
      maxHeight: window.innerHeight - MAIN_HEADER_HEIGHT - VOLUME_LADDER_HEIGHT - 20,
      resizable: ResizerPlacement.TOP,
    },
  ],
  mobileScreens: viewOnlyMobileScreens,
};

export const BlottersOnlyLayout: LayoutComponent = {
  headerSymbolSelector: false,
  columns: ['1fr'],
  rows: ['1fr'],
  parts: [
    {
      column: '1',
      row: '1',
      component: <Blotters />,
    },
  ],
  mobileScreens: blottersOnlyMobileScreens,
};

const BalancesBlotterTab = [WL_BLOTTER_TAB.Balances];

export const SettingsLayout: LayoutComponent = {
  headerSymbolSelector: false,
  columns: ['1fr'],
  rows: ['1fr'],
  parts: [{ column: '1', row: '1', component: <Settings /> }],
};

export const AddressesLayout: LayoutComponent = {
  headerSymbolSelector: false,
  columns: ['1fr'],
  rows: ['1fr'],
  parts: [{ column: '1', row: '1', component: <Addresses /> }],
};

export const FeeTiersLayout: LayoutComponent = {
  headerSymbolSelector: false,
  columns: ['1fr'],
  rows: ['1fr'],
  parts: [{ column: '1', row: '1', component: <FeeTiers /> }],
};

export const BalancesOnlyLayout: LayoutComponent = {
  headerSymbolSelector: false,
  columns: ['1fr'],
  rows: ['1fr'],
  parts: [
    {
      column: '1',
      row: '1',
      component: (
        <Blotters key={`${CustomerUserConfigLayoutType.PortfolioLayout}_0`} blotterTabs={BalancesBlotterTab} />
      ),
    },
  ],
};

// For Layout Specs, we attempt to match the first valid option from top to bottom.
// Bottom options should not require more permissions than the spec's above them

/** Default layout */
export const TradingLayoutSpec: LayoutSpec = [
  {
    layout: TradingLayout,
    requiredPermissions: [WL_VIEW_BLOTTERS, WL_SHOW_CANDLESTICKS_AND_MARKETDATA, [WL_INITIATE_ORDER, WL_INITIATE_RFQ]],
  },
  {
    layout: ViewOnlyLayout,
    requiredPermissions: [WL_VIEW_BLOTTERS, WL_SHOW_CANDLESTICKS_AND_MARKETDATA],
  },
  {
    layout: BlottersOnlyLayout,
    requiredPermissions: [WL_VIEW_BLOTTERS],
  },
];

/** Default layout */
export const WatchListTradingLayoutSpec: LayoutSpec = [
  {
    layout: WatchListTradingLayout,
    requiredPermissions: [WL_VIEW_BLOTTERS, WL_SHOW_CANDLESTICKS_AND_MARKETDATA, [WL_INITIATE_ORDER, WL_INITIATE_RFQ]],
  },
  {
    layout: ViewOnlyLayout,
    requiredPermissions: [WL_VIEW_BLOTTERS, WL_SHOW_CANDLESTICKS_AND_MARKETDATA],
  },
  {
    layout: BlottersOnlyLayout,
    requiredPermissions: [WL_VIEW_BLOTTERS],
  },
];

/** Default layout with ladder on the left */
export const TradingLadderLayoutSpec: LayoutSpec = [
  {
    layout: TradingLadderLayout,
    requiredPermissions: [WL_VIEW_BLOTTERS, WL_SHOW_CANDLESTICKS_AND_MARKETDATA, [WL_INITIATE_ORDER, WL_INITIATE_RFQ]],
  },
  {
    layout: ViewOnlyLadderLayout,
    requiredPermissions: [WL_VIEW_BLOTTERS, WL_SHOW_CANDLESTICKS_AND_MARKETDATA],
  },
  {
    layout: BlottersOnlyLayout,
    requiredPermissions: [WL_VIEW_BLOTTERS],
  },
];

/** Simple UI for RFQ only trading */
export const SimpleRFQLayoutSpec: LayoutSpec = [
  {
    layout: SimpleRFQLayout,
    requiredPermissions: [WL_VIEW_BLOTTERS, WL_VIEW_BLOTTERS_TRADES, WL_VIEW_BLOTTERS_BALANCES, WL_INITIATE_RFQ],
  },
  {
    layout: ViewOnlySimpleRFQLayout,
    requiredPermissions: [WL_VIEW_BLOTTERS, WL_VIEW_BLOTTERS_TRADES, WL_VIEW_BLOTTERS_BALANCES],
  },
];

/** UI for settings */
export const SettingsLayoutSpec: LayoutSpec = [
  {
    layout: SettingsLayout,
    requiredPermissions: [WL_VIEW_SETTINGS],
  },
];

/** UI for viewing customer deposit addresses */
export const AddressesLayoutSpec: LayoutSpec = [
  {
    layout: AddressesLayout,
    requiredPermissions: [WL_VIEW_SETTINGS],
  },
];

/** UI for read only fee tiers */
export const FeeTiersLayoutSpec: LayoutSpec = [
  {
    layout: FeeTiersLayout,
    requiredPermissions: [WL_VIEW_FEE_TIERS],
  },
];

/** UI for portfolio */
export const PortfolioLayoutSpec: LayoutSpec = [
  {
    layout: BalancesOnlyLayout,
    requiredPermissions: [WL_VIEW_BLOTTERS],
  },
];

export const LayoutSpecs: Record<string, LayoutSpec> = {
  [CustomerUserConfigLayoutType.TradingLayout]: TradingLayoutSpec,
  [CustomerUserConfigLayoutType.TradingLadderLayout]: TradingLadderLayoutSpec,
  [CustomerUserConfigLayoutType.WatchListTradingLayout]: WatchListTradingLayoutSpec,
  [CustomerUserConfigLayoutType.SimpleRFQLayout]: SimpleRFQLayoutSpec,
  [CustomerUserConfigLayoutType.SettingsLayout]: SettingsLayoutSpec,
  [CustomerUserConfigLayoutType.PortfolioLayout]: PortfolioLayoutSpec,
  [CustomerUserConfigLayoutType.AddressesLayout]: AddressesLayoutSpec,
  [CustomerUserConfigLayoutType.FeeTiersLayout]: FeeTiersLayoutSpec,
};

export const tradingLayouts = [
  CustomerUserConfigLayoutType.TradingLayout,
  CustomerUserConfigLayoutType.TradingLadderLayout,
  CustomerUserConfigLayoutType.WatchListTradingLayout,
];
