import styled from 'styled-components';
import { Box, type BoxProps } from './Box';

/** Simple flex div
 *
 * Set flex properties without creating a new component.
 */
export const Flex = styled(Box)<BoxProps>``;
Flex.defaultProps = {
  display: 'flex',
};
