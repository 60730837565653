import Big, { type BigSource } from 'big.js';
import { max, min } from 'lodash-es';
import { transparentize } from 'polished';
// @ts-expect-error - react-sparklines does not have types
import { Sparklines, SparklinesCurve } from 'react-sparklines';
import { useTheme } from 'styled-components';
import { useElementId, useElementSize } from '../../hooks';
import type { BoxProps } from '../Core';
import { NumberVariants } from '../FormattedNumber';
import { SparklineWrapper } from './styles';

export const Sparkline = ({ data, limit, variant, sparklineFill = 'none', ...props }: SparklineProps) => {
  const {
    elementRef,
    size: { offsetWidth, offsetHeight },
  } = useElementSize<HTMLDivElement>();
  const {
    colors: {
      green: { lighten: colorPositive },
      red: { lighten: colorNegative },
      gray: { '100': colorDefault },
    },
  } = useTheme();

  const color =
    variant === NumberVariants.Positive
      ? colorPositive
      : variant === NumberVariants.Negative
      ? colorNegative
      : colorDefault;

  // based on the code in Kyoko sparkline component
  let maxValue = Big(max(data) ?? 0);
  let minValue = Big(min(data) ?? 0);
  const range = maxValue.minus(minValue);
  minValue = minValue.minus(range.times(0.1));
  maxValue = maxValue.add(range.times(0.2));

  const gradientID = useElementId('sparkline');
  const id = gradientID('gradient');
  const fill = sparklineFill === 'gradient' ? `url(#${id})` : sparklineFill;
  const transparentColor = transparentize(1, color);

  return (
    <SparklineWrapper ref={elementRef} {...props}>
      <Sparklines
        width={offsetWidth}
        height={offsetHeight}
        svgHeight={offsetHeight}
        svgWidth={offsetWidth}
        data={data}
        limit={limit}
        max={maxValue.toNumber()}
        min={minValue.toNumber()}
        margin={0}
      >
        <SparklinesCurve color={color} style={{ fill }} />
        <defs>
          <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={color} />
            <stop offset="100%" stopColor={transparentColor} />
          </linearGradient>
        </defs>
      </Sparklines>
    </SparklineWrapper>
  );
};

type SparklineProps = {
  limit?: number;
  data: (BigSource | undefined)[];
  variant?: NumberVariants;
  sparklineFill?: string;
} & BoxProps;
