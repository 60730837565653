import { mapDarkTheme, mapLightTheme, setAlpha, ThemeTypes } from '@talos/kyoko';

import { darken, lighten, mix, readableColor } from 'polished';
import type { DefaultTheme } from 'styled-components';

export type GenerateThemeType = { [K in keyof DefaultTheme['colors']]: string };

export function generateTheme(customDraft: GenerateThemeType, themeType: ThemeTypes.dark | ThemeTypes.light) {
  const newThemeColors: DefaultTheme['colors'] = Object.keys(customDraft).reduce((acc, key) => {
    const defaultColor = customDraft[key];
    const lightenColor = lighten(0.1, defaultColor);
    const dimColor = setAlpha(0.4, defaultColor);
    const muteColor = setAlpha(0.1, defaultColor);

    if (key === 'gray') {
      return {
        ...acc,
        gray: getGrayColors(themeType, defaultColor) as any,
      };
    }

    return {
      ...acc,
      [key]: {
        lighten: lightenColor,
        default: defaultColor,
        dim: dimColor,
        mute: muteColor,
      },
    };
  }, {} as DefaultTheme['colors']);

  const colorTextPositiveButton = readableColor(
    newThemeColors.green.default,
    newThemeColors.black.default,
    newThemeColors.white.default,
    false
  );

  const positiveColorText: Pick<
    DefaultTheme,
    | 'colorTextPositiveButton'
    | 'colorTextPositiveButtonActive'
    | 'colorTextPositiveButtonDisabled'
    | 'colorTextPositiveButtonFocus'
    | 'colorTextPositiveButtonHover'
  > = {
    colorTextPositiveButton: colorTextPositiveButton,
    colorTextPositiveButtonActive: colorTextPositiveButton,
    colorTextPositiveButtonHover: colorTextPositiveButton,
    colorTextPositiveButtonFocus: colorTextPositiveButton,
    colorTextPositiveButtonDisabled: setAlpha(0.5, colorTextPositiveButton),
  };

  const colorTextNegativeButton = readableColor(
    newThemeColors.red.default,
    newThemeColors.black.default,
    newThemeColors.white.default,
    false
  );

  const negativeColorText: Pick<
    DefaultTheme,
    | 'colorTextNegativeButton'
    | 'colorTextNegativeButtonActive'
    | 'colorTextNegativeButtonDisabled'
    | 'colorTextNegativeButtonFocus'
    | 'colorTextNegativeButtonHover'
  > = {
    colorTextNegativeButton: colorTextNegativeButton,
    colorTextNegativeButtonActive: colorTextNegativeButton,
    colorTextNegativeButtonHover: colorTextNegativeButton,
    colorTextNegativeButtonFocus: colorTextNegativeButton,
    colorTextNegativeButtonDisabled: setAlpha(0.5, colorTextNegativeButton),
  };

  const colorTextWarningButton = readableColor(
    newThemeColors.yellow.default,
    newThemeColors.black.default,
    newThemeColors.white.default,
    false
  );

  const warningColorText: Pick<
    DefaultTheme,
    | 'colorTextWarningButton'
    | 'colorTextWarningButtonActive'
    | 'colorTextWarningButtonDisabled'
    | 'colorTextWarningButtonFocus'
    | 'colorTextWarningButtonHover'
  > = {
    colorTextWarningButton: colorTextWarningButton,
    colorTextWarningButtonActive: colorTextWarningButton,
    colorTextWarningButtonHover: colorTextWarningButton,
    colorTextWarningButtonFocus: colorTextWarningButton,
    colorTextWarningButtonDisabled: setAlpha(0.5, colorTextWarningButton),
  };

  const colorTextPrimaryButton = readableColor(
    newThemeColors.primary.default,
    newThemeColors.black.default,
    newThemeColors.white.default,
    false
  );

  const primaryColorText: Pick<
    DefaultTheme,
    | 'colorTextPrimaryButton'
    | 'colorTextPrimaryButtonActive'
    | 'colorTextPrimaryButtonDisabled'
    | 'colorTextPrimaryButtonFocus'
    | 'colorTextPrimaryButtonHover'
  > = {
    colorTextPrimaryButton: colorTextPrimaryButton,
    colorTextPrimaryButtonActive: colorTextPrimaryButton,
    colorTextPrimaryButtonHover: colorTextPrimaryButton,
    colorTextPrimaryButtonFocus: colorTextPrimaryButton,
    colorTextPrimaryButtonDisabled: setAlpha(0.5, colorTextPrimaryButton),
  };

  const colorTextDefaultButton = readableColor(
    // aproximation of the color that is actually set, which is setAlpha(0.08, newThemeColors.gray['100'])
    newThemeColors.gray['020'],
    newThemeColors.black.default,
    newThemeColors.white.default,
    false
  );

  const defaultColorText: Pick<
    DefaultTheme,
    | 'colorTextDefaultButton'
    | 'colorTextDefaultButtonActive'
    | 'colorTextDefaultButtonDisabled'
    | 'colorTextDefaultButtonFocus'
    | 'colorTextDefaultButtonHover'
  > = {
    colorTextDefaultButton: colorTextDefaultButton,
    colorTextDefaultButtonActive: colorTextDefaultButton,
    colorTextDefaultButtonHover: colorTextDefaultButton,
    colorTextDefaultButtonFocus: colorTextDefaultButton,
    colorTextDefaultButtonDisabled: setAlpha(0.5, colorTextDefaultButton),
  };

  const buttonColors = {
    ...positiveColorText,
    ...negativeColorText,
    ...warningColorText,
    ...primaryColorText,
    ...defaultColorText,
  };

  if (themeType === ThemeTypes.dark) {
    return mapDarkTheme(newThemeColors, {
      name: 'DarkCustomTheme',
      ...buttonColors,
    });
  }

  return mapLightTheme(newThemeColors, {
    name: 'LightCustomTheme',
    ...buttonColors,
  });
}

function getGrayColors(themeType: ThemeTypes.dark | ThemeTypes.light, bgColor: string) {
  const keys = ['000', '010', 'main', '020', '030', '040', '050', '060', '070', '080', '090', '100'];
  const alphasDark = [0, 0.03, 0, 0.06, 0.12, 0.18, 0.21, 0.25, 0.32, 0.5, 0.64, 1];
  const alphasLight = [0.1, 0.05, 0, 0.02, 0.08, 0.14, 0.18, 0.22, 0.27, 0.4, 0.6, 0.9];

  const isDarkTheme = themeType === ThemeTypes.dark;

  const darkColors = keys.map((key, i) => {
    const alpha = alphasDark[i];
    const mixBaseColor = i < 2 ? darken(0.1, bgColor) : bgColor;
    return mix(alpha, '#FFFFEE', mixBaseColor);
  });

  const lightColors = keys.map((key, i) => {
    const alpha = alphasLight[i];
    return mix(alpha, '#050511', bgColor);
  });

  const grayColors = {};
  const currentColors = isDarkTheme ? darkColors : lightColors;

  for (let i = 0; i < currentColors.length; i++) {
    const key = keys[i];
    const color = currentColors[i];
    grayColors[key] = color;
  }

  return grayColors;
}
