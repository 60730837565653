import {
  WatchList as BaseWatchList,
  MixpanelEvent,
  MixpanelEventSource,
  primeOrderForm,
  setSymbol,
  SideEnum,
  useDynamicCallback,
  useFavoriteSecurities,
  useMixpanel,
  useWatchlistSettings,
  useWLOrderFormDispatch,
  useWLOrgConfigContext,
  useWLRoleAuth,
  WL_INITIATE_ORDER,
  WL_INITIATE_RFQ,
  type WatchListRowData,
  type WatchListRowNode,
} from '@talos/kyoko';
import type { CellClickedEvent } from 'ag-grid-community';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_CURRENCIES } from './';

export function MobileWatchList({ securityRoute }: { securityRoute: string }) {
  const mixpanel = useMixpanel(MixpanelEventSource.Watchlist);
  const { config } = useWLOrgConfigContext();
  const dispatch = useWLOrderFormDispatch();
  const { isAuthorized } = useWLRoleAuth();
  const navigate = useNavigate();

  const onSecurityClicked = useDynamicCallback((event: CellClickedEvent) => {
    mixpanel.track(MixpanelEvent.ChangeSymbol);
    const node = event.node as WatchListRowNode;
    const security = node.data.security;

    security?.Symbol && dispatch(setSymbol(security?.Symbol));
    navigate(securityRoute);
  });

  const onBidClicked = useDynamicCallback((event: CellClickedEvent<WatchListRowData>) => {
    mixpanel.track(MixpanelEvent.ChangeSymbol);
    if (isAuthorized(WL_INITIATE_ORDER) || isAuthorized(WL_INITIATE_RFQ)) {
      dispatch(
        primeOrderForm({
          Symbol: event.node.data?.security.Symbol,
          Side: SideEnum.Buy,
          Price: event.node.data?.bid?.toFixed(),
        })
      );

      navigate('/trade');
    } else {
      event.node.data?.security.Symbol && dispatch(setSymbol(event.node.data?.security.Symbol));
      navigate(securityRoute);
    }
  });

  const onOfferClicked = useDynamicCallback((event: CellClickedEvent<WatchListRowData>) => {
    mixpanel.track(MixpanelEvent.ChangeSymbol);
    if (isAuthorized(WL_INITIATE_ORDER) || isAuthorized(WL_INITIATE_RFQ)) {
      dispatch(
        primeOrderForm({
          Symbol: event.node.data?.security.Symbol,
          Side: SideEnum.Sell,
          Price: event.node.data?.offer?.toFixed(),
        })
      );

      navigate('/trade');
    } else {
      event.node.data?.security.Symbol && dispatch(setSymbol(event.node.data?.security.Symbol));
      navigate(securityRoute);
    }
  });

  const columns = useMemo(() => {
    return [
      { type: 'rank' as const },
      { type: 'favorite' as const },
      { type: 'security' as const, onClick: onSecurityClicked },
      { type: 'bidButton' as const, onClick: onBidClicked },
      { type: 'spread' as const },
      { type: 'offerButton' as const, onClick: onOfferClicked },
      { type: 'spacer' as const },
    ].filter(c => config.showSpreadOnVolumeLadder || c.type !== 'spread');
  }, [config.showSpreadOnVolumeLadder, onSecurityClicked, onBidClicked, onOfferClicked]);

  const { favoriteSecurities, setFavoriteSecurities } = useFavoriteSecurities();

  const watchlistSettings = useWatchlistSettings();

  return (
    <BaseWatchList
      enabledColumns={columns}
      rowHeight={64}
      selectableCurrencies={config.watchListFilterCurrencies ?? DEFAULT_CURRENCIES}
      favoriteSecurities={favoriteSecurities}
      setFavoriteSecurities={setFavoriteSecurities}
      {...watchlistSettings}
    />
  );
}
