import {
  ConnectionStatusEnum,
  MARKET_DATA_SNAPSHOT,
  RefRateContext,
  useMultipleStreamsSubscription,
  type ICustomerMarketDataSnapshot,
  type RefRate,
  type RefRateContextProps,
  type RequestStream,
  type SubscriptionResponse,
} from '@talos/kyoko';
import Big from 'big.js';
import { isNil } from 'lodash-es';
import { memo, useMemo } from 'react';

interface MarketDataSnapshotRequest extends RequestStream {
  name: typeof MARKET_DATA_SNAPSHOT;
  Symbol: string;
  Throttle: string;
}

function getRefRateStreamRequestForSymbol(symbol: string): MarketDataSnapshotRequest {
  return {
    name: MARKET_DATA_SNAPSHOT,
    tag: 'RefRateContextProvider',
    Symbol: symbol,
    Throttle: '1s',
  };
}
function getRefRateStreamRequestForAllSymbols(
  requestMap: Map<string, MarketDataSnapshotRequest>
): MarketDataSnapshotRequest[] {
  return Array.from(requestMap.values());
}

function subscriptionCallback(
  memo: Map<string, RefRate | undefined>,
  json: SubscriptionResponse<ICustomerMarketDataSnapshot, string>
) {
  for (const d of json.data) {
    const prevPricePoint = memo.get(d.Symbol)?.currentPrice ?? memo.get(d.Symbol)?.prevPrice;
    const newRate = d.Status === ConnectionStatusEnum.Online ? getMidPrice(d) : prevPricePoint;
    if ((isNil(prevPricePoint) && !isNil(newRate)) || (!isNil(prevPricePoint) && !newRate?.eq(prevPricePoint))) {
      memo.set(d.Symbol, { currentPrice: newRate, prevPrice: prevPricePoint, isLoaded: true });
    } else {
      memo.set(d.Symbol, { currentPrice: undefined, prevPrice: undefined, isLoaded: true });
    }
  }
  return memo;
}

export const RefRateContextProvider = memo(function RefRateContextProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const {
    valueMapObservable: refRatesBySymbol,
    registerSubscription,
    registerSubscriptions,
    unregisterSubscription,
    unregisterSubscriptions,
  } = useMultipleStreamsSubscription({
    getRequest: getRefRateStreamRequestForSymbol,
    getAllRequests: getRefRateStreamRequestForAllSymbols,
    subscriptionCallback: subscriptionCallback,
  });

  const value = useMemo<RefRateContextProps>(
    () => ({
      refRatesBySymbol,
      registerSubscription,
      registerSubscriptions,
      unregisterSubscription,
      unregisterSubscriptions,
    }),
    [refRatesBySymbol, registerSubscription, unregisterSubscription, registerSubscriptions, unregisterSubscriptions]
  );
  return <RefRateContext.Provider value={value}>{children}</RefRateContext.Provider>;
});

function getMidPrice(data: ICustomerMarketDataSnapshot) {
  return data.Bids[0]?.Price && data.Bids[0]?.Price !== '0' && data.Offers[0]?.Price && data.Offers[0]?.Price !== '0'
    ? Big(data.Bids[0].Price).add(data.Offers[0].Price).div(2)
    : undefined;
}
