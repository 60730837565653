import type { ICellRendererParams } from 'ag-grid-enterprise';
import { noop } from 'lodash-es';
import { DeepDiveButton } from '../DeepDiveButton';
import type { WatchListRowData } from './types';

export interface DeepDiveButtonCellRendererParams extends ICellRendererParams {
  data: WatchListRowData;
}

export const DeepDiveButtonCellRenderer = function DeepDiveButtonCellRenderer({
  data,
}: DeepDiveButtonCellRendererParams) {
  return <DeepDiveButton onClick={noop} security={data.security} />;
};
