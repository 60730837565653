import { BaseStyle, GlobalStyle, useWLOrgConfigContext } from '@talos/kyoko';
import { useEffect, type PropsWithChildren } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { usePreferredTheme } from '../hooks';
import { useCustomThemeContext } from './CustomThemeContext';

export function ThemeProvider({ children }: PropsWithChildren) {
  const { config } = useWLOrgConfigContext();
  const theme = usePreferredTheme(config.theme);

  const customThemeOverride = useCustomThemeContext();

  const themeBeingUsed = (customThemeOverride.customThemeEnabled && customThemeOverride.customTheme) || theme;

  useEffect(() => {
    const { fontUrl } = theme;
    const link = document.getElementById('themeFont') as HTMLLinkElement;
    if (link != null) {
      link.href = fontUrl;
    }
  }, [theme]);

  return (
    <StyledComponentsThemeProvider theme={themeBeingUsed}>
      <BaseStyle />
      <GlobalStyle />
      <meta name="theme-color" content={theme.backgroundHeader} />
      {children}
    </StyledComponentsThemeProvider>
  );
}
