import { RecentSymbolsContext, useWLCustomerUserConfigContext, type RecentSymbolsContextProps } from '@talos/kyoko';
import { useMemo, type PropsWithChildren } from 'react';

export const RecentSymbolsProvider = ({ children }: PropsWithChildren) => {
  const { config, updateConfig } = useWLCustomerUserConfigContext();

  const recentSymbols: RecentSymbolsContextProps = useMemo(() => {
    return {
      recentSymbols: config?.recentSymbols ?? {},
      setRecentSymbols: recentSymbols => updateConfig({ recentSymbols }),
    };
  }, [config?.recentSymbols, updateConfig]);

  return <RecentSymbolsContext.Provider value={recentSymbols}>{children}</RecentSymbolsContext.Provider>;
};
