import {
  archiveOrderID,
  DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
  ORDER,
  selectArchivedOrderIDSet,
  selectFocusedOrderID,
  setFocusedOrderID,
  useAccordionFilterBuilder,
  useConstant,
  useGetNewEntityWithTranslatedTextPipe,
  usePersistedBlotterTable,
  useWLOrderFormDispatch,
  useWLOrderFormSelector,
  useWsBlotterTable,
  type CustomerOrder,
  type OrdStatusEnum,
  type SideEnum,
  type WebsocketRequest,
} from '@talos/kyoko';
import { useCallback, useEffect, useMemo } from 'react';

import type { CellClickedEvent } from 'ag-grid-community';
import { onlyServerFilterKeys, useOrdersFilter } from 'components/Filters/FilterBuilder/useOrdersFilter';
import { ACTIVE_ORDER_STATUSES } from 'tokens/order';
import type { TabbedBlotterProps } from '../../types';
import { useActiveOrdersBlotterColumn } from './useActiveOrdersBlotterColumns';

interface ActiveOrdersWebsocketRequest extends WebsocketRequest {
  MarketAccounts?: string[];
  Sides?: SideEnum[];
  Statuses?: OrdStatusEnum[];
  Strategies?: string[];
  Symbols?: string[];
}

type UseActiveOrdersBlotterProps = Required<Pick<TabbedBlotterProps, 'maxRows'>>;

const filter = { StartDate: Sugar.Date.create('a year ago') };

const DEFAULT_SORT = '-SubmitTime';

export function useActiveOrdersBlotter({ maxRows }: UseActiveOrdersBlotterProps) {
  const archivedOrderIDs = useWLOrderFormSelector(selectArchivedOrderIDSet);
  const focusedOrderID = useWLOrderFormSelector(selectFocusedOrderID);
  const dispatch = useWLOrderFormDispatch();

  const request: ActiveOrdersWebsocketRequest = useConstant({
    name: ORDER,
    tag: 'ACTIVE_ORDERS',
    HideApiCalls: true,
    Throttle: '1s',
    Statuses: ACTIVE_ORDER_STATUSES,
    sort_by: DEFAULT_SORT,
  });

  const columns = useActiveOrdersBlotterColumn();
  const persistedBlotterTable = usePersistedBlotterTable<CustomerOrder>(`activeOrders`, {
    columns: columns,
    sort: DEFAULT_SORT,
    filter,
  });

  // [DEAL-4595] Clear any saved Date Filter from the Active Orders Blotter
  delete persistedBlotterTable.initialFilter?._start;
  delete persistedBlotterTable.initialFilter?.StartDate;
  delete persistedBlotterTable.initialFilter?.EndDate;
  // [DEAL-4595] Remove Date Filter from Active Orders Blotter
  const filteredOrders = useOrdersFilter({ persistedBlotterTable, showDateRangePicker: false });
  const { clientSideFilter: clientLocalFilter, blotterTableFilterProps, filterBuilderProps } = filteredOrders;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: false },
    filterBuilderProps,
  });

  const orderFormDispatch = useWLOrderFormDispatch();

  const handleOnCellClicked = useCallback(
    (event: CellClickedEvent<CustomerOrder, any>) => {
      if (event.data != null) {
        orderFormDispatch(setFocusedOrderID(event.data.OrderID));
      }
    },
    [orderFormDispatch]
  );

  const translateCustomerOrderTextPipe = useGetNewEntityWithTranslatedTextPipe<CustomerOrder>();

  const blotterTable = useWsBlotterTable<ActiveOrdersWebsocketRequest, CustomerOrder>({
    initialRequest: request,
    columns: persistedBlotterTable.columns,
    filter: onlyServerFilterKeys(filteredOrders.filter),
    persistence: persistedBlotterTable,
    pipe: translateCustomerOrderTextPipe,
    rowID: 'OrderID',
    clientLocalFilter,
    flashRows: ['add'],
    startingRowLimit: maxRows,
    gridOptions: {
      rowSelection: DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
      animateRows: true,
      rowHeight: 32,
      onCellClicked: handleOnCellClicked,
    },
  });

  useEffect(() => {
    for (const blotterRow of blotterTable.getRows()) {
      if (archivedOrderIDs.has(blotterRow.data.OrderID)) {
        blotterRow.remove();
      }
    }
  }, [archivedOrderIDs, blotterTable]);

  useEffect(() => {
    for (const blotterRow of blotterTable.getRows()) {
      blotterRow.setSelected(blotterRow.data.OrderID === focusedOrderID);
    }
  }, [blotterTable, focusedOrderID]);

  const archiveAll = useCallback(() => {
    for (const blotterRow of blotterTable.getRows()) {
      if (!ACTIVE_ORDER_STATUSES.includes(blotterRow.data.OrdStatus)) {
        dispatch(archiveOrderID(blotterRow.data.OrderID));
      }
    }
  }, [dispatch, blotterTable]);

  return useMemo(
    () => ({
      archiveAll,
      blotterTable,
      blotterTableFilterProps,
      filterBuilderAccordion,
    }),
    [archiveAll, blotterTable, blotterTableFilterProps, filterBuilderAccordion]
  );
}
