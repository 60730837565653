import {
  BlotterTableContext,
  useCurrenciesContext,
  useIntl,
  useSecuritiesContext,
  useWLCustomerMarketAccountContext,
  useWLOrgConfigContext,
  useWLUserContext,
  useWLUsersContext,
  type BlotterTableContextProps,
} from '@talos/kyoko';
import { useMemo, type PropsWithChildren } from 'react';
import { useStrategiesContext } from './StrategiesProvider';

export function BlotterTableContextProvider({ children }: PropsWithChildren) {
  const { strategiesByName } = useStrategiesContext();
  const { currenciesBySymbol } = useCurrenciesContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const { userByEmail, userByID } = useWLUsersContext();
  const { user: currentUser } = useWLUserContext();
  const { config: wlOrgConfig } = useWLOrgConfigContext();
  const { customerMarketAccountsBySourceAccountID } = useWLCustomerMarketAccountContext();
  const intl = useIntl();

  const context = useMemo<BlotterTableContextProps>(
    () => ({
      strategiesByName,
      currenciesBySymbol,
      securitiesBySymbol,
      customerMarketAccountsBySourceAccountID,
      userByEmail,
      userByID,
      currentUser,
      wlOrgConfig,
      intl,
    }),
    [
      currenciesBySymbol,
      currentUser,
      customerMarketAccountsBySourceAccountID,
      intl,
      securitiesBySymbol,
      strategiesByName,
      userByEmail,
      userByID,
      wlOrgConfig,
    ]
  );

  return <BlotterTableContext.Provider value={context}>{children}</BlotterTableContext.Provider>;
}
