import type { LayoutComponentScreen } from '../types';
import {
  BalancesScreen,
  ChartScreen,
  CreditScreen,
  OrderCardsScreen,
  TradesScreen,
  TransfersScreen,
  WatchListScreen,
} from './screenLibrary';

export const viewOnlyMobileScreens: LayoutComponentScreen[] = [
  WatchListScreen,
  ChartScreen,
  OrderCardsScreen,
  BalancesScreen,
  TradesScreen,
  CreditScreen,
  TransfersScreen,
];
