import {
  type CustomerUserConfigNavigationItem,
  DEFAULT_MAX_ROWS,
  IconName,
  type RequiredProperties,
  WL_INITIATE_ORDER,
  WL_INITIATE_RFQ,
  WL_SHOW_CANDLESTICKS_AND_MARKETDATA,
  WL_VIEW_BLOTTERS_ACTIVE_ORDERS,
  WL_VIEW_BLOTTERS_BALANCES,
  WL_VIEW_BLOTTERS_CREDITS,
  WL_VIEW_BLOTTERS_ORDER_HISTORY,
  WL_VIEW_BLOTTERS_TRADES,
  WL_VIEW_BLOTTERS_TRANSFERS,
  WL_VIEW_FEE_TIERS,
  WL_VIEW_SETTINGS,
  type WLOrgConfig,
} from '@talos/kyoko';
import { noop } from 'lodash-es';
import { Addresses } from '../../components/Addresses';
import { Balances } from '../../components/Balances';
import { CreditExposuresBlotter } from '../../components/Blotters/CreditExposuresBlotter';
import { MobileBlotter } from '../../components/Blotters/MobileBlotter';
import { OrderHistoryBlotter } from '../../components/Blotters/OrderHistoryBlotter';
import { TradesBlotter } from '../../components/Blotters/TradesBlotter';
import { TransfersBlotter } from '../../components/Blotters/TransfersBlotter';
import { CandleSticksWithSymbol } from '../../components/Candlesticks/CandleSticksWithSymbol';
import { FeeTiers } from '../../components/FeeTiers';
import { OrderCards } from '../../components/OrderCards';
import { OrderForms } from '../../components/OrderForms';
import { RFQOrderForm } from '../../components/OrderForms/RFQForm';
import { Settings } from '../../components/Settings';
import { VolumeLadder } from '../../components/VolumeLadder';
import { MobileWatchList } from '../../components/WatchList/MobileWatchList';

type ScreenNavigationItem = RequiredProperties<CustomerUserConfigNavigationItem, 'icon'> & {
  component: JSX.Element;
  requiredConfigs?: (keyof WLOrgConfig)[];
};

export const WatchListScreen: ScreenNavigationItem = {
  component: <MobileWatchList securityRoute="/ladder" />,
  label: 'watchlist' as const,
  path: '/',
  icon: IconName.ViewList,
};

export const OrderFormsScreen: ScreenNavigationItem = {
  component: <OrderForms />,
  label: 'trade' as const,
  path: '/trade',
  icon: IconName.TrendUp,

  requiredPermissions: [WL_INITIATE_ORDER],
};

export const RFQFormScreen: ScreenNavigationItem = {
  component: <RFQOrderForm />,
  label: 'trade' as const,
  path: '/trade',
  icon: IconName.TrendUp,

  requiredPermissions: [WL_INITIATE_RFQ],
};

export const OrderCardsScreen: ScreenNavigationItem = {
  component: <OrderCards />,
  label: 'orders' as const,
  path: '/orders',
  icon: IconName.Collection,
  requiredPermissions: [WL_VIEW_BLOTTERS_ACTIVE_ORDERS],
};

export const BalancesScreen: ScreenNavigationItem = {
  component: <Balances />,
  label: 'balances' as const,
  path: '/balances',
  icon: IconName.ChartDonut,
  requiredPermissions: [WL_VIEW_BLOTTERS_BALANCES],
};

export const TradesScreen: ScreenNavigationItem = {
  component: (
    <MobileBlotter titleKey="trades">
      <TradesBlotter
        renderInBackground={true}
        loading={false}
        maxRows={DEFAULT_MAX_ROWS}
        onStartLoading={noop}
        onFinishedLoading={noop}
      />
    </MobileBlotter>
  ),
  label: 'trades' as const,
  path: '/trades',
  icon: IconName.DocumentText,
  requiredPermissions: [WL_VIEW_BLOTTERS_TRADES],
};

export const CreditScreen: ScreenNavigationItem = {
  component: (
    <MobileBlotter titleKey="credit">
      <CreditExposuresBlotter renderInBackground={true} />
    </MobileBlotter>
  ),
  label: 'credit' as const,
  path: '/credit',
  icon: IconName.CurrencyDollar,
  requiredConfigs: ['showExposureBlotter'],
  requiredPermissions: [WL_VIEW_BLOTTERS_CREDITS],
};

export const TransfersScreen: ScreenNavigationItem = {
  component: (
    <MobileBlotter titleKey="transfers">
      <TransfersBlotter renderInBackground={true} maxRows={DEFAULT_MAX_ROWS} />
    </MobileBlotter>
  ),
  label: 'transfers' as const,
  path: '/transfers',
  icon: IconName.ArrowLeftRight,
  requiredPermissions: [WL_VIEW_BLOTTERS_TRANSFERS],
  requiredConfigs: ['enableBalanceTransactions'] satisfies (keyof WLOrgConfig)[],
};

export const OrdersScreen: ScreenNavigationItem = {
  component: (
    <MobileBlotter titleKey="orders">
      <OrderHistoryBlotter
        renderInBackground={true}
        loading={false}
        maxRows={DEFAULT_MAX_ROWS}
        onStartLoading={noop}
        onFinishedLoading={noop}
      />
    </MobileBlotter>
  ),
  label: 'orders' as const,
  path: '/orders',
  icon: IconName.DocumentText,
  requiredPermissions: [WL_VIEW_BLOTTERS_ORDER_HISTORY],
};

export const SettingsScreen: ScreenNavigationItem = {
  component: <Settings />,
  label: 'settings' as const,
  path: '/settings',
  icon: IconName.Cog,
  requiredPermissions: [WL_VIEW_SETTINGS],
};

export const LadderScreen: ScreenNavigationItem = {
  component: <VolumeLadder />,
  label: 'ladder' as const,
  path: '/ladder',
  icon: IconName.Filter,
};
export const ChartScreen: ScreenNavigationItem = {
  component: <CandleSticksWithSymbol />,
  label: 'chart' as const,
  path: '/chart',
  icon: IconName.PresentationChartLine,
  requiredPermissions: [WL_SHOW_CANDLESTICKS_AND_MARKETDATA],
};

export const AddressesScreen: ScreenNavigationItem = {
  component: <Addresses />,
  label: 'addresses' as const,
  path: '/addresses',
  icon: IconName.Link,
};

export const FeeTiersScreen: ScreenNavigationItem = {
  component: <FeeTiers />,
  label: 'feeTiers' as const,
  path: '/fee-tiers',
  icon: IconName.Fees,
  requiredPermissions: [WL_VIEW_FEE_TIERS],
};
